import { number, z } from "zod";

export const IFDetailsSchema = z.object({
  invoice_factoring_id: z.number().optional(),
  beneficiary_id: z.number().optional(),
  company_name: z.string().min(3, { message: "Please select a company name" }),
  company_registration_number: z
    .string()
    .min(3, { message: "Registration number is required" }),

  company_address: z
    .string()
    .min(3, { message: "Please enter a valid address" }),
  contact_person_name: z
    .string()
    .min(3, { message: "Contact person name is required" }),
  phone_number: z
    .string()
    .min(3, { message: "Contact person name is required" }),
  email: z
    .string()
    .email("Enter a valid email ")
    .min(3, { message: "Email is required" }),

  shipment_date: z
    .string()
    .min(3, { message: "Please enter a valid shipment date" }),
  finance_partner_id: z
    .string()
    .min(1, { message: "Select a finance partner" })
    .or(z.number({ message: "Select a finance partner" })),

  finance_term: z.string().trim().min(1, { message: "Select a finance term" }),
  invoice_amount: z
    .string()
    .trim()
    .min(1, { message: "Please enter invoice amount" }),
  payment_request: z.string(),
  invoice_file: z
    .string()
    .min(3, { message: "Please upload valid invoice file" })
    .includes("https://", { message: "Please upload document" }),
  purchase_order_file: z
    .string()
    .min(3, { message: "Please upload valid purchase order file" })
    .includes("https://", { message: "Please upload document" }),
});

export type IFDetailsType = z.infer<typeof IFDetailsSchema>;

export const IFBuyerCompanyFormSchema = z.object({
  company_name: z.string().min(3, { message: "Company name is required" }),
  reg_no: z.string().min(3, { message: "Registration number is required" }),
  company_address: z
    .string()
    .min(3, { message: "Company address is required" }),
  contact_person_name: z
    .string()
    .min(3, { message: "Contact person name is required" }),
  phone_number: z
    .string()
    .min(3, { message: "Contact person name is required" }),
  email: z
    .string()
    .email("Enter a valid email ")
    .min(3, { message: "Email is required" }),

  bank: z.string().min(3, { message: "bank is required" }),
  bank_address: z.string().min(3, { message: "bank Address is required" }),
  account_number: z.string().min(3, { message: "Account Number is required" }),
  swift_ifsc: z.string().min(3, { message: "Swift_Ifsc is required" }),
  account_reference_number: z
    .string()
    .min(3, { message: "Account reference number is required" }),
  account_holder_name: z
    .string()
    .min(3, { message: "Account holder name is required" }),
});

export type IFBuyerCompanyFormType = z.infer<typeof IFBuyerCompanyFormSchema>;
