import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";

import { get_smart_escrow_details } from "../constants/apis";

import { useAppContext } from "../context/AppContext";
import { useFetch } from "../hooks/useFetch";
import { SEConditionsFormType } from "../schemas/SEConditionsFormSchema";
import {
  SEDetailsFCPFormType,
  SEDetailsSCPFormType,
} from "../schemas/SEDetailsType";
import leftArrow from "../assets/icons/arrow-left.png";

type BeneficiaryDetails = {
  beneficiary_id: number;
  company_name: string;
  company_address: string;
  company_registration_number: string;
  contact_person_name: string;
  email: string;
  phone_number: string;
};

type BeneficiaryAccountDetails = {
  beneficiary_account_id: number;
  beneficiary_id: number;
  account_ref_name: string;
  account_holder_name: string;
  bank: string;
  bank_address: string;
  account_no: string;
  swift_ifsc: string;
  currency: string;
};

export type AllSmartEscrowData = {
  beneficiary_details: BeneficiaryDetails;
  beneficiary_account_details: BeneficiaryAccountDetails;
  smart_escrow: SEDetailsSCPFormType &
    SEDetailsFCPFormType &
    SEConditionsFormType & { smart_escrow_id: number };
};

export const SESummary = () => {
  const { checked, jwtToken, user } = useAppContext();

  const params = useParams();
  const navigate = useNavigate();

  const { data: allSmartEscrowData, loading } = useFetch<AllSmartEscrowData>(
    get_smart_escrow_details,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        smart_escrow_id: params?.smart_escrow_id,
      }),
    }
  );

  // console.log(allSmartEscrowData);

  if (allSmartEscrowData === null || loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="m-10 leading-8">
      <div className="flex">
        <div className="w-full">
          <h3 className="text-lg font-semibold">Sender</h3>
          <p>COMPANY NAME: {user?.company_name}</p>
          <p>SENDER EMAIL: {user?.work_email}</p>
        </div>
        <div className="w-full">
          <h3 className="text-lg font-semibold">Beneficiary</h3>
          <p>
            COMPANY NAME: {allSmartEscrowData.beneficiary_details.company_name}
          </p>
          <p>
            ACCOUNT REFERENCE NAME:{" "}
            {allSmartEscrowData.beneficiary_account_details.account_ref_name}
          </p>
          <p>
            ACCOUNT HOLDER NAME:{" "}
            {allSmartEscrowData.beneficiary_account_details.account_holder_name}
          </p>
          <p>
            ACCOUNT NO./IBAN:{" "}
            {allSmartEscrowData.beneficiary_account_details.account_no}
          </p>
          <p>
            CURRENCY: :{" "}
            {allSmartEscrowData.beneficiary_account_details.currency}
          </p>
          <p>BANK: {allSmartEscrowData.beneficiary_account_details.bank}</p>
          <p>
            BANK ADDRESS:{" "}
            {allSmartEscrowData.beneficiary_account_details.bank_address}
          </p>
          <p>
            SWIFT/IFSC:{" "}
            {allSmartEscrowData.beneficiary_account_details.swift_ifsc}
          </p>
        </div>
      </div>

      <div className="flex mt-5">
        {!checked ? (
          <div className="w-full">
            <h3 className="text-lg font-semibold">Details</h3>
            <p>AMOUNT SENT: {allSmartEscrowData.smart_escrow.amount_sent}</p>
            <p>
              AAMOUNT RECEIVED:{" "}
              {allSmartEscrowData.smart_escrow.amount_received}
            </p>
            <p>CURRENT MARKET RATE: 1USD = 0.98 EURO</p>
            <p>FEES: {allSmartEscrowData.smart_escrow.fees}</p>
            <p>REFERENCE: {allSmartEscrowData.smart_escrow.reference}</p>
            <p>
              FX HEDGING:{" "}
              {allSmartEscrowData.smart_escrow.request_fx_hedging
                ? "checked"
                : "NA"}
            </p>
            <p>
              ESCROW FEES PAID BY:{" "}
              {allSmartEscrowData.smart_escrow.fees_payed_by}
            </p>
          </div>
        ) : (
          <div className="w-full">
            <h3 className="text-lg font-semibold">Details</h3>
            <p>
              TRANSACTION TYPE:{" "}
              {allSmartEscrowData.smart_escrow.transation_type}
            </p>
            <p>ISSUE DATE: {allSmartEscrowData.smart_escrow.issue_date}</p>
            <p>EXPIRY DATE: {allSmartEscrowData.smart_escrow.expiry_date}</p>
            <p>
              LATEST SHIPMENT: {allSmartEscrowData.smart_escrow.latest_shipment}
            </p>
            <p>
              SHIPMENT PERIOD: {allSmartEscrowData.smart_escrow.shipment_period}
            </p>
            <p>
              TRANSACTION AMOUNT:{" "}
              {allSmartEscrowData.smart_escrow.transation_amount}
            </p>
            <p>
              TRANSACTION PI CURRENCY:{" "}
              {allSmartEscrowData.smart_escrow.transaction_pi_currency}
            </p>
            <p>
              TRANSACTION PO TOLERANCE:{" "}
              {allSmartEscrowData.smart_escrow.transaction_po_tolerance}
            </p>
            <p>
              EXCHANGE RATE: : {allSmartEscrowData.smart_escrow.exchange_rate}
            </p>
            <p>
              TRANSACTION AMT TOLERANCE:{" "}
              {allSmartEscrowData.smart_escrow.transaction_amt_tolerance}
            </p>
            <p>DRAWING: {allSmartEscrowData.smart_escrow.drawing}</p>
            <p>DRAWING TERM: {allSmartEscrowData.smart_escrow.drawing_term}</p>
            <p>
              PARTIAL SHIPMENTS:{" "}
              {allSmartEscrowData.smart_escrow.partial_shipments}
            </p>
            <p>
              TRANS SHIPMENT: {allSmartEscrowData.smart_escrow.trans_shipment}
            </p>
            <p>
              AIRPORT OF LOADING:{" "}
              {allSmartEscrowData.smart_escrow.airport_og_loading}
            </p>
            <p>
              AIRPORT OF DISCHARGE: :{" "}
              {allSmartEscrowData.smart_escrow.airport_of_discharge}
            </p>
            <p>TRANSPORT TO: {allSmartEscrowData.smart_escrow.transport_to}</p>
            <p>INCOTERMS</p>
            <p>
              DESCRIPTION OF DELIVERY:{" "}
              {allSmartEscrowData.smart_escrow.description_of_delivery}
            </p>
            <p>INDUSTRY: {allSmartEscrowData.smart_escrow.industry}</p>
            <p>NOTE: {allSmartEscrowData.smart_escrow.note}</p>
            <p>
              ESCROW FEES PAID BY:{" "}
              {allSmartEscrowData.smart_escrow.fees_payed_by}
            </p>
          </div>
        )}

        <div className="w-full">
          <h3 className="text-lg font-semibold">Conditions</h3>
          {allSmartEscrowData?.smart_escrow?.conditions?.map((el, i) => (
            <div key={i}>
              <p>VALUE OF PARTIAL PAYMENT {i + 1}</p>
              {el.document_approval.map((doc, idx) => (
                <div key={idx}>
                  <p>
                    DOCUMENTS:{" "}
                    {doc.doument_file && (
                      <a
                        href={doc.doument_file}
                        target="_blank"
                        className="text-custom-blue underline"
                      >
                        View file
                      </a>
                    )}
                  </p>
                  <p>APPROVER OF DOCUMENT: {doc.approval_of_document}</p>
                </div>
              ))}

              {el.condition_approval.map((doc, idx2) => (
                <p key={idx2}>CONDITION: {doc.condition}</p>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="my-14 flex justify-around">
        <button
          type="button"
          className="w-32 h-14 p-1 border border-custom-blue rounded flex justify-center items-center gap-2 text-custom-blue"
          onClick={() => navigate(-1)}
        >
          <img src={leftArrow} alt="left arrow" />
          <span>Back</span>
        </button>

        <button
          type="submit"
          className="w-32 h-14 p-1 bg-custom-blue text-white rounded"
          onClick={() => {
            navigate("/invoice-factoring/invoice-factoring-details");
          }}
        >
          Approve
        </button>
      </div>
    </div>
  );
};
