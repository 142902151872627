import { useNavigate, useSearchParams } from "react-router-dom";
import DragAndDrop from "../DragAndDrop";
import { ArrowIcon } from "../Icons/ArrowIcon";
import { useFetch } from "../../hooks/useFetch";
import { IFDetailsResponseDataType } from "../../pages/IFDetails";
import { get_invoice_factoring_details } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";

import { calculatePercentage } from "../../utils";
import DocumentIcon from "../Icons/DocumentIcon";

const pendingCreditApproval = [
  {
    id: 1,
    status: "Apply for credit limit for each buyer in Invoice factoring tab.",
    complete: true,
  },
  {
    id: 2,
    status: "Credit limit check from partners",
    complete: true,
  },
  {
    id: 3,
    status: "Request for more documents or Term sheet provided",
    complete: true,
  },
  {
    id: 4,
    status: "Signing of term sheet.",
    complete: false,
  },
  {
    id: 5,
    status: "Request for Trade funding.",
    complete: false,
  },
];

const lastArrElement = (el: number) => el === pendingCreditApproval.length;

export default function Financing() {
  const [searchParams] = useSearchParams();
  const beneficiary_id = searchParams.get("beneficiary_id") || "";

  const { jwtToken } = useAppContext();

  const navigate = useNavigate();

  // console.log(invoice_buyer_id);

  const { data: invoiceData, loading: invoiceDataLoading } =
    useFetch<IFDetailsResponseDataType>(
      beneficiary_id ? get_invoice_factoring_details : null,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ beneficiary_id }),
      },
      beneficiary_id!
    );

  // console.log(invoiceData);
  const usedPercentageAmount = calculatePercentage(
    Number(invoiceData?.invoice_factoring_data?.payment_request || 0),
    Number(invoiceData?.beneficiary_data?.limit_request || 0)
  );

  return (
    <details open className="bg-white rounded-xl group border border-[#f3f3f5]">
      <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
        <span>Financing</span>

        <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
      </summary>
      <div className="mt-2 p-5 bg-white rounded-b-lg">
        {invoiceDataLoading && <FinanceInsightsSkeleton />}

        {!invoiceDataLoading && (
          <>
            <h2 className="font-semibold text-[32px] border-b border-[#D0D5DD] pb-4">
              {invoiceData?.beneficiary_data.company_name || ""}
            </h2>

            <section className="pb-8 border-b border-[#D0D5DD]">
              <div className="my-5">
                <h3 className="font-medium text-[20px]">
                  Pending Credit Approval
                </h3>
                <p>MODIFI</p>
              </div>

              <div className="flex justify-between items-start">
                {pendingCreditApproval.map((el) => (
                  <div key={el.id} className="w-full">
                    <div
                      className={`flex items-center ${
                        lastArrElement(el.id) ? "flex-row-reverse" : ""
                      }`}
                    >
                      <div
                        className={`w-[25px] h-[25px] ${
                          el.complete
                            ? "bg-custom-blue-medium text-white"
                            : "bg-gray-200 text-gray-500"
                        } flex items-center justify-center rounded-full`}
                      >
                        <p className="text-[10px]">0{el.id}</p>
                      </div>
                      <div
                        className={`w-full h-1 ${
                          el.complete ? "bg-custom-blue-medium" : "bg-gray-200"
                        }`}
                      />
                    </div>

                    <div
                      className={`${
                        lastArrElement(el.id) ? "w-full text-end" : "w-[182px]"
                      } `}
                    >
                      <small className="font-medium text-gray-500">
                        {el.status}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="space-y-5 mt-5 pb-5 border-b border-[#D0D5DD]">
              <h3 className="font-medium text-[20px]">Approved Credit</h3>

              <div>
                <div className="flex justify-between items-center mb-5">
                  <div>
                    <h3>
                      {invoiceData?.beneficiary_data.finance_partner_id === 1 &&
                        "Tradewind Financing"}
                      {invoiceData?.beneficiary_data.finance_partner_id === 2 &&
                        "Stenn Financing"}
                    </h3>
                    <p className="text-gray-500">0-USD</p>
                  </div>

                  <div className="text-end">
                    <h3>Credit Limit</h3>
                    <p className="text-gray-500">
                      {invoiceData?.beneficiary_data.limit_request || 0}-USD
                    </p>
                  </div>
                </div>

                <div className="w-full bg-gray-300 rounded-full h-[18px] p-1">
                  <div
                    className="relative bg-custom-blue-medium h-full rounded-full text-center text-white"
                    style={{
                      width: `${invoiceData?.invoice_factoring_data.payment_request}%`,
                    }}
                  >
                    <div className="absolute right-0 -top-2 w-6 h-6 bg-custom-blue-medium rounded-full" />

                    {usedPercentageAmount !== 0 && (
                      <p className="absolute -right-5 -top-12 text-custom-blue-medium text-xs font-bold">
                        Used <br /> {usedPercentageAmount} USD
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* <div>
            <div className="flex justify-between items-center mb-5">
              <div>
                <h3>Stenn Financing</h3>
                <p className="text-gray-500">0-USD</p>
              </div>

              <div className="text-end">
                <h3>Credit Limit</h3>
                <p className="text-gray-500">250,000-USD</p>
              </div>
            </div>

            <div className="w-full bg-gray-300 rounded-full h-[18px] p-1">
              <div
                className="relative bg-custom-blue-medium h-full rounded-full text-center text-white"
                style={{ width: "70%" }}
              >
                <div className="absolute right-0 -top-2 w-6 h-6 bg-custom-blue-medium rounded-full" />

                <p className="absolute -right-5 -top-12 text-custom-blue-medium text-xs font-bold">
                  Used <br /> 175,000 USD
                </p>
              </div>
            </div>
          </div> */}
            </section>

            <section className="space-y-5 mt-5 pb-5 border-b border-[#D0D5DD]">
              <h3 className="font-medium text-[20px]">Tasks</h3>

              <div>
                <div className="flex justify-between items-center text-[#344054] text-sm mb-1">
                  <label htmlFor="">Upload Transaction Invoice (IN44589)</label>
                  <a
                    href={invoiceData?.invoice_factoring_data.invoice_file}
                    className="text-custom-blue-medium underline flex items-center gap-1"
                  >
                    <DocumentIcon className="size-4" />
                    View Invoice Document
                  </a>

                  {/* <p>Update Invoice</p> */}
                </div>
                <DragAndDrop id="" onChange={() => {}} onDrop={() => {}} />
              </div>

              <div>
                <div className="flex justify-between items-center text-[#344054] text-sm mb-1">
                  <label htmlFor="">
                    Upload Financial Statements (CZ44781)
                  </label>

                  <a
                    href={
                      invoiceData?.invoice_factoring_data.purchase_order_file
                    }
                    className="text-custom-blue-medium underline flex items-center gap-1"
                  >
                    <DocumentIcon className="size-4" />
                    View Purchase Order Document
                  </a>
                </div>
                <DragAndDrop id="" onChange={() => {}} onDrop={() => {}} />
              </div>
            </section>
          </>
        )}
        <section className="p-3 mt-2 flex items-center gap-[348px]">
          <button
            className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
            onClick={() => navigate("/dashboard")}
          >
            Back
          </button>

          {/* <div className="flex items-center gap-2">
            <button className="text-4xl text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </button>

            <button className="w-[46px] h-[46px] bg-custom-dark-blue-new text-white rounded-lg">
              1
            </button>
            <button className="w-[46px] h-[46px] text-gray-500 rounded-lg">
              2
            </button>

            <button className="text-4xl text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div> */}
        </section>
      </div>
    </details>
  );
}

function FinanceInsightsSkeleton() {
  return (
    <div className="animate-pulse mt-2 p-5 bg-white rounded-b-lg space-y-4">
      <div className="w-[300px] h-8 bg-gray-400  rounded-md"></div>

      <div className="space-y-2">
        <div className="w-[500px] h-5 bg-gray-400 rounded-md"></div>
        <div className="w-[300px] h-2 bg-gray-400 rounded-md"></div>
      </div>

      <div className="h-2 bg-gray-400  rounded-md"></div>

      <div className="w-[300px] h-8 bg-gray-400 rounded-md"></div>

      <div className="space-y-2">
        <div className="w-[500px] h-5 bg-gray-400 rounded-md"></div>
        <div className="w-[300px] h-2 bg-gray-400 rounded-md"></div>
      </div>

      <div className="h-8 bg-gray-400 rounded-md"></div>

      <div className="h-[300px] bg-gray-400 rounded-md"></div>
    </div>
  );
}
