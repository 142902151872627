import { useAppContext } from "../context/AppContext";
import { z } from "zod";

import { add_beneficary_details, get_country_origin } from "../constants/apis";
import { toast } from "react-toastify";
import CrossIcon from "./Icons/CrossIcon";
import InputWithLabel from "./InputWithLabel";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useFetch } from "../hooks/useFetch";

import SearchInput from "./SearchInput";

import { SPAddBeneficiaryCompanyFormSchema } from "../schemas/SPSenderBeneficiaryType";
import { usePartnersDataContext } from "../context/PartnersDataContext";
import { CreditLimitFormSchema } from "../schemas/BuyerCompanyFormType";

type OriginDetails = {
  origin: string;
  destination: string;
};

type Props = {
  onClose: () => void;
};
export const creditLimitDataFileds = {
  limited_request: 0,
  payment_terms: "",
  terms_conditions_1: false,
  terms_conditions_2: false,
};

export const buyerDataField = {
  invoice_supplier_id: "",
  company_name: "",
  reg_no: "",
  company_address: "",
  contact_person_name: "",
  phone_number: "",
  email: "",
};

const BuyerFormSchema = z.object({
  companies: z.array(
    SPAddBeneficiaryCompanyFormSchema.and(CreditLimitFormSchema)
  ),
  terms_conditions_1: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions",
  }),
  terms_conditions_2: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions.",
  }),
});
type BuyerFormSchemaType = z.infer<typeof BuyerFormSchema>;

const intialFieldValues = {
  company_name: "",
  company_address: "",
  company_registration_number: "",
  contact_person_name: "",
  email: "",
  phone_number: "",
  limit_request: 0,
  payment_terms: "",
};

const RequestCreditLimit: React.FC<Props> = ({ onClose }) => {
  const { jwtToken, user } = useAppContext();
  const { refetch } = usePartnersDataContext();

  // console.log(partnersData);

  const { data: routeCountryData } = useFetch<OriginDetails>(
    get_country_origin,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
    control,
  } = useForm<BuyerFormSchemaType>({
    resolver: zodResolver(BuyerFormSchema),
    defaultValues: { companies: [{}] },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "companies",
  });

  const terms_conditions =
    watch("terms_conditions_1") && watch("terms_conditions_2");

  const onSubmitHandler: SubmitHandler<BuyerFormSchemaType> = async (
    values
  ) => {
    // console.log(values);

    try {
      const response = await fetch(add_beneficary_details, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(values.companies),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();

      if (data.status === "success") {
        reset();
        toast.success("Buyer added Succesfully");

        refetch();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        className="w-[829px] p-8 space-y-5"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="flex justify-between">
          <h1 className="text-xl font-bold">Request Credit Limit</h1>
          <button type="button" onClick={onClose} className="hover:scale-90">
            <CrossIcon className="size-8" />
          </button>
        </div>

        <div className="flex items-center gap-4 pb-5 border-b border-[#EAECF0]">
          <label className="w-full text-[#344054] text-sm font-medium">
            Origin Country *
            <SearchInput
              placeholder="Enter Here"
              name="origin"
              initialCity={routeCountryData?.origin || ""}
              setFormValues={() => {}}
              className="input-new"
            />
          </label>

          <label className="w-full text-[#344054] text-sm font-medium">
            Destination Country *
            <SearchInput
              placeholder="Enter Here"
              name="origin"
              initialCity={routeCountryData?.destination || ""}
              setFormValues={() => {}}
              className="input-new"
            />
          </label>
        </div>

        <div className="pb-5 border-b border-[#EAECF0]">
          <h2 className="font-semibold mb-5">Client Information</h2>

          <div className="flex items-center gap-4">
            <InputWithLabel
              label={"Company Name *"}
              type="text"
              placeholder="Enter Here"
              defaultValue={user?.company_name}
              disabled
            />
            <InputWithLabel
              label={"Registration Number *"}
              type="text"
              placeholder="Enter Here"
              defaultValue={user?.company_reg_number}
              disabled
            />
            <InputWithLabel
              label={"Address *"}
              type="text"
              placeholder="Enter Here"
              disabled
              defaultValue={`${user?.registered_addressLine1}, ${
                user?.registered_addressLine2
                  ? user?.registered_addressLine2 + ","
                  : ""
              } ${user?.registered_city}, ${user?.registered_state}, ${
                user?.registered_country
              }`}
            />
          </div>
        </div>

        <div>
          <div className="pb-2">
            <h2 className="font-semibold">Buyer Information</h2>
          </div>

          {fields.map((field, index) => (
            <div key={field.id} className="space-y-2 pb-2 border-b">
              <h4 className="font-medium text-gray-800 mt-1">
                Company - {index + 1}
              </h4>
              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Company Name *"}
                  type="text"
                  placeholder="Enter Here"
                  error={errors.companies?.[index]?.company_name?.message}
                  {...register(`companies.${index}.company_name`)}
                />
                <InputWithLabel
                  label={"Registration Number *"}
                  type="text"
                  placeholder="Enter Here"
                  error={
                    errors.companies?.[index]?.company_registration_number
                      ?.message
                  }
                  {...register(
                    `companies.${index}.company_registration_number`
                  )}
                />
                <InputWithLabel
                  label={"Address *"}
                  type="text"
                  placeholder="Enter Here"
                  error={errors.companies?.[index]?.company_address?.message}
                  {...register(`companies.${index}.company_address`)}
                />
              </div>
              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Contact Person Name *"}
                  type="text"
                  placeholder="Enter Here"
                  error={
                    errors.companies?.[index]?.contact_person_name?.message
                  }
                  {...register(`companies.${index}.contact_person_name`)}
                />

                <InputWithLabel
                  label={"Email Address*"}
                  type="email"
                  placeholder="Enter Here"
                  error={errors.companies?.[index]?.email?.message}
                  {...register(`companies.${index}.email`)}
                />
                <InputWithLabel
                  label={"Phone Number *"}
                  type="text"
                  placeholder="Enter Here"
                  error={errors.companies?.[index]?.phone_number?.message}
                  {...register(`companies.${index}.phone_number`)}
                />
              </div>

              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Limit Request "}
                  {...register(`companies.${index}.limit_request`)}
                  error={errors.companies?.[index]?.limit_request?.message}
                  // id={"limit_request"}
                  type="text"
                  placeholder="Enter Here"
                />
                <InputWithLabel
                  label={"Payment Terms"}
                  {...register(`companies.${index}.payment_terms`)}
                  error={errors.companies?.[index]?.payment_terms?.message}
                  // id={"payment_terms"}
                  type="text"
                  placeholder="Enter Here"
                />
              </div>

              <div>
                {index !== 0 && (
                  <button
                    className="text-red-600 my-2"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        <button
          type="button"
          onClick={() => {
            append(intialFieldValues);
          }}
          className="w-[208px] h-[44px] px-0 my-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
        >
          Add New Buyer
        </button>

        <div className="space-y-2">
          <label className="flex space-x-1 pt-2 text-sm text-[#344054]">
            <input
              {...register("terms_conditions_1")}
              className="w-[25px]"
              type="checkbox"
            />
            <p>
              I agree with processing of sensitive information provided for thr
              listed trading partners
            </p>
          </label>

          <label className="flex space-x-1 text-sm pb-4 text-[#344054]">
            <input
              {...register("terms_conditions_2")}
              className="w-[25px]"
              type="checkbox"
            />
            <p>
              Does your buyer agree to perform the transaction via invoice
              factoring
            </p>
          </label>
          <p className="h-2 text-xs">
            {errors?.terms_conditions_1 || errors?.terms_conditions_2 ? (
              <span className="text-red-500">
                {errors.terms_conditions_1?.message ||
                  errors.terms_conditions_2?.message}
              </span>
            ) : null}
          </p>
        </div>

        <div className="flex justify-end gap-4 border-t border-[#EAECF0] pt-6">
          <button
            type="button"
            onClick={() => onClose()}
            className="w-[87px] h-[44px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          >
            Cancel
          </button>

          <button
            type="submit"
            className="h-[44px] px-3 rounded-md bg-custom-blue-medium text-white font-medium disabled:cursor-not-allowed disabled:bg-gray-600"
            disabled={!terms_conditions}
          >
            {isSubmitting ? "submitting.." : "Save"}
          </button>
        </div>
      </form>
    </>
  );
};

export default RequestCreditLimit;
