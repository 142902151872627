import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { verify_user } from "../constants/apis";
import SpinnerIcon from "../components/Icons/SpinnerIcon";
import SuccessIcon from "../components/Icons/SuccessIcon";

export default function VerifyEmailPage() {
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const { setJwtToken } = useAppContext();

  const navigate = useNavigate();

  const email = searchParams.get("email");
  const otp = searchParams.get("otp");

  const sendOtpToServer = async (mailId: string, verifyCode: string) => {
    setIsloading(true);

    try {
      const response = await fetch(verify_user, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: mailId,
          verify_code: verifyCode,
        }),
      });

      const data = await response.json();

      //   console.log(data);

      if (data.status == "success") {
        setIsSuccess(true);
        setJwtToken(data.jwt);
      } else if (data.status == "fail") {
        setError(data?.message);
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong!");
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (email && otp) {
      sendOtpToServer(email, otp);
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full space-y-6">
        {/* Header */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">
            Email Verification
          </h2>

          <div>
            {isLoading && (
              <>
                <p className="text-sm text-gray-600 my-2">
                  We're currently verifying your email address. This process may
                  take a moment, so please hang tight!
                </p>
                <SpinnerIcon className="size-16 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
              </>
            )}
          </div>
        </div>

        <div className="space-y-4 text-center">
          <small className="h-2 text-red-400">{error && error}</small>

          {isSuccess && (
            <div>
              <SuccessIcon className="size-16 inline-flex justify-center" />
              <p className="text-green-400 mt-2 mb-4">
                Email verification complete
              </p>
              {/* Submit Button */}
              <button
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => navigate("/dashboard")}
              >
                Go Next
              </button>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="text-center text-sm text-gray-500">
          <p>
            If you’re having trouble, please{" "}
            <a href="#" className="text-blue-600 hover:text-blue-700">
              contact support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
