interface Data {
  data: any;
}

export const InvoiceShowData = ({ data }: Data) => {
  return (
    <div className="p-10 text-left space-y-4">
      <div className=" space-y-2">
        <h3 className="text-custom-blue font-medium">Supplier Information</h3>
        <p>company name: {data?.invoice_supplier_data?.company_name}</p>
        <p>
          company account reference number:{" "}
          {data?.invoice_supplier_data?.account_reference_number}
        </p>
      </div>

      <div className="space-y-2">
        <h3 className="text-custom-blue font-medium">Buyer Information</h3>

        <p>debitor name: {data?.invoice_factoring_data?.debtor_name}</p>
        <p>address: {data?.invoice_factoring_data?.address}</p>
        <p>country: {data?.invoice_factoring_data?.country}</p>
        <p>
          registration number: {data?.invoice_factoring_data?.registration_no}
        </p>
        <p>vat number: {data?.invoice_factoring_data?.vat_no}</p>
        <p>website: {data?.invoice_factoring_data?.website}</p>
        <p>inco terms: {data?.invoice_factoring_data?.incoterms}</p>
        <p>payment method: {data?.invoice_factoring_data?.payment_method}</p>
        <p>
          current turnover: {data?.invoice_factoring_data?.current_turnover}
        </p>
        <p>
          expected turnover: {data?.invoice_factoring_data?.expected_turnover}
        </p>
        <p>
          customer reference: {data?.invoice_factoring_data?.customer_reference}
        </p>
        <p>payment term: {data?.invoice_factoring_data?.payment_term}</p>
        <p>
          credit limit required:{" "}
          {data?.invoice_factoring_data?.credit_limit_required}
        </p>
        <p>
          credit limit approved:{" "}
          {data?.invoice_factoring_data?.credit_limit_approved}
        </p>
        <p>
          sales($) in last 12 months:{" "}
          {data?.invoice_factoring_data?.sales_in_last_12_months}
        </p>
        <p>
          expected sales($) in next 12 months:
          {data?.invoice_factoring_data?.expected_sales_in_next_12_months}
        </p>
        <p>
          years selling to this buyer:
          {data?.invoice_factoring_data?.years_selling_to_this_buyer}
        </p>
      </div>
    </div>
  );
};
