import { z } from "zod";

export const SPSenderBeneficiaryFormSchema = z.object({
  // senderCompanyName: z
  //   .string()
  //   .min(1, "Company name is required")
  //   .min(3, { message: "Company name must be at least 3 characters" }),
  company_name: z
    .string()
    .min(1, "Beneficiary company name is required")
    .min(3, {
      message: "Beneficiary company name must be at least 3 characters",
    }),
  account_ref_name: z.string().min(1, "Account reference is required").min(3, {
    message: "Account reference name must be at least 3 characters",
  }),
  currency: z
    .string()
    .min(1, "Currency is required")
    .min(2, { message: "Currency must be at least 2 characters" }),
  bank: z
    .string()
    .min(1, "Bank details is required")
    .min(3, { message: "Bank must be at least 3 characters" }),
  bank_address: z
    .string()
    .min(1, "Bank address is required")
    .min(3, { message: "Bank address must be at least 3 characters" }),
  account_holder_name: z
    .string()
    .min(1, "Account holder name is required")
    .min(3, { message: "Account holder name must be at least 3 characters" }),
  swift_ifsc: z.string().min(3, {
    message: "SWIFT / IFSC must be at least 3 characters",
  }),
  account_no: z.string().min(1, "ACCOUNT NO. / IBAN is required").min(3, {
    message: "ACCOUNT NO. / IBAN must be at least 3 characters",
  }),
  beneficiary_id: z.number().optional(),
  beneficiary_account_id: z.number().optional(),
});

export type SPSenderBeneficiaryFormType = z.infer<
  typeof SPSenderBeneficiaryFormSchema
>;

export const SPAddBeneficiaryCompanyFormSchema = z.object({
  beneficiary_id: z.number().optional(),
  company_name: z.string().trim().min(1, "company name is required").min(3, {
    message: "Must be at least 3 characters",
  }),
  company_address: z
    .string()
    .trim()
    .min(1, "company address is required")
    .min(3, {
      message: "Must be at least 3 characters",
    }),
  company_registration_number: z
    .string()
    .trim()
    .min(1, "Registration number is required")
    .min(3, {
      message: "Must be at least 3 characters",
    }),
  contact_person_name: z
    .string()
    .trim()
    .min(1, "contact person name is required")
    .min(3, {
      message: "Must be at least 3 characters",
    }),
  email: z.string().trim().min(1, "Email is required").email(),
  phone_number: z.string().trim().min(1, "Phone Number is required").min(8, {
    message: "Must be at least 8 characters",
  }),
  // limit_request: z.number().optional(),
  // payment_terms: z.number().optional(),
});

export const AccountFormSchema = z.object({
  beneficiary_account_id: z.number().optional(),
  account_ref_name: z
    .string()
    .trim()
    .min(1, "Account reference is required")
    .min(3, {
      message: "Account reference name must be at least 3 characters",
    }),
  currency: z
    .string()
    .trim()
    .min(1, "Currency is required")
    .min(2, { message: "Currency must be at least 2 characters" }),
  bank: z
    .string()
    .trim()
    .min(1, "Bank details is required")
    .min(3, { message: "Bank must be at least 3 characters" }),
  bank_address: z
    .string()
    .trim()
    .min(1, "Bank address is required")
    .min(3, { message: "Bank address must be at least 3 characters" }),
  account_holder_name: z
    .string()
    .trim()
    .min(1, "Account holder name is required")
    .min(3, { message: "Account holder name must be at least 3 characters" }),
  swift_ifsc: z.string().trim().min(3, {
    message: "SWIFT / IFSC must be at least 3 characters",
  }),
  account_no: z
    .string()
    .trim()
    .min(1, "ACCOUNT NO. / IBAN is required")
    .min(3, {
      message: "ACCOUNT NO. / IBAN must be at least 3 characters",
    }),
});

export const SPAddBeneficiaryFormSchema = SPAddBeneficiaryCompanyFormSchema.and(
  z.object({
    account_details: z.array(AccountFormSchema),
  })
);

export type SPAddBeneficiaryFormType = z.infer<
  typeof SPAddBeneficiaryFormSchema
>;
