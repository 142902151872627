// src/components/IconButton.tsx

import React from "react";

interface IconButtonProps {
  text?: string;
  icon?: React.ReactNode; // This can be any React element (e.g., SVG, FontAwesome icon)
  onClick?: () => void; // Callback function when the button is clicked
  ariaLabel?: string; // Optional aria-label for accessibility
  iconSource?: string;
  altIcon?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  buttonWidth?: string;
  buttonHeight?: string;
  buttonBorderRadius?: string;
  buttonPadding?: string;
  style?: React.CSSProperties;

  disabled?: boolean;
  className?: string; // Optional className for additional styling
}

const IconButton: React.FC<IconButtonProps> = ({
  text,
  onClick,
  buttonWidth,
  buttonHeight,
  buttonBorderRadius,
  buttonPadding,
  ariaLabel,
  className,
  iconSource,
  altIcon,
  width,
  height,
  backgroundColor,
  disabled,
  ...style
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={ariaLabel}
      className={`inline-flex items-center gap-2 text-custom-blue hover:underline ${className}`}
      disabled={disabled}
    >
      <img style={{ width, height }} src={iconSource} alt={altIcon || "Icon"} />
      <span>{text}</span>
    </button>
  );
};

export default IconButton;
