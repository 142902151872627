import { useAppContext } from "../context/AppContext";
import {
  add_beneficary_details,
  update_beneficary_details,
} from "../constants/apis";
import { toast } from "react-toastify";
import CrossIcon from "./Icons/CrossIcon";
import InputWithLabel from "./InputWithLabel";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, getItemFromLocalStore } from "../utils";
import { SupplierFormScehma, SupplierType } from "../schemas/SupplierType";
import { usePartnersDataContext } from "../context/PartnersDataContext";



type AddNewQASupplierProps = {
  onClose: () => void;
  formType: "add" | "update" | undefined;
  selectedCompany: SupplierType | undefined;
};
const initialDetails = {
  beneficiary_id:undefined,
  company_name: "",
  company_address: "",
  company_registration_number: "",
  contact_person_name: "",
  email: "",
  phone_number: "",
}

const initialSupplierData = {
  company_details: [{
   ...initialDetails
  }]
};

const BENEFICIARY_FORMDATA = "beneficiaryFormData";

const AddQualitySupplier: React.FC<AddNewQASupplierProps> = ({
  onClose,
  formType,
  selectedCompany,
}) => {
  const { jwtToken } = useAppContext();
  const { refetch } = usePartnersDataContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SupplierType>({
    resolver: zodResolver(SupplierFormScehma),
    defaultValues: formType === "add" 
    ? initialSupplierData 
    : selectedCompany ?? initialSupplierData,  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "company_details", // Match `account_details` from the structure
  });
 
  useEffect(() => {
    if (formType === "update" && selectedCompany) {

      console.log(selectedCompany);
      reset({ company_details: [selectedCompany] });
    }
    if (formType === "add") {
      reset(initialSupplierData);
    }else {
      onClose();
    }
  }, [formType, selectedCompany, reset]);
 console.log('errors',errors);
  const onSubmitHandler: SubmitHandler<SupplierType> = async (values) => {
    const url =
      formType === "update"
        ? update_beneficary_details
        : add_beneficary_details;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formType === "update" ? values.company_details[0]:values.company_details),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (data.status === "success") {
        toast.success(
          formType === "update"
            ? "Supplier Details Updated Successfully"
            : "Supplier Added Successfully"
        );
        
        refetch();

        // Reset the form only for "add" mode
        if (formType === "add") {
          reset(initialSupplierData);
        } else {
          onClose(); // Close modal after update
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="w-[50vw] p-8">
      <div className="flex justify-between mb-5">
        <h1 className="text-xl font-bold">
          {capitalizeFirstLetter(formType!)} Supplier Company Details
        </h1>
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="hover:scale-90"
        >
          <CrossIcon className="size-8" />
        </button>
      </div>

      <form className="space-y-3" onSubmit={handleSubmit(onSubmitHandler)}>
        {fields?.map((item, index) => (
          <div key={item.id}>
            <h2 className="mb-2 font-medium">{`Supplier Details ${
              index + 1
            }`}</h2>
            <hr className="border-t-custom-blue-medium" />
            <div className="py-4 space-y-3">
              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Company Name *"}
                  type="text"
                  error={errors?.company_details?.[index]?.company_name?.message}
                  {...register(`company_details.${index}.company_name`)}
                />
                <InputWithLabel
                  label={"Company Address *"}
                  type="text"
                  error={errors?.company_details?.[index]?.company_address?.message}
                  {...register(`company_details.${index}.company_address`)}
                />
              </div>
              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Company Registration Number *"}
                  type="text"
                  error={errors?.company_details?.[index]?.company_registration_number?.message}
                  {...register(`company_details.${index}.company_registration_number`)}
                />
                <InputWithLabel
                  label={"Contact Person Name *"}
                  type="text"
                  error={errors?.company_details?.[index]?.contact_person_name?.message}
                  {...register(`company_details.${index}.contact_person_name`)}
                />
              </div>
              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={"Email Address*"}
                  type="text"
                  error={errors?.company_details?.[index]?.email?.message}
                  {...register(`company_details.${index}.email`)}
                />
                <InputWithLabel
                  label={"Phone Number *"}
                  type="text"
                  error={errors?.company_details?.[index]?.phone_number?.message}
                  {...register(`company_details.${index}.phone_number`)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              {index !== 0 && (
                <button
                  className="text-red-600 mt-2"
                  onClick={() => remove(index)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}
        {formType === "add" && (
          <button
            type="button"
            className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
            onClick={() => append(initialDetails)} // Append new supplier
          >
            Add New Supplier
          </button>
        )}

        <div className="pt-4 flex justify-end gap-2">
          <button
            type="button"
            onClick={() => {
              onClose();
            }}
            className="w-[87px] h-11 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          >
            Cancel
          </button>

          <button
            disabled={isSubmitting}
            className="w-28 h-11 bg-custom-blue-medium text-white font-medium rounded-lg"
          >
            {isSubmitting ? "submitting.." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddQualitySupplier;
