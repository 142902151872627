import { InsuranceFormType } from "../schemas/InsuranceDetailsType";
import { PolicyHolderType } from "../schemas/PolicyHolder";

export const InsuranceShowData = ({
  insuranceData,
  insuredData,
}: {
  insuranceData: InsuranceFormType | null;
  insuredData: PolicyHolderType | null;
}) => {
  return (
    <div
      className="bg-white space-y-3 py-10 pl-10"
      style={{ textAlign: "left" }}
    >
      <p>Company Name: {insuredData?.company_name}</p>
      <p>Company Id:{insuredData?.company_id}</p>
      <p>
        Company Address:
        {insuredData?.company_address}
      </p>
      <p>City:{insuredData?.city}</p>
      <p>Country:{insuredData?.country}</p>
      <p>Email:{insuredData?.email}</p>
      <p>Phone Number:{insuredData?.phone_number}</p>
      <p>Contact Name:{insuredData?.contact_name}</p>
      <p>
        Category of Goods:
        {insuranceData?.category_of_goods}
      </p>
      <p>
        Description of goods:
        {insuranceData?.description_of_goods}
      </p>
      <p>
        Volume of goods:
        {insuranceData?.volume_of_goods}
      </p>
      <p>
        Value of goods:
        {insuranceData?.value_of_goods}
      </p>
      <p>
        Place of origin:
        {insuranceData?.place_of_origin}
      </p>
      <p>
        Place of destination:
        {insuranceData?.place_of_destination}
      </p>
      <p>Type of Insurance Cover:{insuranceData?.type_of_insurance_cover}</p>
      <p>Valid From: {insuranceData?.valid_from}</p>
      <p>Valid To:{insuranceData?.valid_to}</p>
    </div>
  );
};
