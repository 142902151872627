export default function SimplePaymentIconNew({
  className,
}: {
  className: string;
}) {
  return (
    <svg
      viewBox="0 0 28 29"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M18.2499 17.1094C18.051 17.1094 17.8602 17.1884 17.7196 17.329C17.5789 17.4697 17.4999 17.6605 17.4999 17.8594C17.4999 18.0583 17.5789 18.2491 17.7196 18.3897C17.8602 18.5304 18.051 18.6094 18.2499 18.6094H21.7499C21.9488 18.6094 22.1396 18.5304 22.2802 18.3897C22.4209 18.2491 22.4999 18.0583 22.4999 17.8594C22.4999 17.6605 22.4209 17.4697 22.2802 17.329C22.1396 17.1884 21.9488 17.1094 21.7499 17.1094H18.2499ZM2.00391 9.35938C2.00391 8.36481 2.39899 7.41099 3.10226 6.70772C3.80552 6.00446 4.75934 5.60938 5.75391 5.60938H22.2499C23.2445 5.60938 24.1983 6.00446 24.9016 6.70772C25.6048 7.41099 25.9999 8.36481 25.9999 9.35938V19.8594C25.9999 20.8539 25.6048 21.8078 24.9016 22.511C24.1983 23.2143 23.2445 23.6094 22.2499 23.6094H5.75491C4.76034 23.6094 3.80652 23.2143 3.10326 22.511C2.39999 21.8078 2.00491 20.8539 2.00491 19.8594L2.00391 9.35938ZM5.75391 7.10938C5.15717 7.10938 4.58487 7.34643 4.16292 7.76838C3.74096 8.19034 3.50391 8.76264 3.50391 9.35938V10.1094H24.4999V9.35938C24.4999 8.76264 24.2629 8.19034 23.8409 7.76838C23.4189 7.34643 22.8466 7.10938 22.2499 7.10938H5.75391ZM3.50391 19.8594C3.50391 20.4561 3.74096 21.0284 4.16292 21.4504C4.58487 21.8723 5.15717 22.1094 5.75391 22.1094H22.2499C22.8466 22.1094 23.4189 21.8723 23.8409 21.4504C24.2629 21.0284 24.4999 20.4561 24.4999 19.8594V11.6094H3.50491L3.50391 19.8594Z" />
    </svg>
  );
}
