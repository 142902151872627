import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NavBar } from "../NavBar";
import { SignupForm } from "./LoginSignUpForm";
import { useAppContext } from "../../context/AppContext";

import worldmapimg from "../../assets/img/worldmap.png";

export default function HeroSection() {
  const { jwtToken, toggleModal, setModalContent } = useAppContext();
  const navigate = useNavigate();

  return (
    <>
      <NavBar />
      <header>
        <div className="container mx-auto p-20 pt-0 grid grid-cols-2 items-center">
          <div className="mt-20">
            <p className="text-5xl font-bold leading-[60px]">
              The{" "}
              <span className="text-[#155DE9]">
                one-stop <br />
                {/* one-stop <br /> neobank */}
                <TypewriterEffect />
              </span>{" "}
              <br />
              for B2B global
              <br />
              cross-border trade.
            </p>
            <p className="text-[#42474D] text-lg mt-4 max-w-[600px]">
              Book your freight, get trade finance, send money, book quality
              assurance service & pay securely. Grow beyond the borders.
            </p>
            <button
              onClick={() => {
                jwtToken
                  ? navigate("/dashboard")
                  : (setModalContent(<SignupForm />), toggleModal());
              }}
              className="bg-custom-blue-new text-white rounded-lg shadow-xl my-3 px-4 h-[43px] transition-transform duration-200 hover:translate-y-1 mt-4"
            >
              Request a quote
            </button>
          </div>

          <div className="mt-5">
            <img className="w-full" src={worldmapimg} alt="worldmap" />
          </div>
        </div>
      </header>
    </>
  );
}

const TypewriterEffect = () => {
  const [textContent, setTextContent] = useState("");
  const [curPhraseIndex, setCurPhraseIndex] = useState(0);

  const phrases = [
    "neobank",
    "Freight forwarder",
    "trade finance solution",
    "solution",
  ];

  const sleepTime = 200; // Speed of typing
  const deleteSpeed = 150; // Speed of deleting
  const pauseAfterTyping = 1000; // Pause after typing before deleting
  const pauseAfterDeleting = 500; // Pause after deleting before typing next word

  // Sleep function to control the timing
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const typeAndDelete = async () => {
      const currentPhrase = phrases[curPhraseIndex];

      // Typing phase
      for (let i = 0; i < currentPhrase.length; i++) {
        setTextContent(currentPhrase.substring(0, i + 1));
        await sleep(sleepTime);
      }

      // Pause before deleting
      await sleep(pauseAfterTyping);

      // Deleting phase
      for (let i = currentPhrase.length; i > 0; i--) {
        setTextContent(currentPhrase.substring(0, i - 1));
        await sleep(deleteSpeed);
      }

      // Pause after deleting
      await sleep(pauseAfterDeleting);

      // Move to the next phrase
      setCurPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    };

    typeAndDelete();
  }, [curPhraseIndex]);

  return (
    <span>
      <span>{textContent}</span>
      <span className="cursor-blink">|</span>
    </span>
  );
};
