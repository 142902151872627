import { createContext, useContext } from "react";
import { z } from "zod";

import { useFetch } from "../hooks/useFetch";
import { get_all_simple_payment_details } from "../constants/apis";
import { useAppContext } from "./AppContext";
import {
  AccountFormSchema,
  SPAddBeneficiaryCompanyFormSchema,
} from "../schemas/SPSenderBeneficiaryType";
import { SPDetailsFormType } from "../schemas/SPDetailsFieldType";

export type SimplePayment = {
  beneficiary_account_details: z.infer<typeof AccountFormSchema>;
  beneficiary_details: z.infer<typeof SPAddBeneficiaryCompanyFormSchema>;
  simple_payment: SPDetailsFormType & {
    simple_payment_id: number;
    created_at: string;
    save_as_draft: 0 | 1;
    step: number;
  };
};

type SimplePaymentData = {
  data: SimplePayment[] | null;
  loading: boolean;
  refetch: () => void;
};

const SimplePaymentDataContext = createContext<SimplePaymentData | null>(null);

export const SimplePaymentDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwtToken } = useAppContext();

  const { data, loading, refetch } = useFetch<SimplePayment[]>(
    jwtToken ? get_all_simple_payment_details : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <SimplePaymentDataContext.Provider value={{ data, loading, refetch }}>
      {children}
    </SimplePaymentDataContext.Provider>
  );
};

export const useSimplePaymentDataContext = () => {
  const context = useContext(SimplePaymentDataContext);

  if (!context)
    throw new Error(
      "usePartnersDataContext must be used within an PartnersDatContext provider"
    );

  return context;
};
