import { createContext, useContext } from "react";
import { BuyerCompanyData } from "../pages/IFDetails";
import { useFetch } from "../hooks/useFetch";
import { get_all_invoice_buyer_details } from "../constants/apis";
import { useAppContext } from "./AppContext";

type IFBuyerDataContextType = {
  buyerData: BuyerCompanyData[] | null;
  loading: boolean;
  refetch: () => void;
};

const IFBuyerDataContext = createContext<IFBuyerDataContextType | null>(null);

export const IFBuyerDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwtToken } = useAppContext();

  const {
    data: buyerData,
    refetch,
    loading,
  } = useFetch<BuyerCompanyData[]>(
    jwtToken ? get_all_invoice_buyer_details : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <IFBuyerDataContext.Provider value={{ buyerData, loading, refetch }}>
      {children}
    </IFBuyerDataContext.Provider>
  );
};

export const useIFBuyerDataContext = () => {
  const context = useContext(IFBuyerDataContext);

  if (!context)
    throw new Error(
      "useIFBuyerDataContext must be used within an IFBuyerDataContextProvider"
    );

  return context;
};
