import partner1 from "../../assets/img/partner1.png";
import partner2 from "../../assets/img/partner2.png";
import partner3 from "../../assets/img/partner3.png";
import partner4 from "../../assets/img/partner4.png";
// import partner5 from "../../assets/img/partner5.png";
// import partner6 from "../../assets/img/partner6.png";
import partner7 from "../../assets/img/partner7.png";
import partner8 from "../../assets/img/partner8.png";

const partners = [
  { id: 1, logo: partner1 },
  { id: 2, logo: partner2 },
  { id: 3, logo: partner3 },
  { id: 4, logo: partner4 },
  { id: 5, logo: partner8 },
  { id: 6, logo: partner7 },
];

export default function PartnersSection() {
  return (
    <section className="">
      <div className="container mx-auto">
        <h2 className="font-bold text-3xl text-[#1A1D21] ml-20 mb-4">
          Meet our partners
        </h2>
      </div>

      <div className="container mx-auto mt-10 px-20">
        <div className="flex items-center justify-between mb-10">
          {partners.map((el) => (
            <img key={el.id} src={el.logo} alt="logo" />
          ))}
        </div>
      </div>
    </section>
  );
}
