import { useFormContext } from "react-hook-form";
import { IFDetailsType } from "../schemas/IFDetailsType";

export const IFSummary = () => {
  const { getValues } = useFormContext<IFDetailsType>();

  const invoiceData = getValues();

  return (
    <section>
      <div className="my-5">
        <h3 className="font-semibold mb-5">Buyer Information</h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm font-medium text-custom-blue">Company Name</p>
            <p className="text-gray-700 py-1">{invoiceData?.company_name}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-custom-blue">
              Company Regsitration Number
            </p>
            <p className="text-gray-700">
              {invoiceData?.company_registration_number}
            </p>
          </div>
          <div>
            <p className="text-sm font-medium text-custom-blue">
              Company Address
            </p>
            <p className="text-gray-700">{invoiceData?.company_address}</p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm font-medium text-custom-blue">
              Contact person name
            </p>
            <p className="text-gray-700 py-1">
              {invoiceData?.contact_person_name}
            </p>
          </div>
          <div>
            <p className="text-sm font-medium text-custom-blue">Email</p>
            <p className="text-gray-700">{invoiceData?.email}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-custom-blue">Phone number</p>
            <p className="text-gray-700">{invoiceData?.phone_number}</p>
          </div>
        </div>
      </div>

      <div>
        <h3 className="font-semibold mb-5">Invoice Factoring Information</h3>

        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm font-medium text-custom-blue">
              Shipment date
            </p>
            <p className="text-gray-700 py-1">{invoiceData?.shipment_date}</p>
          </div>

          <div>
            <p className="text-sm font-medium text-custom-blue">Finance term</p>
            <p className="text-gray-700 py-1">{invoiceData?.finance_term}</p>
          </div>

          <div>
            <p className="text-sm font-medium text-custom-blue">
              Invoice amount
            </p>
            <p className="text-gray-700 py-1">{invoiceData?.invoice_amount}</p>
          </div>

          <div>
            <p className="text-sm font-medium text-custom-blue">Invoice file</p>
            <a
              className="text-gray-700 underline"
              href={invoiceData?.invoice_file}
            >
              Invoice file
            </a>
          </div>
          <div>
            <p className="text-sm font-medium text-custom-blue">
              Purchase order file
            </p>
            <a
              className="text-gray-700 underline"
              href={invoiceData?.purchase_order_file}
            >
              Purchase order file
            </a>
          </div>

          <div>
            <p className="text-sm font-medium text-custom-blue">
              Payment request
            </p>
            <p className="text-gray-700">{invoiceData?.payment_request} %</p>
          </div>
        </div>
      </div>
    </section>
  );
};
