import { useState } from "react";
import UploadIcon from "./Icons/UploadIcon";

type Props = {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
};

export default function DragAndDrop({ id, onChange, onDrop }: Props) {
  const [dragging, setDragging] = useState(false);

  // Handle the drag over event
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    // setDragging(true);
    if (!dragging) setDragging(true);
  };

  // Handle the drag leave event
  const handleDragLeave = () => {
    setDragging(false);
  };

  return (
    <div
      className={`border ${
        dragging ? "border-green-400" : "border-[#EAECF0]"
      } rounded-xl p-6`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => {
        onDrop(e);
        setDragging(false);
      }}
    >
      {/* File Input */}
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, application/pdf"
        id={id}
        className="hidden"
        onChange={onChange}
      />

      {/* Drag and Drop Text */}
      <div className="text-center">
        <label
          htmlFor={id}
          className="text-[#142857] cursor-pointer font-medium inline-flex flex-col justify-center items-center"
          onDragOver={(e) => e.stopPropagation()}
        >
          <UploadIcon className="size-12" />
          Click to upload
        </label>

        <p className="text-[#344054]">
          {dragging ? "Release to drop the files" : "or drag and drop"}
        </p>
      </div>
    </div>
  );
}
