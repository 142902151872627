import React, { useEffect, useState } from "react";
import InputWithLabel from "../components/InputWithLabel";
import Button from "../components/Button";
import { blueColor } from "../constants/colors";
import { AltertBox } from "../components/AltertBox";
import checkIcon from "../assets/icons/check.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  add_insurance_details,
  update_insurance_details,
  get_insurance_details,
} from "../constants/apis";
import leftArrow from "../assets/icons/arrow-left.png";
import {
  InsuranceFormSchema,
  InsuranceFormType,
} from "../schemas/InsuranceDetailsType";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppContext } from "../context/AppContext";
import { useFetch } from "../hooks/useFetch";
import { today } from "../utils";
import Spinner from "../components/Spinner";

export const InsuranceDetails = () => {
  const [isProcessed, setProcessed] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const insuredDetailsId = searchParams.get("insuredDetailsId");
  const { jwtToken } = useAppContext();

  const { data: insuranceData ,loading:insuredDataLoading} = useFetch<
    InsuranceFormType & { insurance_id: number }
  >(get_insurance_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting },
  } = useForm<InsuranceFormType>({
    resolver: zodResolver(InsuranceFormSchema),

    values: insuranceData!,
  });

  const onSubmitHandler: SubmitHandler<InsuranceFormType> = async (values) => {
    console.log(values);
    try {
      const response = await fetch(
        insuranceData?.insurance_id
          ? update_insurance_details
          : add_insurance_details,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            insuranceData?.insurance_id
              ? {
                  ...values,
                  insured_details_id: insuredDetailsId,
                  insurance_id: insuranceData?.insurance_id,
                }
              : {
                  ...values,
                  insured_details_id: insuredDetailsId,
                  cid: 1,
                }
          ),
        }
      );

      if (!response.ok) throw new Error("Failed to submit insurance details");

      // setProcessed(true);
      const result = await response.json();
      if (result.status == "success") {
        toast.success(result?.message || result?.msg);
        navigate(
          `/insurance/summary?insuredDetailsId=${insuranceData?.insurance_id}`
        );
      }
      // setInsuranceId(result.insurance_id);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  // const onSubmitHandler:SubmitHandler<InsuranceFormType> = async(values)=>{
  //   toast.success('Data Uploaded Successfully');
  // }

  console.log(errors);

  
  if (insuredDataLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <section className="px-8">
      <h3 className="text-black text-2xl font-semibold mb-5">
      Details</h3>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-2">
        <div className="flex justify-between gap-10">
          <InputWithLabel
            error={errors.category_of_goods?.message}
            {...register("category_of_goods")}
            label={"Category of Goods"}
          />
          <InputWithLabel
            error={errors.description_of_goods?.message}
            {...register("description_of_goods")}
            label={"Description of Goods"}
          />
           <InputWithLabel
            error={errors.invoice_amount?.message}
            {...register("invoice_amount")}
            label={"Invoice Amount"}
          />
        </div>
        <div className="flex justify-between gap-10">
          <InputWithLabel
            error={errors.volume_of_goods?.message}
            {...register("volume_of_goods")}
            label={"Volume of Goods"}
          />
            <InputWithLabel
            error={errors.place_of_origin?.message}
            {...register("place_of_origin")}
            label={"Place of Origin"}
          />
          <InputWithLabel
            error={errors.place_of_destination?.message}
            {...register("place_of_destination")}
            label={"Place of Destination"}
          />
        </div>
        <div className="flex justify-between gap-10">
        
        </div>
        <div className="flex justify-between gap-10">
          <InputWithLabel
            error={errors.type_of_insurance_cover?.message}
            {...register("type_of_insurance_cover")}
            label={"Type of Insurance Cover"}
          />
          <InputWithLabel
            type="date"
            error={errors.valid_from?.message}
            {...register("valid_from")}
            label={"Valid From"}
            min={today}
          />
          <InputWithLabel
            type="date"
            error={errors.valid_to?.message}
            {...register("valid_to")}
            label={"Valid To"}
            min={today}
          />
        </div>

        <div className="space-x-4 py-4 flex justify-between items-center">
          <button
            type="button"
            onClick={()=>navigate(-1)}
            className="w-[73px] h-[44px] px-2 text-[#344054] font-medium border border-[#D0D5DD] rounded-lg"
          >
            Back
          </button>

          <button
            type="submit"
            className="w-[91px] h-[44px] rounded-md bg-[#0348B7] text-white font-medium"
          >
            {isSubmitting ? "Submitting.." : "Submit"}
          </button>
        </div>
      </form>
      {/* {isProcessed && (
        <AltertBox
          iconSource={checkIcon}
          alertText="Successfully Submitted"
          alertText1="Quotes will be processed within 2 business days and result will appear in dashboard."
          alertText2="Thank you for your patience."
          buttonTitle="Transaction Summary"
          handleClick={() => navigate("/transaction-summary")}
        />
      )} */}
    </section>
  );
};
