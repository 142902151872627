import { z } from "zod";

export const SimpleRegistrationLoginSchema = z.object({
  email: z.string().email({ message: "Enter a valid email" }),
  password: z
    .string()
    .min(1, "Password is required")
    .min(3, "Password must be at least 3 characters")
    .max(20),
});

export type SimpleRegistrationLoginType = z.infer<
  typeof SimpleRegistrationLoginSchema
>;

export const SimpleRegistrationSignupSchema = z
  .object({
    firstName: z
      .string()
      .trim()
      .min(1, "First name is required")
      .max(50, "Can't be more than 20 characters"),
    middleName: z.string().optional(),
    lastName: z
      .string()
      .trim()
      .min(1, "Last name is required")
      .max(50, "Can't be more than 20 characters"),
    companyName: z
      .string()
      .min(1, "Company name is required")
      .min(2, "Must be at least 3 characters"),
    // jobTitle: z
    //   .string()
    //   .min(1, "Job title is required")
    //   .min(3, "Must be at least 3 characters"),

    workEmail: z.string().email({ message: "Enter a valid email" }),
    countryCode: z.string().trim().min(1, "Country code is required"),
    // city: z.string().min(3, "Enter a valid city"),
    // contactName: z
    // .string()
    // .min(3, "Enter a valid contact Name"),
    businessPhone: z
      .string()
      .min(1, "Business phone is required")
      .min(6, "Enter a valid Business phone number")
      .max(20, "Business phone is max 5 characters"),
    // countryRegion: z
    //   .string()
    //   .min(1, "Country region is required")
    //   .min(2, "Must be at least 2 characters")
    //   .max(20, "Country region is max 20 characters"),
    companyRegNumber: z
      .string()
      .min(1, "Company Reg number is required")
      .max(50, "Company Reg number is max 5 characters"),
    // companyRegAddress: z
    //   .string()
    //   .trim()
    //   .min(1, "Company reg address is required"),

    password: z
      .string()
      .min(1, "Password is required")
      .min(3, "Password must be at least 3 characters")
      .max(20),
    retypePassword: z
      .string()
      .min(1, "Password is required")
      .min(3, "Password must be at least 3 characters")
      .max(20),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.retypePassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords must match",
        path: ["retypePassword"],
      });
    }
  });

export type SimpleRegistrationSignupType = z.infer<
  typeof SimpleRegistrationSignupSchema
>;
