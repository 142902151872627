import { useCallback, useEffect, useReducer, useRef, useState } from "react";
// import { useAppContext } from "../context/AppContext";

// Define types for the state and actions
interface FetchState<T> {
  data: T | null;
  error: string | null;
  loading: boolean;
}

type FetchAction<T> =
  | { type: "FETCHING" }
  | { type: "FETCHED"; payload: T }
  | { type: "FETCH_ERROR"; payload: string };

// Reducer function to handle state transitions
const fetchReducer = <T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true, error: null, data: null };
    case "FETCHED":
      return { ...state, loading: false, data: action.payload, error: null };
    case "FETCH_ERROR":
      return { ...state, loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export const useFetch = <T>(
  url: string | null,
  config: RequestInit,
  dependency?: string | number,
  queryKey?: string
) => {
  const [state, dispatch] = useReducer(
    fetchReducer as React.Reducer<FetchState<T>, FetchAction<T>>,
    {
      data: null,
      error: null,
      loading: false,
    }
  );

  const [fetchKey, setFetchKey] = useState<string>("");

  const abortController = useRef<AbortController | null>(null);

  // const { jwtToken } = useAppContext();

  const refetch = useCallback(() => {
    setFetchKey(Date.now().toString());
  }, []);

  useEffect(() => {
    if (!url) return;

    abortController.current = new AbortController();
    const signal = abortController.current.signal;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });

      // Check if the data is cached and if we don't need to force a fetch
      const cachedData = sessionStorage.getItem(queryKey || url || "");

      if (cachedData && !fetchKey) {
        dispatch({ type: "FETCHED", payload: JSON.parse(cachedData) as T });
        return;
      }

      try {
        const response = await fetch(url, { signal, ...config });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const { status, data } = (await response.json()) as {
          status: string;
          data: T;
        };

        if (data === undefined) {
          dispatch({ type: "FETCHED", payload: null! });

          return;
        }

        // Cache the data
        sessionStorage.setItem(queryKey || url || "", JSON.stringify(data));
        if (signal.aborted) return;

        dispatch({ type: "FETCHED", payload: data });
      } catch (error: any) {
        if (signal.aborted) return;

        dispatch({ type: "FETCH_ERROR", payload: error.message });
      }
    };

    fetchData();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [url, dependency, fetchKey]);

  useEffect(() => {
    if (dependency) {
      refetch();
    }
  }, [dependency]);

  return { ...state, refetch };
};
