import React, { ChangeEvent } from "react";
// import "../constants/styling.css";

interface StyleProps {
  width?: string;
  height?: string;
  placeholder?: string | any;
  backgroundColor?: string;
  borderRadius?: string;
  style?: React.CSSProperties;
  fontStyle?: string;
  value?: string;
  id?: string;
  className?: string;
  marginInline?: string;
  errorMessage?: string;
  inputType?: any;
  marginBlock?: string;
  name?: string;
  maxLength?: number;
  onInput?: any;
  pattern?: string;
  marginRight?: string;
  marginLeft?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  maxValue?: any;
  disabled?: boolean;
}

export const TextInput: React.FC<StyleProps> = ({
  width,
  pattern,
  onInput,
  maxValue,
  name,
  className,
  maxLength,
  height,
  marginRight,
  marginLeft,
  inputType,
  placeholder,
  id,
  style,
  marginBlock,
  backgroundColor,
  value,
  errorMessage,
  onChange,
  disabled,
}) => {
  const combinedStyles: React.CSSProperties = {
    width,
    height,
    marginLeft,
    marginRight,
    marginBlock,
    backgroundColor,
    ...style,
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          className={`text-input ${className}`}
          style={combinedStyles}
          placeholder={placeholder}
          value={value}
          type={inputType}
          pattern={pattern}
          onChange={onChange}
          name={name}
          onInput={onInput}
          maxLength={maxLength}
          max={maxValue}
          id={id}
          disabled={disabled}
        />
        {/* <p style={{width:'fit-content',marginBlock:'10px',color:'red'}}>{errorMessage}</p> */}
      </div>
    </>
  );
};
