export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // The result is a base64 encoded string
      const base64String = reader.result as string;

      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
};

// DD-MM-YYYY format
export const today = new Date().toISOString().split("T")[0];

// debounce function
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getItemFromLocalStore = (key: string) =>
  JSON.parse(localStorage.getItem(key)!);

export function getDaysBetweenDates(
  date1: string | Date,
  date2: string | Date
): number {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Validate if the dates are valid
  if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
    throw new Error("Invalid date(s) provided");
  }

  // Calculate the difference in milliseconds
  const diffInMilliseconds = Math.abs(d2.getTime() - d1.getTime());

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const days = diffInMilliseconds / (1000 * 60 * 60 * 24);

  return days;
}

// DD-MM-YYYY format from a timestamp
export function formattedDate(date: string) {
  const d = new Date(date);

  return `${d.getMonth() + 1}-${d.getDay()}-${d.getFullYear()}`;
}

export function calculatePercentage(part: number, total: number): number {
  return parseFloat(((part / 100) * total).toFixed(2));
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}
