import { ReactNode } from "react";

interface TooltipProps {
  children?: ReactNode; // The element around which the tooltip will appear
  content: ReactNode; // Tooltip text/content
}

const Tooltip: React.FC<TooltipProps> = ({ children = "?", content }) => {
  return (
    <div className="relative group mb-1">
      <button className="w-4 h-4 text-white text-xs bg-custom-blue-new rounded-full">
        {children}
      </button>

      <div className="absolute max-w-[200px] bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-max p-2 bg-gray-800 text-white text-xs rounded opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
        {content}
        <div className="absolute bottom-[-8px] left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-45"></div>
      </div>
    </div>
  );
};

export default Tooltip;
