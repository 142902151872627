const LogisticsIcon = ({ className }: { className: string }) => (
  <svg
    viewBox="0 0 22 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M17.875 18.6094C18.6344 18.6094 19.25 17.9378 19.25 17.1094C19.25 16.2809 18.6344 15.6094 17.875 15.6094C17.1156 15.6094 16.5 16.2809 16.5 17.1094C16.5 17.9378 17.1156 18.6094 17.875 18.6094Z" />
    <path d="M13.75 18.6094C14.5094 18.6094 15.125 17.9378 15.125 17.1094C15.125 16.2809 14.5094 15.6094 13.75 15.6094C12.9906 15.6094 12.375 16.2809 12.375 17.1094C12.375 17.9378 12.9906 18.6094 13.75 18.6094Z" />
    <path d="M9.625 6.60938C10.3844 6.60938 11 5.9378 11 5.10938C11 4.28095 10.3844 3.60938 9.625 3.60938C8.86561 3.60938 8.25 4.28095 8.25 5.10938C8.25 5.9378 8.86561 6.60938 9.625 6.60938Z" />
    <path d="M5.5 6.60938C6.25939 6.60938 6.875 5.9378 6.875 5.10938C6.875 4.28095 6.25939 3.60938 5.5 3.60938C4.74061 3.60938 4.125 4.28095 4.125 5.10938C4.125 5.9378 4.74061 6.60938 5.5 6.60938Z" />
    <path d="M5.66088 18.6094C6.95976 18.6105 8.22595 18.1652 9.27965 17.3366C10.3334 16.5081 11.121 15.3385 11.5307 13.9939L13.1484 8.69888C13.467 7.65295 14.0796 6.74315 14.8992 6.09876C15.7189 5.45438 16.7038 5.10818 17.7141 5.10938H20.625V3.60938H17.7141C16.4152 3.60826 15.149 4.05359 14.0953 4.88212C13.0416 5.71065 12.254 6.88026 11.8443 8.22488L10.2266 13.5199C9.90801 14.5658 9.29539 15.4756 8.47576 16.12C7.65612 16.7644 6.67118 17.1106 5.66088 17.1094H2.75V2.10938H1.375V21.6094C1.375 22.4366 1.99169 23.1094 2.75 23.1094H20.625V21.6094H2.75V18.6094H5.66088Z" />
  </svg>
);

export default LogisticsIcon;
