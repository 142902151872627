import { z } from "zod";
export const SupplierFormScehma = z.object({
  company_details: z.array(
    z.object({
      beneficiary_id:z.number().optional(),
      company_name: z
        .string()
        .min(1,"Comapny Name is required")
        .max(100, "Company name must be at most 100 characters"),
      company_address: z
        .string()
        .min(1,"Comapny Name is required")
        .max(200, "Company address must be at most 200 characters"),
      company_registration_number: z
        .string()
        .min(1,"Comapny Name is required")
        .max(50, "Registration number must be at most 50 characters"),
      contact_person_name: z
        .string()
        .min(1,"Comapny Name is required")
        .max(50, "Contact person name must be at most 50 characters"),
      email: z
        .string()
        .min(1,"Comapny Name is required")
        .email("Invalid email address"),
      phone_number: z
        .string()
        .min(1,"Comapny Name is required")
        .regex(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format"),
    })
  ),
});


// export const AddSCompanyFormSchema = z.object({
//   SupplierFormScehma,
// });

export type SupplierType = z.infer<typeof SupplierFormScehma>;
