import Button from "../components/Button"
import IconButton from "../components/IconButton"
import TextInputWithLabel from "../components/TextInputWithLabel"
import { blackColor, blueColor, whiteColor } from "../constants/colors"

const DashboardRequestQuote = () => {
  return (
    <div>
        
      <div style={{width:'90%',marginLeft:'5.5%'}}>
      <p style={{color:blackColor, fontSize:'18px'}}>Supplier Information</p>
      </div>
      <div style={{marginInline:'auto',display:'flex',flexDirection:'row',gap:'40px',paddingInline:'3.5%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center',}}>
      <TextInputWithLabel  width="90%"   height="56px" label={"CLIENT NAME"} id={"dashboardSenderClientName"} marginInline='5%' backgroundColor={whiteColor}/>
      <TextInputWithLabel  width="90%" height="56px" label={"ADDRESS"} id={"dashboardClientAddress"} marginInline='5%' backgroundColor={whiteColor}/>
     </div>
      
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"PERSON INCHARGE"} id={"dashboardPersonInCharge"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"DESIGNATION"} id={"dashboardDesignation"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"WEBSITE"} id={"dashboardWebsite"} placeholder='' backgroundColor={whiteColor}/>
     </div>

     <div style={{width:'90%',marginLeft:'6%'}}>
      <p style={{color:blackColor, fontSize:'18px'}}>Buyer Information</p>
      </div>
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"DEBITOR NAME"} id={"dashboardPersonInCharge"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"ADDRESS"} id={"dashboardDesignation"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"COUNTRY"} id={"dashboardCountry"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"WEBSITE"} id={"dashboardWebsite"} placeholder='' backgroundColor={whiteColor}/>
     </div>
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"REGISTRATION NO."} id={"dashboardRegistrationno"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"VAT NO."} id={"dashboardVatNo"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"INCO TERMS"} id={"dashboardIncoTerms"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="70%" marginInline= '15%' height="56px" label={"PAYMENT METHOD"} id={"dashboardPaymentMethod"} placeholder='' backgroundColor={whiteColor}/>
     </div>
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"CURRENT TURNOVER"} id={"dashboardCurrentTurnover"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"EXPECTED TURNOVER"} id={"dashboardExpectedTurnover"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"CUSTOMER REFERENCE"} id={"dashboardCustomerReference"} placeholder='' backgroundColor={whiteColor}/>
     </div>
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"PAYMENT TERM"} id={"dashboardPaymentTerm"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"CREDIT LIMIT REQUIRED"} id={"dashboardCreditLimitRequired"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"CREDIT LIMIT APPROVED"} id={"dashboardCreditLimitApproved"} placeholder='' backgroundColor={whiteColor}/>
     </div>
     <div style={{marginInline:'auto',display:'flex',flexDirection:'row', gap:'40px',paddingInline:'3%',paddingBlock:'1%',justifyContent:'space-between',alignItems:'center'}}>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"SALES ($) IN LAST 12 MONTHS"} id={"dashboardSalesLast"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"EXPECTED SALES ($) IN NEXT 12 MONTHS"} id={"dashboardSalesNext"} placeholder='' backgroundColor={whiteColor}/>
     <TextInputWithLabel  width="80%" marginInline= '10%' height="56px" label={"YEARS OF SELLING TO THIS BUYER"} id={"dashboardYearsOfSelling"} placeholder='' backgroundColor={whiteColor}/>
     </div>
     <div style={{display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center',marginInline:'auto',marginBlock:'50px',alignItems:'center'}}>
     <Button buttonTitle='REQUEST A QUOTE' backgroundColor={blueColor} width='80%' height='56px' borderRadius='4px' />
</div>
    
    
    </div>
  )
}

export default DashboardRequestQuote
