import { useCallback, useEffect, useState } from "react";
import IconTextInput from "./IconTextInput";

import { get_geonames_api } from "../constants/apis";

// import mapPin from "../assets/icons/pin.png";
import { FormValuesType } from "../pages/LCargoDetails";
import { getItemFromLocalStore } from "../utils";
import { debounce } from "../utils";

export type GeoNames = {
  adminCode1: string;
  lng: string;
  geonameId: number;
  countryCode: string;
  name: string;
  countryName: string;
  lat: string;
};

export default function SearchInput({
  placeholder,
  name,
  initialCity = "",
  setFormValues,
  className,
  icon,
}: {
  placeholder: string;
  name: string;
  initialCity: string;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  className?: string;
  icon?: string;
}) {
  const [inputValue, setInputValue] = useState(initialCity);
  const [suggestions, setSuggestions] = useState<GeoNames[]>([]);
  const [isSelected, setIsSelected] = useState(false);

  const getSuggestions = async (query: string) => {
    try {
      const response = await fetch(
        `${get_geonames_api}/searchJSON?q=${query}&maxRows=4&username=sarathisahoo`
      );

      const data = (await response.json()) as { geonames: GeoNames[] };

      // console.log(data);

      if (data.geonames) {
        setSuggestions(data.geonames);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSuggestionsDebounced = useCallback(
    debounce(getSuggestions, 500),
    []
  );

  useEffect(() => {
    const cargoDetails = getItemFromLocalStore("cargo-details");
    if (cargoDetails) {
      // console.log(cargoDetails, isSelected);

      setIsSelected(true);
    }
  }, []);

  useEffect(() => {
    if (initialCity) {
      setInputValue(initialCity);
      setIsSelected(true);
    }
  }, [initialCity]);

  useEffect(() => {
    if (inputValue.length > 1) {
      if (isSelected) return;

      getSuggestionsDebounced(inputValue);
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleFromInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsSelected(false);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));

    return (
      <span>
        {parts.map((part, index) => {
          return part.toLocaleLowerCase() === highlight.toLocaleLowerCase() ? (
            <b key={index} className="text-custom-blue font-semibold">
              {part}
            </b>
          ) : (
            part
          );
        })}
      </span>
    );
  };

  return (
    <div className="relative w-full">
      <IconTextInput
        type="text"
        placeholder={placeholder}
        name={name}
        icon={icon}
        onChange={handleFromInputChange}
        value={inputValue}
        autoComplete="off"
        className={className}
      />
      <ul className="absolute bg-white w-full mt-1 rounded z-50 shadow-xl">
        {!isSelected &&
          suggestions?.map((location) => (
            <li
              key={location.geonameId}
              onClick={() => {
                setInputValue(`${location.name}, ${location.countryCode}`);
                setFormValues((prev: FormValuesType) => ({
                  ...prev,
                  [name]: location,
                }));

                setIsSelected(true);
                setSuggestions([]);
              }}
              className="p-[5px] cursor-pointer hover:bg-custom-off-white"
              // >{`${location.name}, ${location.countryCode}`}</li>
            >
              {getHighlightedText(
                `${location.name}, ${location.countryCode}`,
                inputValue
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}
