import { Link } from "react-router-dom";
import { tasks } from "./Task";

export default function Messages() {
  return (
    <section className="card grayscale brightness-90">
      <div className="flex items-center justify-between mb-4 ">
        <h2 className="text-[#030303] font-semibold text-xl">Messages</h2>

        <div>
          <Link to={""} className="text-custom-blue-new font-semibold">
            View all
          </Link>
        </div>
      </div>

      <div className="space-y-4">
        {tasks.map((task) => (
          <div
            key={task.id}
            className={`flex items-center gap-3 px-2 py-3 ${
              task.id === 1 && "bg-[#EEF7FF] rounded-lg"
            }`}
          >
            <div>
              <div className="w-[48px] h-[48px] bg-gray-400 rounded-full"></div>
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold leading-2">
                  {task.name}{" "}
                  <span className="font-normal text-xs text-gray-400">
                    {task.time}
                  </span>
                </p>

                <small className="text-custom-blue-new font-bold">
                  {task.id === 1 ? "2 Unread" : "Read"}
                </small>
              </div>

              <small className="text-gray-400">{task.message}</small>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
