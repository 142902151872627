import { SubmitHandler, useForm } from "react-hook-form";
import InputWithLabel from "../InputWithLabel";
import { useAppContext } from "../../context/AppContext";
import {
  add_security_questions,
  get_security_questions,
  update_security_questions,
} from "../../constants/apis";
import { toast } from "react-toastify";
import SpinnerIcon from "../Icons/SpinnerIcon";
import { useFetch } from "../../hooks/useFetch";

type SecurityQuestionsType = {
  question_one: string;
  answer_one: string;
  question_two: string;
  answer_two: string;
};

export default function SecurityQuestionsSection() {
  const { jwtToken } = useAppContext();

  const {
    data: securityQuestionsData,
    loading,
    refetch,
  } = useFetch<SecurityQuestionsType & { user_security_id: number }>(
    get_security_questions,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  // console.log(securityQuestionsData);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SecurityQuestionsType>({
    values: securityQuestionsData || {
      question_one: "",
      answer_one: "",
      question_two: "",
      answer_two: "",
    },
  });

  const onSubmitHandler: SubmitHandler<SecurityQuestionsType> = async (
    value
  ) => {
    console.log(value);

    const url = securityQuestionsData?.user_security_id
      ? update_security_questions
      : add_security_questions;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await res.json();

      console.log(data);

      if (data.status === "success") {
        toast.success(data?.message);
        if (securityQuestionsData?.user_security_id) {
          refetch();
        }
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <h2 className="font-medium text-xl mb-5">Security Questions</h2>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-2">
        <div className="flex items-center gap-4">
          <InputWithLabel
            label="New Password Security Question 1:"
            type="text"
            id="question_one"
            placeholder="Enter Here"
            {...register("question_one", {
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Field must be at least 2 characters",
              },
              setValueAs: (value) => value.trim(),
            })}
            error={errors.question_one?.message}
          />

          <InputWithLabel
            label="Answer"
            type="text"
            id="answer_one"
            placeholder="Enter Here"
            {...register("answer_one", {
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Field must be at least 2 characters",
              },
              setValueAs: (value) => value.trim(),
            })}
            error={errors.answer_one?.message}
          />
        </div>

        <div className="flex items-center gap-4">
          <InputWithLabel
            label="New Password Security Question 2:"
            type="text"
            id="question_two"
            placeholder="Enter Here"
            {...register("question_two", {
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Field must be at least 2 characters",
              },
              setValueAs: (value) => value.trim(),
            })}
            error={errors.question_two?.message}
          />

          <InputWithLabel
            label="Answer"
            type="text"
            id="answer_two"
            placeholder="Enter Here"
            {...register("answer_two", {
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Field must be at least 2 characters",
              },
              setValueAs: (value) => value.trim(),
            })}
            error={errors.answer_two?.message}
          />
        </div>

        <div>
          <button
            type="submit"
            className="h-[44px] px-4 mt-2 rounded-md bg-custom-blue-medium text-white font-medium"
          >
            {isSubmitting ? (
              <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </section>
  );
}
