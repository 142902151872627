import { Link } from "react-router-dom";

import KarurFabrics from "../../assets/img/Karur-Fabrics-logo.png";
import SharonImpex from "../../assets/img/Sharon-Impex-logo.png";
import SriPriyaAutoExports from "../../assets/img/Sri-Priya-Auto-Exports-logo.png";
import RedChamber from "../../assets/img/Red-Chamber-logo.png";

import CardSkeleton from "../CardSkeleton";
import { formattedDate } from "../../utils";

import { useSimplePaymentDataContext } from "../../context/SimplePaymentDataContext";

export const history = [
  {
    id: 1,
    name: "Karur Fabrics",
    logo: KarurFabrics,
    code: "IN44589",
    date: "06-08-24",
    cost: "800,000 USD",
  },
  {
    id: 2,
    name: "Sharon Impex",
    logo: SharonImpex,
    code: "CZ57890",
    date: "06-08-24",
    cost: "500,000 USD",
  },
  {
    id: 3,
    name: "Sri Priya Auto Exports",
    logo: SriPriyaAutoExports,
    code: "JP99880",
    date: "04-02-24",
    cost: "500,000 USD",
  },
  {
    id: 4,
    name: "Red Chamber",
    logo: RedChamber,
    code: "US25761",
    date: "12-01-24",
    cost: "2,500,000 USD",
  },
];

export default function History() {
  const { data, loading } = useSimplePaymentDataContext();

  const completePaymentData = data?.filter(
    (el) => el.simple_payment.save_as_draft === 0
  );

  return (
    <section className="card h-[374px] col-span-1">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-[#030303] font-semibold text-xl">History</h2>

        <div>
          <Link to={""} className="text-custom-blue-new font-semibold">
            View all
          </Link>
        </div>
      </div>

      <div>
        {loading && (
          <div className="">
            {[1, 2, 3].map((el) => (
              <CardSkeleton key={el} />
            ))}
          </div>
        )}
      </div>

      <div className="h-[300px] overflow-y-scroll custom-scrollbar">
        {data !== null
          ? completePaymentData?.map(
              ({ simple_payment, beneficiary_details }, index) => (
                <div
                  key={simple_payment.simple_payment_id}
                  className={`flex items-center justify-between py-3 ${
                    index + 1 !== data.length && "border-b"
                  }`}
                >
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {/* <img src={el.logo} alt="logo" /> */}
                        {beneficiary_details?.company_name.at(0)?.toUpperCase()}
                      </div>

                      <div>
                        <p className="text-sm font-medium">
                          {beneficiary_details?.company_name}
                        </p>
                        <small className="text-gray-400">
                          {beneficiary_details?.company_registration_number}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div>
                    <small className="text-gray-400">
                      {formattedDate(simple_payment?.created_at)}
                    </small>
                  </div>
                </div>
              )
            )
          : !loading && (
              <p className="text-center text-gray-500">No records found</p>
            )}
      </div>
    </section>
  );
}
