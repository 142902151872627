import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

import "./index_1.css";

import { AppProvider } from "./context/AppContext.tsx";
import { IFBuyerDataContextProvider } from "./context/IFBuyerDataContext.tsx";
import { PartnersDatContextProvider } from "./context/PartnersDataContext.tsx";
import { SimplePaymentDataContextProvider } from "./context/SimplePaymentDataContext.tsx";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AppProvider>
      <PartnersDatContextProvider>
        <SimplePaymentDataContextProvider>
          <IFBuyerDataContextProvider>
            <App />
          </IFBuyerDataContextProvider>
        </SimplePaymentDataContextProvider>
      </PartnersDatContextProvider>
    </AppProvider>
  </StrictMode>
);
