import { matchPath, Outlet, useLocation } from "react-router-dom";
import QualityAssuranceForm from "../components/QualityAssurance/QualityAssuranceForm";


export const QualityAssuranceLayout = () => {

  const location = useLocation();

  const routes = [
    {
      id: 1,
      route: "/quality-assurance",
      name: "Quality Assurance",
    },
    { id: 2, route: "/quality-assurance/summary", name: "Summary" },
  ];

  const isActiveRoute = (route: string) => {
    return matchPath(location.pathname, route) !== null;
  };

  return (
    <div>
      <div className="bg-white rounded-md p-8 border border-[#f3f3f5]">
        {/* <div className=" flex">
          <nav className="p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
            {routes.map((el) => (
              <div key={el.route}>
                <div
                  className={`w-[190px] py-2 transition-colors duration-700 ${
                    isActiveRoute(el.route)
                      ? "text-[#0348B7]"
                      : "text-[#475467]"
                  }`}
                >
                  <p className="flex items-center gap-1">
                    <span
                      className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                        isActiveRoute(el.route)
                          ? "text-[#0348B7] border-[#0348B7]"
                          : "border-[#D0D5DD]"
                      }`}
                    >
                      0{el.id}
                    </span>{" "}
                    {el.name}
                  </p>
                </div>
                {el.id !== routes.length && (
                  <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
                )}
              </div>
            ))}
          </nav>

          <div className="relative w-full">
            <Outlet />
          </div>
        </div> */}
        <QualityAssuranceForm/>
      </div>
    </div>
  );
};
