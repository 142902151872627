import { useAppContext } from "../context/AppContext";

import {
  // delete_invoice_buyer_details,
  update_beneficary_details,
} from "../constants/apis";
import { toast } from "react-toastify";
import CrossIcon from "./Icons/CrossIcon";
import InputWithLabel from "./InputWithLabel";
import {
  // FieldArrayWithId,
  SubmitHandler,
  // UseFieldArrayRemove,
  useForm,
  UseFormReset,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
// import { BuyerCompanyFormType } from "../schemas/BuyerCompanyformType";
import { SPAddBeneficiaryCompanyFormSchema } from "../schemas/SPSenderBeneficiaryType";

// import { useIFBuyerDataContext } from "../context/IFBuyerDataContext";
// import { useState } from "react";
// import SpinnerIcon from "./Icons/SpinnerIcon";
import { z } from "zod";
import { usePartnersDataContext } from "../context/PartnersDataContext";
import { IFDetailsType } from "../schemas/IFDetailsType";
import Tooltip from "./Tooltip";
import { company_address, company_name, company_reg_number, email } from "../constants/tooltipContents";

type AddNewQualitySupplierProps = {
  onClose: () => void;
  selectedCompany: CompanyFormSchemaType | undefined;
  reset: UseFormReset<IFDetailsType>;
};

export const buyerDataField = {
  // invoice_buyer_id: 0,
  company_name: "",
  reg_no: "",
  company_address: "",
  contact_person_name: "",
  phone_number: "",
  email: "",
};

type CompanyFormSchemaType = z.infer<typeof SPAddBeneficiaryCompanyFormSchema>;

const AddBuyerCompany: React.FC<AddNewQualitySupplierProps> = ({
  onClose,
  selectedCompany,
  reset,
}) => {
  const { jwtToken } = useAppContext();

  const { refetch } = usePartnersDataContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CompanyFormSchemaType>({
    resolver: zodResolver(SPAddBeneficiaryCompanyFormSchema),

    values: selectedCompany,
  });

  const onSubmitHandler: SubmitHandler<CompanyFormSchemaType> = async (
    values
  ) => {
    // console.log(values);

    try {
      const response = await fetch(update_beneficary_details, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();

      if (data.status === "success") {
        toast.success(data?.message);

        refetch();

        reset({
          company_name: "",
          company_address: "",
          company_registration_number: "",
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-[50vw] p-8">
      <div className="flex justify-between mb-5">
        <h1 className="text-xl font-bold">Company Details</h1>
        <button type="button" onClick={onClose} className="hover:scale-90">
          <CrossIcon className="size-8" />
        </button>
      </div>

      <form className="space-y-3" onSubmit={handleSubmit(onSubmitHandler)}>
        {/* {fields.map((field, index) => (
          <div
            key={field.id}
            className="space-y-3 border-b border-[#EAECF0] pb-4"
          >
            <h2 className="font-medium text-gray-800">Company {index + 1}</h2>

            <div className="flex items-center gap-4">
              <InputWithLabel
                label={"Company Name *"}
                id={"company_name"}
                type="text"
                placeholder="Enter Here"
                error={
                  errors.invoice_buyer_details?.[index]?.company_name?.message
                }
                {...register(`invoice_buyer_details.${index}.company_name`)}
              />
              <InputWithLabel
                label={"Company Address *"}
                id={"company_address"}
                type="text"
                placeholder="Enter Here"
                error={
                  errors.invoice_buyer_details?.[index]?.company_address
                    ?.message
                }
                {...register(`invoice_buyer_details.${index}.company_address`)}
              />
            </div>
            <div className="flex items-center gap-4">
              <InputWithLabel
                label={"Company Registration Number *"}
                id={"reg_no"}
                type="text"
                placeholder="Enter Here"
                error={errors.invoice_buyer_details?.[index]?.reg_no?.message}
                {...register(`invoice_buyer_details.${index}.reg_no`)}
              />
              <InputWithLabel
                label={"Contact Person Name *"}
                id={"contact_person_name"}
                type="text"
                placeholder="Enter Here"
                error={
                  errors.invoice_buyer_details?.[index]?.contact_person_name
                    ?.message
                }
                {...register(
                  `invoice_buyer_details.${index}.contact_person_name`
                )}
              />
            </div>
            <div className="flex items-center gap-4">
              <InputWithLabel
                label={"Email Address *"}
                id={"email"}
                type="text"
                placeholder="Enter Here"
                error={errors.invoice_buyer_details?.[index]?.email?.message}
                {...register(`invoice_buyer_details.${index}.email`)}
              />
              <InputWithLabel
                label={"Phone Number *"}
                id={"phone_number"}
                type="text"
                placeholder="Enter Here"
                error={
                  errors.invoice_buyer_details?.[index]?.phone_number?.message
                }
                {...register(`invoice_buyer_details.${index}.phone_number`)}
              />
            </div>

            <div className="flex justify-between items-center pt-3">
              {index !== 0 && <RemoveBuyerBtn {...{ field, remove, index }} />}

              {index + 1 === fields.length ? (
                <button
                  type="button"
                  className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
                  onClick={() => append(buyerDataField)}
                >
                  Add a New Buyer
                </button>
              ) : null}
            </div>
          </div>
        ))} */}

        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Company Name *"}
            type="text"
            element={<Tooltip content={company_name} />}
            error={errors.company_name?.message}
            {...register("company_name")}
          />
          <InputWithLabel
            label={"Company Address *"}
            element={<Tooltip content={company_address} />}
            type="text"
            error={errors.company_address?.message}
            {...register("company_address")}
          />
        </div>
        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Company Registration Number *"}
            element={<Tooltip content={company_reg_number}/>}

            type="text"
            error={errors.company_registration_number?.message}
            {...register("company_registration_number")}
          />
          <InputWithLabel
            label={"Contact Person Name *"}
            type="text"
            error={errors.contact_person_name?.message}
            {...register("contact_person_name")}
          />
        </div>
        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Email Address*"}
            type="text"
            error={errors.email?.message}
            element={<Tooltip content={email}/>}

            {...register("email")}
          />
          <InputWithLabel
            label={"Phone Number *"}
            type="text"
            error={errors.phone_number?.message}
            {...register("phone_number")}
          />
        </div>

        <div className="flex justify-end gap-4 pt-6">
          <button
            type="button"
            onClick={onClose}
            className="w-[87px] h-[44px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          >
            Cancel
          </button>

          <button
            type="submit"
            className="w-[100px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
          >
            {isSubmitting ? "Saving.." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBuyerCompany;

// function RemoveBuyerBtn({
//   field,
//   remove,
//   index,
// }: {
//   field: FieldArrayWithId<BuyerCompanyFormType, "invoice_buyer_details", "id">;
//   remove: UseFieldArrayRemove;
//   index: number;
// }) {
//   const [btnLoading, setBtnLoading] = useState(false);

//   const { jwtToken } = useAppContext();
//   const { refetch } = useIFBuyerDataContext();

//   const removeBuyer = async (
//     field: FieldArrayWithId<
//       BuyerCompanyFormType,
//       "invoice_buyer_details",
//       "id"
//     >,

//     index: number
//   ) => {
//     if (!field.invoice_buyer_id) {
//       remove(index);
//       return;
//     }

//     setBtnLoading(true);

//     try {
//       const res = await fetch(delete_invoice_buyer_details, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${jwtToken}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ invoice_buyer_id: field.invoice_buyer_id }),
//       });
//       const data = await res.json();

//       if (data.status === "success") {
//         toast.success(data?.message || data?.msg);

//         refetch();
//       } else {
//         toast.info("There is no change in data.");
//       }
//     } catch (error) {
//       toast.error("Something went wrong!");
//     } finally {
//       setBtnLoading(false);
//     }
//   };

//   return (
//     <button
//       className="text-red-600 mt-2"
//       type="button"
//       onClick={() => removeBuyer(field, index)}
//       disabled={btnLoading}
//     >
//       {btnLoading ? (
//         <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-red-600" />
//       ) : (
//         "Remove"
//       )}
//     </button>
//   );
// }
