import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import checkIcon from "../assets/icons/check.png";

import {
  get_all_insurance_data,
  get_insurance_details,
  get_insured_details,
} from "../constants/apis";
import Spinner from "../components/Spinner";

import { IFDetailsResponseDataType } from "./IFDetails";
import { useEffect, useState } from "react";
import { AltertBox } from "../components/AltertBox";

export type InsuranceDataType = {
  id: number;
  policy_holder_name: string;
  insured_name: string;
  insurance_type: string;
  start_date: string;
  end_date: string;
  policy_number: string;
  coverage_amount: number;
};

export const InsuranceSummary = () => {
  const navigate = useNavigate();
  const { jwtToken, user } = useAppContext();
  const { checked } = useAppContext();
  const [isProcessed, setIsProcessed] = useState(false);
  const {
    data: insuranceData,
    loading: insuranceDataLoading,
    refetch,
  } = useFetch<InsuranceDataType[]>(get_insurance_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });
  const { data: insuredData, loading: insuredDataLoading } = useFetch<
    InsuranceDataType[]
  >(get_insured_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    refetch();
  }, []);

//   if (insuranceDataLoading) {
//     return (
//       <div style={{ position: "absolute", left: "0" }}>
//         <Spinner />
//       </div>
//     );
//   }
  console.log(insuranceData);

  if (!insuranceData || insuranceData.length === 0) {
    return <p>No Insurance Data Available.</p>;
  }
  return (
    <section className="px-5">
      <div className="flex justify-between flex-row gap-4">
        <div className="my-5 card !bg-custom-off-white w-full">
          <h3 className="font-semibold mb-5">Policy Holder</h3>
          {/* <div className="grid grid-cols-3 gap-4"> */}
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Company Name</p>
            <p className="text-black text- py-1">{user?.company_name}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">
              Registration Number
            </p>
            <p className="text-black py-1">{user?.company_reg_number}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Address</p>
            <p className="text-black py-1">{user?.company_reg_address}</p>
          </div>
          {/* </div> */}

          {/* <div className="grid grid-cols-3 gap-4"> */}
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Contact Name</p>
            <p className="text-black py-1">
              {user?.first_name}
              {user?.last_name}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Email</p>
            <p className="text-black py-1">{user?.work_email}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Phone number</p>
            <p className="text-black py-1">{user?.company_phone_number}</p>
          </div>
          {/* </div> */}
        </div>

        <div className="my-5 card !bg-custom-off-white w-full">
          <h3 className="font-semibold mb-5">Insured</h3>

          {/* <div className="grid grid-cols-3 gap-4"> */}
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Company Name</p>
            <p className="text-black py-1">{insuredData?.company_name}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">
              Registration Number
            </p>
            <p className="text-black py-1">{insuredData?.company_id}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Address</p>
            <p className="text-black py-1">{insuredData?.company_address}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Contact Name</p>
            <p className="text-black py-1">{insuredData?.company_name}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Email</p>
            <p className="text-black py-1">{insuredData?.email}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Phone Number</p>
            <p className="text-black py-1">{insuredData?.phone_number}</p>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="my-1 card !bg-custom-off-white w-full">
          <h3 className="font-semibold mb-5">Insurance Details</h3>

          {/* <div className="grid grid-cols-3 gap-4"> */}
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Category Of Goods</p>
            <p className="text-black py-1">{insuranceData?.category_of_goods}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">
              Description of Goods
            </p>
            <p className="text-black py-1">{insuranceData?.description_of_goods}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Volume of Goods</p>
            <p className="text-black py-1">{insuranceData?.volume_of_goods}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Invoice Amount</p>
            <p className="text-black py-1">{insuranceData?.invoice_amount}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Place Of Origin</p>
            <p className="text-black py-1">{insuranceData?.place_of_origin}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Place of Destination</p>
            <p className="text-black py-1">{insuranceData?.place_of_destination}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Type of Insurance Cover</p>
            <p className="text-black py-1">{insuranceData?.type_of_insurance_cover}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Valid From</p>
            <p className="text-black py-1">{insuranceData?.valid_from}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-neutral-600">Valid To</p>
            <p className="text-black py-1">{insuranceData?.valid_to}</p>
          </div>
          {/* </div> */}
        </div>
      <div className="mt-4 py-6 flex justify-between border-t border-[#EAECF0]">
        <button
          type="button"
          className="h-[44px] px-4 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          onClick={() => navigate(-1)}
        >
          <span>Back</span>
        </button>

        <button
          type="submit"
          className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
          onClick={() => setIsProcessed(true)}
        >
          Approve
        </button>
      </div>
      {isProcessed && (
        <AltertBox
          iconSource={checkIcon}
          alertText="Successfully Submitted"
          alertText1="Quotes will be processed within 2 business days and result will appear in dashboard."
          alertText2="Thank you for your patience."
          buttonTitle="Go to Dashboard"
          handleClick={() => navigate("/dashboard")}
        />
      )}
    </section>
  );
};
