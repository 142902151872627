import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { delete_founder } from "../../constants/apis";
import { toast } from "react-toastify";
import { useState } from "react";
import SpinnerIcon from "../Icons/SpinnerIcon";

export default function AuthorizedPersons() {
  const { user } = useAppContext();
  const navigate = useNavigate();

  return (
    <section className="flex gap-8">
      <div className="w-full p-2 space-y-8">
        <h2 className="font-medium text-xl pl-1">Authorized Persons</h2>

        <div className="space-y-4">
          {user?.founders?.map((founder) => (
            <div
              key={founder.fid}
              className="flex items-center justify-between px-1 py-2 border-b-2 border-[#EAECF0]"
            >
              <div className="flex items-center gap-2">
                <div className="w-[46px] h-[46px] rounded-full bg-slate-400 inline-flex justify-center items-center">
                  <p className="font-medium text-xl">
                    {founder.first_name.at(0)?.toUpperCase()}
                  </p>
                </div>
                <div>
                  <p className="text-[16px] font-medium">
                    {founder.first_name} {founder.middle_name}{" "}
                    {founder.last_name}
                  </p>
                  <small className="text-custom-blue-medium">
                    {founder.email}
                  </small>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <button
                  className="text-custom-blue-medium font-medium"
                  onClick={() => navigate("/registration")}
                >
                  Edit
                </button>

                <RemoveFounderBtn fid={founder.fid!} />
              </div>
            </div>
          ))}
        </div>

        <div>
          {(user?.founders?.length! === 0 || user?.founders === null) && (
            <p className="text-gray-600">There is no Authorized Persons</p>
          )}
        </div>

        <div>
          <button
            className="h-[44px] px-2 rounded-md bg-custom-blue-medium text-white font-medium"
            onClick={() => navigate("/registration")}
          >
            Add Authorized Persons
          </button>
        </div>
      </div>

      <div className="w-full p-2 space-y-8">
        <h2 className="font-medium text-xl mb-8">Booking Persons</h2>

        <div>
          <p className="text-gray-600">There is no Booking Persons</p>
        </div>

        <div>
          <button className="h-[44px] px-2 rounded-md bg-custom-blue-medium text-white font-medium">
            Add Booking Persons
          </button>
        </div>
      </div>
    </section>
  );
}

function RemoveFounderBtn({ fid }: { fid: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchUser, jwtToken } = useAppContext();

  const deleteFounder = async (id: number) => {
    setIsLoading(true);

    try {
      const res = await fetch(delete_founder, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fid: id,
        }),
      });

      const data = await res.json();

      if (data?.status === "success") {
        refetchUser();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className="text-red-600 font-medium"
      onClick={async () => await deleteFounder(fid)}
      disabled={isLoading}
    >
      {isLoading ? (
        <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
      ) : (
        "Remove"
      )}
    </button>
  );
}
