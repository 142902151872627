import { forwardRef, HTMLProps, ReactNode, Ref, useState } from "react";
import EyeSlashIcon from "./Icons/EyeSlashIcon";
import EyeIcon from "./Icons/EyeIcon";

interface InputWithLabelProps extends HTMLProps<HTMLInputElement> {
  label: string;
  error?: string;
  element?: ReactNode;
}

const InputWithLabel = forwardRef(
  (props: InputWithLabelProps, ref: Ref<HTMLInputElement>) => {
    const { label, style, id, type, error, element, className, ...inputProps } =
      props;

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowpassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <div className="w-full relative">
        <div className="flex justify-between items-center">
          <label
            htmlFor={id}
            className="text-[#344054] text-sm font-medium"
            style={style}
          >
            {label}
          </label>

          {element}
        </div>

        <input
          type={showPassword ? "text" : type}
          className={`input-new ${className}`}
          id={id}
          {...inputProps}
          ref={ref}
        />

        {type === "password" && (
          <div className="absolute right-2 top-8 text-[#344054]">
            {showPassword ? (
              <button type="button" onClick={toggleShowpassword}>
                <EyeIcon className="size-5" />
              </button>
            ) : (
              <button type="button" onClick={toggleShowpassword}>
                <EyeSlashIcon className="size-5" />
              </button>
            )}
          </div>
        )}

        <small className="text-red-500 h-[10px] block ml-1">
          {error && error}
        </small>
      </div>
    );
  }
);

export default InputWithLabel;
