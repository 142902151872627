import { z } from "zod";

export const SPDetailsFormSchema = z.object({
  amount_sent: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z.number({ message: "Expect a number" }).min(1, "Enter a valid amount")
  ),
  amount_received: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z.number({ message: "Expect a number" }).min(1, "Enter a valid amount")
  ),
  fees: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z.number({ message: "Expect a number" }).min(1)
  ),
  estimated_time: z.string().date(),
  reference: z.string().min(1, {
    message: "Reference is required",
  }),
  payment_mode: z.string().min(1, {
    message: "Payment mode is required",
  }),
  reason: z.string().min(1, {
    message: "Reason is required",
  }),
  amount_sent_currency: z
    .string()
    // .min(1, {
    //   message: "Currency is required",
    // })
    // .default("USD")
    .optional(),
  amount_received_currency: z
    .string()
    // .min(1, {
    //   message: "Currency is required",
    // })
    // .default("USD")
    .optional(),

  paid_from: z.string().min(1, {
    message: "Currency is required",
  }),
});

export type SPDetailsFormType = z.infer<typeof SPDetailsFormSchema>;
