interface SwitchProps {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  ariaLabel?: string;
  className?: string;
  checkPayment: boolean;
  paymentOne: string;
  paymentTwo: string;
}
const Switch: React.FC<SwitchProps> = ({
  checked,
  checkPayment,
  paymentOne,
  paymentTwo,
  onToggle,
  ariaLabel,
  className,
}) => {
  const handleChange = () => {
    onToggle(!checked);
  };

  return (
    <div className="flex items-center gap-2">
      <p className={`${!checked ? "font-bold" : "font-normal"}`}>
        {paymentOne}
      </p>
      <div
        className="w-[45px] h-[27px] bg-custom-blue rounded-2xl inline-flex items-center p-1 cursor-pointer"
        role="switch"
        aria-checked={checked}
        aria-label={ariaLabel}
        onClick={handleChange}
      >
        <div
          className={`w-5 h-5 bg-white rounded-full shadow-lg transition-transform duration-300 ${
            checked ? "translate-x-4" : "translate-x-0"
          }`}
        />
      </div>

      <p className={`${checked ? "font-bold" : "font-normal"}`}>{paymentTwo}</p>
    </div>
  );
};

export default Switch;
