import { useFormContext } from "react-hook-form";

import InputWithLabel from "../InputWithLabel";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";

import { today } from "../../utils";

// import CrossIcon from "./Icons/CrossIcon";
// import UploadIcon from "./Icons/UploadIcon";

import CustomDropdown from "./CustomDropdown";
import { businessDetails, businessRegion } from "../../constants/variable";
import BusinessDocumentsFields from "./BusinessDocumentsFields";
import Tooltip from "../Tooltip";
import { company_name, company_reg_number, description, registration_date, trade_name } from "../../constants/tooltipContents";

export default function BusinessDetailsFields() {
  const {
    register,
    formState: { errors },
  } = useFormContext<FullRegistrationFormType>();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-6">
        <InputWithLabel
          label={"Business Name"}
          id={"companyName"}
          {...register("company_name")}
          element={<Tooltip content={company_name}/>}
          error={errors.company_name?.message}
        />
        <InputWithLabel
          label={"Business Registration Number"}
          id={"companyRegNumber"}
           element={<Tooltip content={company_reg_number} />}
          {...register("company_reg_number")}
          error={errors.company_reg_number?.message}
        />
        <InputWithLabel
          label={"Trade Name"}
          element={<Tooltip content={trade_name} />}
          id={"tradeName"}
          {...register("trade_name")}
          error={errors.trade_name?.message}
        />
      </div>
      <div className="flex items-center gap-6">
        <InputWithLabel
          label={"Website"}
          id={"website"}
          {...register("website")}
          error={errors.website?.message}
        />

        {/* <BusinessTypeDropdown /> */}
        <CustomDropdown
          label="Business Type"
          placeholder="Select business type"
          name="business_type"
          category={businessDetails.BUSINESS_TYPE}
          region={businessRegion}
          error={errors.business_type?.message}
        />

        <InputWithLabel
          label={"Description"}
          id={"companyDescription"}
          element={<Tooltip content={description}/>}

          {...register("company_description")}
          error={errors.company_description?.message}
        />
      </div>
      <div className="flex items-center gap-6">
        <InputWithLabel
          label={"Registered Date"}
          element={<Tooltip content={registration_date}/>}

          type="date"
          id={"companyRegDate"}
          {...register("company_reg_date")}
          error={errors.company_reg_date?.message}
          max={today}
        />

        <CustomDropdown
          label="Registered Country"
          placeholder="Select reg. country"
          name="company_reg_country"
          category={businessDetails.COUNTRY_NAME}
          region={businessRegion}
          error={errors.company_reg_country?.message}
        />

        <CustomDropdown
          label="Listed Exchange"
          placeholder="Select listed exchange"
          name="company_listed_exchange"
          category={businessDetails.LISTED_EXCHANGE}
          region={businessRegion}
          error={errors.company_listed_exchange?.message}
        />
      </div>
      {/* <div className="flex items-center gap-6">
        <InputWithLabel
          label={"Phone Number"}
          id={"phoneNumber"}
          {...register("company_phone_number")}
          error={errors.company_phone_number?.message}
        />
        <InputWithLabel
          label={"Registered Address"}
          id={"companyRegAddress"}
          {...register("company_reg_address")}
          error={errors.company_reg_address?.message}
        />
        <InputWithLabel
          label={"Business Address"}
          id={"businessAddress"}
          {...register("business_address")}
          error={errors.business_address?.message}
        />
      </div> */}
      <div className="flex items-center gap-6">
        <CustomDropdown
          label="Total Employess"
          placeholder="Select total employess"
          name="totalEmployees"
          category={businessDetails.TOTAL_EMPLOYEES}
          region={businessRegion}
          error={errors.totalEmployees?.message}
        />

        <CustomDropdown
          label="Industry Sector"
          placeholder="Select industry sector"
          name="industrySector"
          category={businessDetails.INDUSTRY_SECTOR}
          region={businessRegion}
          error={errors.industrySector?.message}
        />
        <CustomDropdown
          label="Intended Use of Account"
          placeholder="Select intended use of account"
          name="intendedUseOfAccount"
          category={businessDetails.INTENDED_USE_OF_ACCOUNT}
          region={businessRegion}
          error={errors.intendedUseOfAccount?.message}
        />
      </div>

      <div className="w-[270px]">
        <CustomDropdown
          label="Company Annual Turnover"
          placeholder="Select company annual turnover"
          name="annualTurnover"
          category={businessDetails.ANNUAL_TURNOVER}
          region={businessRegion}
          error={errors.annualTurnover?.message}
        />
      </div>

      <CompanyRegisteredAddressFields />
      {/* <CompanyBusinessAddressFields /> */}

      {/* DOCFIELDS Section */}
      <BusinessDocumentsFields />
    </div>
  );
}

function CompanyRegisteredAddressFields() {
  const {
    register,
    formState: { errors },
  } = useFormContext<FullRegistrationFormType>();

  return (
    <div className="space-y-2">
      <h3 className="text-[#344054] font-medium pt-4 border-t">
        Registered Address
      </h3>

      {/* <div className="flex items-center gap-6"> */}
      <InputWithLabel
        label={"Address Line 1"}
        placeholder="Enter here"
        {...register("registered_addressLine1")}
        error={errors.registered_addressLine1?.message}
      />
      <InputWithLabel
        label={"Address Line 2 (optional)"}
        placeholder="Enter here"
        {...register("registered_addressLine2")}
        error={errors.registered_addressLine2?.message}
      />
      {/* </div> */}

      <div className="flex items-center gap-6">
        <InputWithLabel
          label={"City"}
          placeholder="Enter here"
          {...register("registered_city")}
          error={errors.registered_city?.message}
        />
        <InputWithLabel
          label={"State"}
          placeholder="Enter here"
          {...register("registered_state")}
          error={errors.registered_state?.message}
        />
      </div>

      <div className="flex items-center gap-6">
        <CustomDropdown
          label="Country"
          placeholder="Select reg. country"
          name="registered_country"
          category={businessDetails.COUNTRY_NAME}
          region={businessRegion}
          error={errors.registered_country?.message}
        />

        <InputWithLabel
          label={"Post Code"}
          placeholder="Enter here"
          {...register("registered_postcode")}
          error={errors.registered_postcode?.message}
        />
      </div>
    </div>
  );
}
