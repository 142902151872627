import React from 'react'
interface DividerProps{
    dividerHeight:string;
    dividerWidth:string;
    dividerBackgroundColor:string;
}

export const Divider:React.FC<DividerProps> = ({dividerHeight,dividerWidth,dividerBackgroundColor}) => {
  return (
    <div style={{height:dividerHeight,width:dividerWidth,backgroundColor:dividerBackgroundColor,marginInline:'auto',marginBlock:'10px'}}>

    </div>
  )
}
