import { useState } from "react";

import { useAppContext } from "../context/AppContext";
import {
  get_insurance_details,
  get_insured_details,
  get_invoice_factoring_details,
  get_logistics_summary,
  get_quality_assurance,
  get_simple_payment,
  get_smart_escrow_details,
} from "../constants/apis";
import { AllSmartEscrowData } from "./SESummary";
import { SPSummaryDataType } from "./SPSummary";
import { IFSummary } from "./IFSummary";
import { LSummary, Quote } from "./LSummary";
import LogisticsIcon from "../components/Icons/LogisticsIcon";
import { InvoiceShowData } from "../components/InvoiceShowData";
import { QAShowData } from "../components/QAShowData";
import { InsuranceShowData } from "../components/InsuranceShowData";
import { SPDataShow } from "../components/SPDataShow";
import { SEShowData } from "../components/SEShowData";

import { useFetch } from "../hooks/useFetch";
import { IFDetailsResponseDataType } from "./IFDetails";
import { QualityAssuranceType } from "../schemas/QuallityAssuranceType";
import { BuyerCompanyFormType } from "../schemas/BuyerCompanyFormType";
import { PolicyHolderType } from "../schemas/PolicyHolder";
import { InsuranceFormType } from "../schemas/InsuranceDetailsType";
import { getDaysBetweenDates, getItemFromLocalStore } from "../utils";
import { TWO_PERCENT } from "../constants/variable";

const SummaryCard = ({
  data,
  companyName,
  from,
  to,
  amount,
  fees,
  children,
  bannerTitle,
  ETA,
  term,
  value,
  loading,
}: {
  data: any;
  companyName: string;
  from?: string;
  to?: string;
  amount?: string | number;
  fees: number;
  children: React.ReactNode;
  bannerTitle: string;
  ETA?: string;
  term?: string;
  value?: string | number;
  loading: boolean;
}) => {
  const [toggleSummary, setToggleSummary] = useState(false);

  if (loading) {
    return (
      <div>
        <p className="text-custom-blue text-lg mt-4 mb-2 font-bold">
          {bannerTitle}
        </p>

        <div className="bg-white px-4 py-5 rounded-lg shadow-lg">
          <p>loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="text-custom-blue text-lg mt-4 mb-2 font-bold">
        {bannerTitle}
      </p>

      <div className="bg-white px-4 py-5 rounded-lg shadow-lg">
        {data ? (
          <div>
            <div className="h-[40px] flex justify-between items-center">
              <div className="flex justify-between items-center gap-4">
                <p className="text-custom-blue">{companyName}</p>

                {value && (
                  <p className="line-clamp-2">
                    <span className="text-gray-600">VALUE:</span> ${value}
                  </p>
                )}

                {from && (
                  <p className="w-[200px] line-clamp-2">
                    <span className="text-gray-600">FROM:</span> {from}
                  </p>
                )}
                {to && (
                  <p className="w-[200px] line-clamp-2">
                    <span className="text-gray-600">TO:</span> {to}
                  </p>
                )}
                {amount && (
                  <p>
                    <span className="text-gray-600">AMOUNT:</span> ${amount}
                  </p>
                )}
                {ETA && (
                  <p>
                    <span className="text-gray-600">ETA:</span> {ETA}
                  </p>
                )}

                {term && (
                  <p>
                    <span className="text-gray-600">TERM:</span> {term}
                  </p>
                )}

                <p>
                  <span className="text-gray-600">FEES:</span> ${fees}
                </p>
              </div>

              <div className="flex flex-col justify-center items-center">
                <button
                  type="submit"
                  className="w-[150px] h-[40px] px-1 rounded-md bg-green-500 text-white"
                >
                  EXECUTE
                </button>

                <button
                  className="text-right text-custom-blue hover:underline"
                  onClick={() => setToggleSummary((prev) => !prev)}
                >
                  {toggleSummary ? "Hide Details" : "Show Details"}
                </button>
              </div>
            </div>
            {toggleSummary && <div>{children}</div>}
          </div>
        ) : (
          <div className="flex justify-end items-center">
            <button className="w-[150px] h-[40px] px-1 rounded-md bg-custom-blue text-white">
              Add Service
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const DashboardQuotationSelection = () => {
  // const { jwtToken } = useAppContext();

  return (
    <section className="bg-custom-off-white py-4 px-20">
      <h1 className="text-custom-blue text-center text-2xl font-bold">
        Transaction Summary
      </h1>

      <SPTSummaryCard />

      <SETSummaryCard />

      <IFTSummaryCard />
      <LogisticTSummaryCard />

      <QATSummaryCard />

      <InsuranceTSummaryCard />

      <div className="text-center">
        <button
          type="submit"
          className="w-[170px] py-4 px-1 my-8 rounded-md bg-custom-blue text-white"
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default DashboardQuotationSelection;

function SPTSummaryCard() {
  const { jwtToken, user } = useAppContext();

  const {
    data: simplePaymentData,
    loading,
    error,
  } = useFetch<SPSummaryDataType>(get_simple_payment, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  return (
    <SummaryCard
      bannerTitle="Payment Service"
      loading={loading}
      data={simplePaymentData}
      companyName={"Taram"}
      from={user?.company_name as string}
      to={simplePaymentData?.beneficiary_details?.company_name as string}
      amount={simplePaymentData?.simple_payment?.amount_sent}
      fees={simplePaymentData?.simple_payment?.fees!}
    >
      <SPDataShow data={simplePaymentData} userData={user} />
    </SummaryCard>
  );
}

function SETSummaryCard() {
  const { jwtToken, user } = useAppContext();

  const { data: smartEscrowData, loading } = useFetch<AllSmartEscrowData>(
    get_smart_escrow_details,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <SummaryCard
      loading={loading}
      data={smartEscrowData}
      bannerTitle="Smart Escrow"
      companyName={"Taram"}
      from={user?.company_name as string}
      to={smartEscrowData?.beneficiary_details?.company_name as string}
      amount={smartEscrowData?.smart_escrow?.amount_sent}
      fees={smartEscrowData?.smart_escrow?.fees!}
    >
      <SEShowData data={smartEscrowData} senderData={user} />
    </SummaryCard>
  );
}

function IFTSummaryCard() {
  const { jwtToken } = useAppContext();

  const {
    data: invoiceFactoringData,
    loading: invoiceDataLoading,
    error: invoiceDataError,
  } = useFetch<IFDetailsResponseDataType>(get_invoice_factoring_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const fees =
    TWO_PERCENT *
    parseFloat(
      invoiceFactoringData?.invoice_factoring_data?.credit_limit_approved!
    );

  return (
    <SummaryCard
      loading={invoiceDataLoading}
      bannerTitle="Invoice Factoring"
      data={invoiceFactoringData}
      companyName={"Taram"}
      from={invoiceFactoringData?.invoice_supplier_data?.company_name as string}
      to={invoiceFactoringData?.invoice_factoring_data?.debtor_name as string}
      amount={
        invoiceFactoringData?.invoice_factoring_data.credit_limit_approved
      }
      fees={fees}
    >
      <InvoiceShowData data={invoiceFactoringData} />
    </SummaryCard>
  );
}

function QATSummaryCard() {
  const { jwtToken } = useAppContext();

  const { data: qualityAssuranceData, loading } = useFetch<{
    quality_assurance_data: QualityAssuranceType & {
      quality_assurance_id: number;
    };
    quality_supplier_data: BuyerCompanyFormType;
  }>(get_quality_assurance, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  return (
    <SummaryCard
      loading={loading}
      data={qualityAssuranceData}
      bannerTitle="Quality Assurance"
      companyName={"Taram"}
      from={qualityAssuranceData?.quality_supplier_data?.company_name as string}
      // to={userData?.company_name as string}
      ETA="5 days"
      fees={11}
    >
      <QAShowData data={qualityAssuranceData} />
    </SummaryCard>
  );
}

function InsuranceTSummaryCard() {
  const { jwtToken } = useAppContext();

  const {
    data: simplePaymentData,
    loading,
    error,
  } = useFetch<SPSummaryDataType>(get_simple_payment, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const { data: insuredData, loading: insuredLoading } = useFetch<
    PolicyHolderType & { insured_details_id: number | string }
  >(get_insured_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const { data: insuranceData, loading: insuranceLoading } =
    useFetch<InsuranceFormType>(get_insurance_details, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    });

  let term;

  if (insuranceData) {
    term = getDaysBetweenDates(
      insuranceData?.valid_from as string,
      insuranceData?.valid_to as string
    );
  }

  return (
    <SummaryCard
      loading={loading || insuredLoading || insuranceLoading}
      data={insuranceData}
      bannerTitle="Insurance"
      companyName={"Taram"}
      value={simplePaymentData?.simple_payment.amount_sent}
      fees={simplePaymentData?.simple_payment.fees!}
      term={`${term} days`}
    >
      <InsuranceShowData
        insuranceData={insuranceData}
        insuredData={insuredData}
      />
    </SummaryCard>
  );
}

function LogisticTSummaryCard() {
  // const [quote] = useState<Quote>(getItemFromLocalStore("quote-details"));

  const { jwtToken } = useAppContext();

  const {
    data: quote,
    loading,
    error,
  } = useFetch<{ freights: Quote[] }>(get_logistics_summary, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  // console.log(quote);

  return (
    <SummaryCard
      loading={loading}
      bannerTitle="Logistics"
      data={quote}
      companyName={"Taram"}
      from={quote?.freights.at(0)?.from_city}
      to={quote?.freights.at(0)?.to_city}
      ETA={quote?.freights.at(0)?.days}
      fees={quote?.freights.at(0)?.cost as number}
    >
      <div className="my-2 pl-10 leading-8">
        {/* {JSON.stringify(quote)} */}
        <h3 className="text-custom-blue font-medium">Logistics details</h3>
        <p>Company Name: {quote?.freights.at(0)?.name}</p>
        <p>From: {quote?.freights.at(0)?.from_city}</p>
        <p>To: {quote?.freights.at(0)?.to_city}</p>
        <p>ETA: {quote?.freights.at(0)?.days} days</p>
        <p>Price: ${quote?.freights.at(0)?.cost}</p>

        {/* <p>Rate Breakdown:</p> */}
        {/* <p>From City: {quote?.from_city}</p> */}

        <p>
          Departure Location: {quote?.freights.at(0)?.route_info?.portFrom.name}
          , {quote?.freights.at(0)?.route_info?.portFrom.countryCode}
        </p>

        <p>
          Arrival Location: {quote?.freights.at(0)?.route_info?.portTo.name},{" "}
          {quote?.freights.at(0)?.route_info?.portTo.countryCode}
        </p>

        {/* <p>To City: {quote?.to_city}</p> */}
      </div>
    </SummaryCard>
  );
}
