export default function SuccessIcon({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx={81} cy={81} r={81} fill="#93FF91" />
      <circle cx={81} cy={81} r={58} fill="#3BE637" />
      <path
        d="M101 66L73.5 93.5L61 81"
        stroke="white"
        strokeWidth={9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
