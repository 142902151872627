import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="text-center mt-10 space-y-1">
      <h1 className="text-4xl font-semibold">404 - Page Not Found</h1>
      <p className="text-gray-500">
        The page you're looking for doesn't exist.
      </p>
      <Link to={"/"} className="text-custom-blue underline">
        Go to home
      </Link>
    </div>
  );
}
