const CookieService = {
  setCookie: (name: string, value: string, days: number, path = '/', domain?: string) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=${path};${domain ? `domain=${domain};` : ''}`;
  },

  getCookie: (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim(); // Use trim to remove leading spaces
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  deleteCookie(name: string, path = '/', domain?: string) {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=0;${domain ? ` domain=${domain};` : ''}`;
  }
};

export default CookieService;
