export default function IFIconNew({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M13.5 10.3594C13.5 10.1605 13.421 9.9697 13.2803 9.82905C13.1397 9.68839 12.9489 9.60938 12.75 9.60938H6.75C6.55109 9.60938 6.36032 9.68839 6.21967 9.82905C6.07902 9.9697 6 10.1605 6 10.3594C6 10.5583 6.07902 10.7491 6.21967 10.8897C6.36032 11.0304 6.55109 11.1094 6.75 11.1094H12.75C12.9489 11.1094 13.1397 11.0304 13.2803 10.8897C13.421 10.7491 13.5 10.5583 13.5 10.3594ZM12.5 13.3594C12.5 13.1605 12.421 12.9697 12.2803 12.829C12.1397 12.6884 11.9489 12.6094 11.75 12.6094H6.75C6.55109 12.6094 6.36032 12.6884 6.21967 12.829C6.07902 12.9697 6 13.1605 6 13.3594C6 13.5583 6.07902 13.7491 6.21967 13.8897C6.36032 14.0304 6.55109 14.1094 6.75 14.1094H11.75C11.9489 14.1094 12.1397 14.0304 12.2803 13.8897C12.421 13.7491 12.5 13.5583 12.5 13.3594ZM12.75 15.6094C12.9489 15.6094 13.1397 15.6884 13.2803 15.829C13.421 15.9697 13.5 16.1605 13.5 16.3594C13.5 16.5583 13.421 16.7491 13.2803 16.8897C13.1397 17.0304 12.9489 17.1094 12.75 17.1094H6.75C6.55109 17.1094 6.36032 17.0304 6.21967 16.8897C6.07902 16.7491 6 16.5583 6 16.3594C6 16.1605 6.07902 15.9697 6.21967 15.829C6.36032 15.6884 6.55109 15.6094 6.75 15.6094H12.75Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 22.3592H19C19.7293 22.3592 20.4288 22.0695 20.9445 21.5538C21.4603 21.0381 21.75 20.3386 21.75 19.6092V14.1093C21.75 13.9103 21.671 13.7196 21.5303 13.5789C21.3897 13.4383 21.1989 13.3593 21 13.3593H17.75V5.55225C17.75 4.12925 16.141 3.30125 14.983 4.12825L14.808 4.25325C14.4248 4.52528 13.9663 4.67108 13.4964 4.67036C13.0264 4.66964 12.5684 4.52244 12.186 4.24925C11.5476 3.79492 10.7835 3.55078 10 3.55078C9.21645 3.55078 8.45238 3.79492 7.814 4.24925C7.43162 4.52244 6.97359 4.66964 6.50364 4.67036C6.03369 4.67108 5.57521 4.52528 5.192 4.25325L5.017 4.12825C3.859 3.30125 2.25 4.12825 2.25 5.55225V18.6093C2.25 19.6038 2.64509 20.5576 3.34835 21.2609C4.05161 21.9642 5.00544 22.3592 6 22.3592ZM8.686 5.46925C9.06995 5.1967 9.52915 5.05028 10 5.05028C10.4709 5.05028 10.9301 5.1967 11.314 5.46925C11.9507 5.92437 12.7136 6.16946 13.4962 6.17036C14.2788 6.17126 15.0423 5.92791 15.68 5.47425L15.855 5.34925C15.8923 5.32266 15.9363 5.30685 15.982 5.30355C16.0277 5.30025 16.0735 5.30959 16.1143 5.33055C16.1551 5.3515 16.1893 5.38327 16.2132 5.42237C16.2372 5.46147 16.2499 5.5064 16.25 5.55225V19.6092C16.25 20.0592 16.358 20.4842 16.55 20.8592H6C5.40326 20.8592 4.83097 20.6222 4.40901 20.2002C3.98705 19.7783 3.75 19.206 3.75 18.6093V5.55225C3.75012 5.5064 3.76284 5.46147 3.78678 5.42237C3.81072 5.38327 3.84495 5.3515 3.88573 5.33055C3.9265 5.30959 3.97226 5.30025 4.01798 5.30355C4.06371 5.30685 4.10765 5.32266 4.145 5.34925L4.32 5.47425C4.95775 5.92791 5.72116 6.17126 6.5038 6.17036C7.28644 6.16946 8.04929 5.92437 8.686 5.46925ZM17.75 19.6092V14.8593H20.25V19.6092C20.25 19.9408 20.1183 20.2587 19.8839 20.4931C19.6495 20.7276 19.3315 20.8592 19 20.8592C18.6685 20.8592 18.3505 20.7276 18.1161 20.4931C17.8817 20.2587 17.75 19.9408 17.75 19.6092Z"
      />
    </svg>
  );
}
