import { useRef, useState } from "react";

import Dropdown from "../DropDown";
import { options } from "./AccountSummary";

import Mapwrap from "../../assets/img/Map-wrap.png";
import InputWithLabel from "../InputWithLabel";

import CardSkeleton from "../CardSkeleton";

import { usePartnersDataContext } from "../../context/PartnersDataContext";
import AddIcon from "../Icons/AddIcon";
import AddNewBeneficiary from "../AddNewBeneficiary";
import { Company as PartnerCompany } from "../../context/PartnersDataContext";

export default function PartnerCompanies() {
  const [selectedCompany, setSelectedCompany] = useState<PartnerCompany>();
  const [formType, setFormType] = useState<"add" | "update">();

  const { data: partnersData, loading } = usePartnersDataContext();

  const addBeneficiarysDialogRef = useRef<HTMLDialogElement>(null);

  const openModal = (type: "update" | "add") => {
    setFormType(type);
    addBeneficiarysDialogRef.current?.showModal();
  };

  return (
    <section className="card h-[487px] col-span-full space-y-5">
      <div className="flex items-center justify-between border-b pb-2">
        <h2 className="text-[#030303] font-semibold text-xl">
          Partner Companies
        </h2>

        <div className="w-[100px]">
          <Dropdown label="" options={options} />
        </div>
      </div>

      <div className="flex justify-between">
        <div>
          <img src={Mapwrap} alt="map" />
        </div>

        <div className="w-[296px]">
          <InputWithLabel label="" placeholder="Search" />

          <div>
            {loading && (
              <div className="">
                {[1, 2, 3].map((el) => (
                  <CardSkeleton key={el} />
                ))}
              </div>
            )}
          </div>

          <div>
            <button
              type="button"
              className="p-1 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg flex items-center gap-1"
              onClick={() => openModal("add")}
            >
              <AddIcon className="size-6" />
              <span>Add New Partner</span>
            </button>

            <dialog
              ref={addBeneficiarysDialogRef}
              className="rounded-md backdrop:bg-black/50"
            >
              <AddNewBeneficiary
                onClose={() => {
                  addBeneficiarysDialogRef.current?.close();
                }}
                formType={formType}
                selectedCompany={selectedCompany}
                reqCreditLimit={true}
              />
            </dialog>
          </div>

          <div className="h-[280px] overflow-y-scroll custom-scrollbar">
            {partnersData
              ? partnersData?.map((el) => (
                  <div
                    key={el.beneficiary_id}
                    className={`flex items-center justify-between py-3`}
                  >
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {/* <img src={el.logo} alt="logo" /> */}
                        {el.company_name.at(0)?.toUpperCase()}
                      </div>

                      <div>
                        <p className="text-sm font-medium">{el.company_name}</p>
                        <small className="text-gray-400">
                          {el.company_registration_number}
                        </small>
                      </div>
                    </div>

                    <div className="pr-2">
                      <button
                        type="button"
                        className="text-custom-blue-medium font-medium hover:underline"
                        onClick={() => {
                          setSelectedCompany(el);
                          openModal("update");
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))
              : !loading && (
                  <p className="text-center text-gray-500">No records found</p>
                )}
          </div>
        </div>
      </div>
    </section>
  );
}
