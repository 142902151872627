import BarChart from "../../assets/img/dashboardBarChart.png";
import BarChartTicks from "../../assets/img/barChartTicks.png";
import Dropdown from "../DropDown";

export const options = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export default function AccountSummary() {
  return (
    <section className="card h-[428px] col-span-2 flex flex-col justify-between grayscale brightness-90">
      <div className="flex items-center justify-between">
        <h2 className="text-[#030303] font-semibold text-xl">
          Account Summary
        </h2>

        <div className="w-[100px]">
          <Dropdown disabled label="" options={options} />
        </div>
      </div>

      <div>
        <img src={BarChart} alt="bar chart" />
      </div>

      <div>{/* <img src={BarChartTicks} alt="bar chart ticks" /> */}</div>
    </section>
  );
}
