import React from 'react'
interface VerticalDividerProps{
    dividerHeight:string;
    dividerWidth:string;
    dividerBackgroundColor:string;
}

export const VerticalDivider:React.FC<VerticalDividerProps> = ({dividerHeight,dividerWidth,dividerBackgroundColor}) => {
  return (
    <div style={{height:dividerHeight,width:dividerWidth,backgroundColor:dividerBackgroundColor,marginInline:'auto',marginBlock:'10px'}}>

    </div>
  )
}
