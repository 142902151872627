import { RouteDetails } from "../pages/LSummary";

export const TrackingSteps = ({
  location,
  truck,
  rail,
  position,
}: {
  location: string;
  truck: RouteDetails;
  rail: RouteDetails;
  position: string;
}) => {
  return (
    <div>
      <div className="flex relative">
        <div>
          <p>{location}</p>
        </div>

        <div>
          <div
            className={`flex items-center absolute left-3 ${
              position === "top" ? "-top-[120px]" : ""
            } ${position === "bottom" ? "-bottom-[140px]" : ""}`}
          >
            <div className="w-[100px] border border-dashed border-gray-400" />

            <div className="w-[300px] bg-white p-2 rounded-lg">
              <div className="flex justify-between text-sm">
                <p className="text-custom-blue">Road FCL (By Truck)</p>
                <p>
                  ~ {truck?.distance},{" "}
                  {truck?.transitTime === "0" ? "0 days" : truck?.transitTime}
                </p>
              </div>
              <div className="flex justify-between text-sm">
                <p className="text-custom-blue">RF Road freight</p>
                <p>USD {truck?.price}</p>
              </div>

              {rail?.distance && rail?.price && rail?.transitTime && (
                <>
                  <hr className="text-gray-100 my-1" />

                  <div className="flex justify-between text-sm">
                    <p className="text-custom-blue">Road FCL (By Rail)</p>
                    <p>
                      ~ {rail?.distance}, {rail?.transitTime}
                    </p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <p className="text-custom-blue">RF Road freight</p>
                    <p>USD {rail?.price}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <VerticalDividerWithCircle />
    </div>
  );
};

export function VerticalDividerWithCircle() {
  return (
    <>
      <div className="h-5 w-5 border-2 border-gray-400 rounded-full mt-2" />
      <div className="w-0 h-12 border border-dashed border-gray-400 ml-2" />
      <div className="w-0 h-12 border-2 border-custom-blue ml-[7px]" />
    </>
  );
}
