import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { useAppContext } from "../../context/AppContext";
import { common_file_upload } from "../../constants/apis";
import { IFDetailsType } from "../../schemas/IFDetailsType";

import { toast } from "react-toastify";
import { fileToBase64, today } from "../../utils";

import Dropdown from "../DropDown";
import InputWithLabel from "../InputWithLabel";
import DragAndDrop from "../DragAndDrop";
import DocumentIcon from "../Icons/DocumentIcon";
import CreditLimitDropDown from "./CreditLimitDropDown";

import { usePartnersDataContext } from "../../context/PartnersDataContext";

export type InvoiceFactoring = {
  created_at: string;
  beneficiary_id: number;
  invoice_factoring_id: number;
  invoice_file: string;
  payment_request: string;
  purchase_order_file: string;
  shipment_date: string;
  uid: number;
};

const FinanceTerm = ["30 days", "60 days", "90 days", "120 days"];

export default function BuyerInformation({
  openBuyerCompanyModal,
  invoiceFactoringData,
}: {
  openBuyerCompanyModal: () => void;
  invoiceFactoringData: InvoiceFactoring[] | null;
}) {
  const [selectedBuyerId, setSelectedBuyerId] = useState<number | null>(null);

  const { jwtToken } = useAppContext();

  const { data: partnersData } = usePartnersDataContext();
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    control,
    clearErrors,
  } = useFormContext<IFDetailsType>();

  // console.log(partnersData);

  const companyOptionWithCreditLimit = partnersData?.filter(
    (el) => parseInt(el?.limit_request as string) > 0
  );

  const watchSelectedCompany = watch("company_name");

  useEffect(() => {
    if (!watchSelectedCompany) return;

    const company = partnersData?.find(
      (el) => el.company_name === watchSelectedCompany
    );

    if (!company) return;
    setSelectedBuyerId(company.beneficiary_id);

    const selectedInvoiceFactoring = invoiceFactoringData?.find(
      (el) => el.beneficiary_id === company.beneficiary_id
    );

    reset({
      ...company,
      ...selectedInvoiceFactoring,
    });
  }, [watchSelectedCompany]);

  const uploadFile = async (
    file: File,
    fieldName: "invoice_file" | "purchase_order_file"
  ) => {
    try {
      const base64String = await fileToBase64(file);

      const res = await fetch(common_file_upload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Content_Type: "application/json",
        },
        body: JSON.stringify({
          base64File: base64String,
          folder_name: "invoice_factoring",
        }),
      });

      const data = await res.json();

      if (data.status === "success") {
        setValue(fieldName, data.url as string);
        // setValue(`purchase_order_file`,data.url as string)

        clearErrors(fieldName);
      } else {
        // toast.error("File upload went wrong please try again");
      }
    } catch (error) {
      console.error("Error converting file to Base64:", error);
    }
  };

  const handleFileChangeAndUpload = async (
    e: React.FormEvent<HTMLInputElement>,
    fieldName: "invoice_file" | "purchase_order_file"
  ) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      await uploadFile(file, fieldName);
    }
  };

  const handleDragAndUpload = async (
    e: React.DragEvent<HTMLDivElement>,
    fieldName: "invoice_file" | "purchase_order_file"
  ) => {
    e.preventDefault();

    const file = e.dataTransfer.files?.[0];
    // console.log(file);

    if (file) {
      await uploadFile(file, fieldName);
    }
  };

  return (
    <section className="space-y-2">
      <div className="flex justify-between items-center py-6">
        <h3 className="text-black text-2xl font-semibold my-4">
          Buyer Information
        </h3>
      </div>

      <div className="flex gap-5">
        <Dropdown
          label={"Company Name"}
          placeholder="Select a buyer"
          options={
            companyOptionWithCreditLimit?.map((el) => ({
              label: el.company_name,
              value: el.company_name,
            })) || []
          }
          {...register("company_name")}
          error={errors.company_name?.message}
          selected
        />

        <InputWithLabel
          label={"Registration Number"}
          placeholder=""
          {...register("company_registration_number")}
          disabled
        />
        <InputWithLabel
          type="text"
          label={"Address"}
          placeholder=""
          {...register("company_address")}
          disabled
        />

        <button
          type="button"
          className="h-[43px] px-2 mt-5 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
          onClick={openBuyerCompanyModal}
          disabled={!watchSelectedCompany}
        >
          Edit
        </button>
      </div>

      <div className="flex items-center gap-4">
        <InputWithLabel
          {...register("shipment_date")}
          error={errors.shipment_date?.message}
          label={"Shipment Date"}
          id={"shipment_date"}
          placeholder=""
          type="date"
          min={today}
        />

        <CreditLimitDropDown />
      </div>

      <div className="flex gap-5">
        <Dropdown
          label={"Finance Term"}
          placeholder="Select finance term"
          options={
            FinanceTerm?.map((el) => ({
              label: el,
              value: el,
            })) || []
          }
          {...register("finance_term")}
          error={errors.finance_term?.message}
          // selected
        />

        <InputWithLabel
          label={"Invoice Amount"}
          placeholder="Enter here"
          {...register("invoice_amount")}
          error={errors.invoice_amount?.message}
        />
      </div>

      <div>
        <Controller
          name="payment_request"
          control={control}
          render={({ field }) => (
            <div className="w-full relative">
              <InputWithLabel
                {...field}
                label="Payment Request"
                id="payment_request"
                type="range"
                min={1}
                max={90}
                onChange={(e) => {
                  const newValue = Math.max(
                    Number(e.currentTarget.value),
                    Number(
                      invoiceFactoringData
                        ?.filter((el) => el.beneficiary_id === selectedBuyerId)
                        .at(0)?.payment_request || e.currentTarget.value
                    )
                  );
                  field.onChange(String(newValue || 1)); // Update React Hook Form state
                }}
                defaultValue={"1"}
              />

              <p className="absolute right-0 -top-[2px] text-sm text-gray-600">
                {field.value || 0}%
              </p>
            </div>
          )}
        />
      </div>

      <div className="flex flex-row w-full gap-4 pb-2 border-b-2">
        {/* DOCFIELDS Section */}
        <div className="flex flex-col gap-2 mb-2 w-[50%]">
          <div className="flex justify-between">
            <label className="text-[#344054] text-sm font-medium">
              Upload Invoice
            </label>
            {getValues().invoice_file?.startsWith("https:") ? (
              <a
                className="text-sm flex float-end items-center gap-1 text-custom-blue hover:underline"
                target="_blank"
                rel="noopener noreferrer" // Important for security
                href={getValues()?.invoice_file}
              >
                <DocumentIcon className="size-[14px]" /> View your uploaded
                document
              </a>
            ) : null}
          </div>

          <DragAndDrop
            id={`file-input`}
            onChange={(e) => {
              handleFileChangeAndUpload(e, "invoice_file");

              toast.promise(handleFileChangeAndUpload(e, "invoice_file"), {
                pending: "File is uploading",
                success: "File has been uploaded 👌",
                error: "Rejected, please try again 🤯",
              });
            }}
            onDrop={(e) => {
              handleDragAndUpload(e, "invoice_file");

              toast.promise(handleDragAndUpload(e, "invoice_file"), {
                pending: "File is uploading",
                success: "File has been uploaded 👌",
                error: "Rejected, please try again 🤯",
              });
            }}
          />
          <small className="text-red-500 h-[10px] block ml-1">
            {errors?.invoice_file?.message}
          </small>
        </div>

        <div className="flex flex-col gap-2 mb-2 w-[50%]">
          <div className="flex justify-between">
            <label className="text-[#344054] text-sm font-medium">
              Purchase Order
            </label>
            {getValues()?.purchase_order_file?.startsWith("https:") ? (
              <a
                className="text-sm flex float-end items-center gap-1 text-custom-blue hover:underline"
                target="_blank"
                rel="noopener noreferrer" // Important for security
                href={getValues()?.purchase_order_file}
              >
                <DocumentIcon className="size-[14px]" /> View your uploaded
                document
              </a>
            ) : null}
          </div>
          <DragAndDrop
            id={`file-input-purchase-order`}
            onChange={(e) => {
              handleFileChangeAndUpload(e, "purchase_order_file");

              toast.promise(
                handleFileChangeAndUpload(e, "purchase_order_file"),
                {
                  pending: "File is uploading",
                  success: "File has been uploaded 👌",
                  error: "Rejected, please try again 🤯",
                }
              );
            }}
            onDrop={(e) => {
              handleDragAndUpload(e, "purchase_order_file");

              toast.promise(handleDragAndUpload(e, "purchase_order_file"), {
                pending: "File is uploading",
                success: "File has been uploaded 👌",
                error: "Rejected, please try again 🤯",
              });
            }}
          />
          <small className="text-red-500 h-[10px] block ml-1">
            {errors.purchase_order_file?.message}
          </small>
        </div>
      </div>
    </section>
  );
}

// function PaymentRequestSlider({
//   selectedBuyerId,
//   invoiceFactoringData,
// }: {
//   selectedBuyerId: number | null;
//   invoiceFactoringData: InvoiceFactoring[] | null;
// }) {
//   const { control } = useFormContext();

//   // Fetch the value from the database (e.g., payment_request value from the invoiceFactoringData)
//   const currentPaymentRequest =
//     invoiceFactoringData
//       ?.filter((el) => el.beneficiary_id === selectedBuyerId)
//       .at(0)?.payment_request || 0;

//   // The remaining range should be 100 - currentPaymentRequest
//   const remainingRange = 100 - currentPaymentRequest;

//   return (
//     <div>
//       {/* <div className="w-full relative"> */}
//       <Controller
//         name="payment_request"
//         control={control}
//         render={({ field }) => (
//           <div className="w-full relative">
//             <InputWithLabel
//               {...field}
//               label="Payment Request"
//               id="payment_request"
//               type="range"
//               min={1}
//               max={remainingRange}
//               // onChange={(e) => {
//               //   const newValue = Math.max(
//               //     Number(e.currentTarget.value),
//               //     Number(
//               //       invoiceFactoringData
//               //         ?.filter((el) => el.beneficiary_id === selectedBuyerId)
//               //         .at(0)?.payment_request || e.currentTarget.value
//               //     )
//               //   );
//               //   field.onChange(String(newValue || 1)); // Update React Hook Form state
//               // }}
//               onChange={(e) => {
//                 field.onChange(e.currentTarget.value);
//               }}
//               // defaultValue={"1"}
//             />

//             <p className="absolute right-0 -top-[2px] text-sm text-gray-600">
//               {field.value || 0}%
//             </p>

//             {/* Display min value */}
//             <div className="absolute left-0 bottom-[-20px] text-sm text-gray-600">
//               1%
//             </div>

//             {/* Display max value */}
//             <div className="absolute right-0 bottom-[-20px] text-sm text-gray-600">
//               {remainingRange}%
//             </div>

//             {/* Display current value */}
//             <p className="absolute right-0 -top-[2px] text-sm text-gray-600">
//               {field.value || 0}%
//             </p>
//           </div>
//         )}
//       />
//     </div>
//   );
// }
