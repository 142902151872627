import React from "react";
import Button from "./Button";
import {
  blackColor,
  greenHighlightColor,
  whiteColor,
} from "../constants/colors";

interface AlertPorps {
  alertText?: string;
  handleClick?: () => void;
  buttonWidth?: string;
  buttonHeight?: string;
  borderRadius?: string;
  buttonTitle?: string;
  paddingBlock?: string;
  backgroundColor?: string;
  iconSource?: string;
  altIcon?: string;
  alertText2?: string;
  alertText1?: string;
  iconSize?: string;
  alertTextColor?: string;
}

export const AltertBox: React.FC<AlertPorps> = ({
  alertTextColor,
  alertText,
  alertText1,
  alertText2,
  handleClick,
  iconSize,
  iconSource,
  altIcon,
  borderRadius,
  buttonHeight,
  buttonTitle,
  buttonWidth,
  paddingBlock,
  backgroundColor,
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.5)",
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "500px",
            borderRadius: "10px",
            height: "350px",
            marginInline: "auto",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: whiteColor,
            paddingInline: "40px",
            paddingBottom: "20px",
          }}
        >
          <img
            style={{
              textAlign: "center",
              width: iconSize || "44px",
              height: iconSize || "44px",
              marginInline: "auto",
              marginBlock: "10px",
              justifyContent: "center",
              backgroundColor: greenHighlightColor,
              borderRadius: "50%",
            }}
            src={iconSource}
            alt={altIcon}
          />
          <p
            style={{
              color: alertTextColor || greenHighlightColor,
              textAlign: "center",
              fontSize: 16,
              marginBlock: "20px",
            }}
          >
            {alertText}
          </p>
          <p style={{ color: blackColor, textAlign: "center", fontSize: 16 }}>
            {alertText1}
          </p>
          <p style={{ color: blackColor, textAlign: "center", fontSize: 16 }}>
            {alertText2}
          </p>
          <div
            style={{ width: "50%", marginBlock: "20px", marginInline: "auto" }}
            onClick={handleClick}
          >
            <Button
              type="button"
              style={{ height: "50px", width: "100%", borderRadius: "10px" }}
              width={buttonWidth}
              height={buttonHeight}
              backgroundColor={backgroundColor}
              borderRadius={borderRadius}
              titleStyle={{ textAlign: "center" }}
              buttonTitle={buttonTitle}
              paddingBlock={paddingBlock}
            />
          </div>
        </div>
      </div>
    </>
  );
};