import { useAppContext } from "../../context/AppContext";
import InputWithLabel from "../InputWithLabel";

export default function UserDetailsSection() {
  const { user } = useAppContext();

  return (
    <section className="max-w-[660px] flex items-center gap-4">
      <InputWithLabel
        label="User Name"
        type="text"
        placeholder="Enter Here"
        defaultValue={`${user?.first_name} ${user?.last_name}`}
        disabled
      />
      <InputWithLabel
        label="Email"
        type="text"
        placeholder="Enter Here"
        defaultValue={user?.work_email}
        disabled
      />
    </section>
  );
}
