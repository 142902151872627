import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

import { ArrowIcon } from "./Icons/ArrowIcon";

import LogOutIcon from "./Icons/LogOutIcon";
import EditIcon from "./Icons/EditIcon";

import DashboardIcon from "./Icons/DashboardIcon";
import UserAvatar from "../assets/img/Avatar.png";
import TaskIcon from "./Icons/TaskIcon";
import MessageIcon from "./Icons/MessageIcon";
import BellRingIcon from "./Icons/BellRingIcon";

import ShieldCheckIcon from "./Icons/ShieldCheckIcon";

export default function UserDropdown() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userDropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const navigate = useNavigate();

  const { removeJwtToken, user, userLoading } = useAppContext();

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    removeJwtToken();
    navigate("/");
  };

  // Close the dropdown if the click is outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  // Add event listener when the component mounts and remove it when it unmounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Cleanup listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex items-center gap-4">
      {/* notification section */}
      <div className="flex items-center gap-2 text-[#737373]">
        <button>
          <TaskIcon className="size-6" />
        </button>

        <button>
          <MessageIcon className="size-6" />
        </button>
        <button>
          <BellRingIcon className="size-6" />
        </button>
      </div>

      {/* Profile section */}
      <button
        className="flex items-center gap-1 cursor-pointer"
        onClick={toggleDropdown}
        ref={buttonRef}
      >
        <div className="relative bg-white size-10 rounded-full">
          <img
            src={UserAvatar}
            alt="avatar"
            className="border-2 border-[#7373734A] rounded-full"
          />

          <div className="absolute bottom-[2px] right-[2px] w-[10px] h-[10px] bg-green-500 rounded-full border-2 border-white" />
        </div>

        {userLoading ? (
          <div className="animate-pulse space-y-2">
            <div className="h-3 w-[100px] bg-slate-300 rounded"></div>

            <div className="h-2 w-[60px] bg-slate-300 rounded"></div>
          </div>
        ) : (
          <div className="flex flex-col items-start">
            <p className="text-[#182230] font-medium">
              {user && `${user?.first_name} ${user?.last_name}`}
            </p>
            <small className="text-[#737373] font-medium">
              {user?.job_title}
            </small>
          </div>
        )}
        <ArrowIcon
          className={`ml-2 size-6 transition-transform duration-300 ease-in-out ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {/* Dropdown section */}
      {isDropdownOpen && (
        <div
          className="absolute right-0 top-10 bg-white w-[200px] p-1 rounded-lg shadow-2xl z-50"
          ref={userDropdownRef}
        >
          <button
            className="w-full p-1 rounded-lg hover:bg-custom-off-white flex items-center gap-1"
            onClick={() => {
              navigate("/dashboard");
              setIsDropdownOpen(false);
            }}
          >
            <DashboardIcon className="size-5" /> <span>Go to dashboard</span>
          </button>

          <button
            className="w-full p-1 rounded-lg hover:bg-custom-off-white flex items-center gap-1"
            onClick={() => {
              navigate("/registration");
              setIsDropdownOpen(false);
            }}
          >
            <EditIcon className="size-5" />
            <span>Business Info</span>
          </button>

          <button
            className="w-full p-1 rounded-lg hover:bg-custom-off-white flex items-center gap-1"
            onClick={() => {
              navigate("/security");
              setIsDropdownOpen(false);
            }}
          >
            <ShieldCheckIcon className="size-5" /> <span>Security</span>
          </button>

          <button
            className="w-full p-1 rounded-lg hover:bg-custom-off-white flex items-center gap-1"
            onClick={handleLogout}
          >
            <LogOutIcon className="size-5" /> <span>Log Out</span>
          </button>
        </div>
      )}
    </div>
  );
}
