const QualityAssuranceIcon = ({ className }: { className: string }) => (
  <svg
    viewBox="0 0 23 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M21.5625 22.0594L19.55 19.9594C19.9094 19.3594 20.125 18.6094 20.125 17.8594C20.125 15.7594 18.5437 14.1094 16.5312 14.1094C14.5188 14.1094 12.9375 15.7594 12.9375 17.8594C12.9375 19.9594 14.5188 21.6094 16.5312 21.6094C17.25 21.6094 17.9687 21.3844 18.5437 21.0094L20.5563 23.1094L21.5625 22.0594ZM14.375 17.8594C14.375 16.5844 15.3094 15.6094 16.5312 15.6094C17.7531 15.6094 18.6875 16.5844 18.6875 17.8594C18.6875 19.1344 17.7531 20.1094 16.5312 20.1094C15.3094 20.1094 14.375 19.1344 14.375 17.8594ZM5.75 12.6094H12.9375V14.1094H5.75V12.6094ZM5.75 8.10938H14.375V9.60938H5.75V8.10938Z" />
    <path d="M10.0625 21.3844L6.325 19.2844C4.16875 18.1594 2.875 15.8344 2.875 13.3594V3.60938H17.25V11.8594H18.6875V3.60938C18.6875 2.78437 18.0406 2.10938 17.25 2.10938H2.875C2.08437 2.10938 1.4375 2.78437 1.4375 3.60938V13.3594C1.4375 16.4344 3.01875 19.2094 5.60625 20.6344L10.0625 23.1094V21.3844Z" />
  </svg>
);

export default QualityAssuranceIcon;
