import { forwardRef, HTMLProps, ReactNode, Ref } from "react";

interface Option {
  label: string;
  value: string;
}

interface DropdownProps extends HTMLProps<HTMLSelectElement> {
  label: string;
  options: Option[];
  element?: ReactNode;
  error?: string;
}

const Dropdown = forwardRef(
  (props: DropdownProps, ref: Ref<HTMLSelectElement>) => {
    const {
      label,
      options,
      placeholder,
      style,
      id,
      error,
      className,
      element,
      ...selectProps
    } = props;

    return (
      <div className="w-full">
        <div className="flex justify-between items-center">
          <label
            htmlFor={id}
            style={style}
            className="text-[#344054] text-sm font-medium"
          >
            {label}
          </label>

          {element}
        </div>

        <select
          className={`input-new ${className}`}
          id={id}
          ref={ref}
          {...selectProps}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <small className="text-red-500 h-[10px] block ml-1">
          {error && error}
        </small>
      </div>
    );
  }
);

export default Dropdown;
