import { z } from "zod";

export const getQualityAssuranceFormSchema = ({
  isLoadingInspection,
  isQualityInspection,
  isSupplierAuditInspection,
}: {
  isLoadingInspection:boolean;
  isQualityInspection:boolean;
  isSupplierAuditInspection:boolean;
}) => {
  return z.object({
    beneficiary_id: z.number().optional(),
    supplier_audit: z.boolean(),
    quality_assurance: z.boolean(),
    loading_inspection:isLoadingInspection ? z.boolean():z.boolean().optional(),
    audit_specification:isSupplierAuditInspection ? z.string({
      required_error: "Enter a audit Specifications",
    }):z.string().optional(),
    qa_consignment_value: isQualityInspection
      ? z
          .string({ message: "Expect a Consignment Value" })
          .min(1, "Filed is required")
      : // .int({ message: "Enter a integer value" })
        z.string().optional(),
    no_of_containers: isLoadingInspection
      ? z
          .string({ message: "Enter a Containers quantity" })
          .min(1, "Filed is required")
      : z.string().optional(),
    // .int({ message: "Enter a integer value" })
    loading_consignment_value: isLoadingInspection
      ? z
          .string({ message: "Enter a consignment value" })
          .min(1, "Filed is required")
      : z.string().optional(),
    // .int({ message: "Enter a integer value" }),
    goods_specifications: isLoadingInspection
      ? z.string({ message: "Enter goods specification" })
      : z.string().optional(),
    quality_specifications_file:isQualityInspection ? z.array(
      z.object({
        url: z
          .string({ message: "Quality Specifications file" })
          .min(1, "Document is required")
          .includes("https://", { message: "Please select a document" }),

        file: z.instanceof(FileList, { message: "Please select a document" }),
      })
    ):z.array(
      z.object({
        url: z.string().optional(),
        file: z.instanceof(File).optional(),
      })
    ).optional(),
  });
};

export type QualityAssuranceType = z.infer<
  ReturnType<typeof getQualityAssuranceFormSchema>
> & {
  company_name?: string;
  company_registration_number?: string;
  company_address?: string;
  address_of_inspection?: string;
  contact_person_name?: string;
  email?: string;
  phone_number?: string;
};
