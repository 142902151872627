import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

import SPSenderBeneficiary from "../../pages/SPSenderBeneficiary";
import { SPDetails } from "../../pages/SPDetails";
import { SPSummary } from "../../pages/SPSummary";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { SPSenderBeneficiaryFormSchema } from "../../schemas/SPSenderBeneficiaryType";
import { SPDetailsFormSchema } from "../../schemas/SPDetailsFieldType";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import AddNewBeneficiary from "../AddNewBeneficiary";
import { Company as PartnerCompany } from "../../context/PartnersDataContext";
import PasswordAndOtpPopup from "./PasswordAndOtpPopup";
import {
  add_simple_payment,
  get_simple_payment,
  service_submit_mail,
  update_simple_payment,
} from "../../constants/apis";
import { toast } from "react-toastify";
import SpinnerIcon from "../Icons/SpinnerIcon";
import {
  SimplePayment,
  useSimplePaymentDataContext,
} from "../../context/SimplePaymentDataContext";
import { useFetch } from "../../hooks/useFetch";

const steps = [
  {
    id: 1,
    name: "Beneficiary",
  },
  { id: 2, name: "Details" },
  { id: 3, name: "Summary" },
];

const FullSimplePaymentSchema =
  SPSenderBeneficiaryFormSchema.and(SPDetailsFormSchema);
export type FullSimplePaymentSchemaType = z.infer<
  typeof FullSimplePaymentSchema
>;

export default function SimplePaymentForm() {
  const [currentStep, setCurrentStep] = useState(1);

  const [selectedCompany, setSelectedCompany] = useState<PartnerCompany>();
  const [formType, setFormType] = useState<"add" | "update">();
  const [values, setValues] = useState<FullSimplePaymentSchemaType | null>(
    null
  );
  const [saveAsDraftLoading, setSaveAsDraftLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const simple_payment_id = searchParams.get("simple_payment_id");

  const { refetch } = useSimplePaymentDataContext();
  const { checkAvailability, jwtToken } = useAppContext();
  const navigate = useNavigate();

  // dialog ref
  const addBeneficiarysDialogRef = useRef<HTMLDialogElement>(null);
  const passwordAndOtpPopupRef = useRef<HTMLDialogElement>(null);

  const openModal = (type: "update" | "add") => {
    setFormType(type);
    addBeneficiarysDialogRef.current?.showModal();
  };

  const { data: simplePaymentDraftData, loading } = useFetch<SimplePayment>(
    simple_payment_id ? get_simple_payment : null,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ simple_payment_id }),
    },
    simple_payment_id!
  );

  const methods = useForm<FullSimplePaymentSchemaType>({
    resolver: zodResolver(FullSimplePaymentSchema),
    defaultValues:
      currentStep == 2
        ? {
            amount_sent_currency: "USD",
            amount_received_currency: "USD",
          }
        : {},

    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
    trigger,
    setFocus,
  } = methods;

  // console.log(errors);

  useEffect(() => {
    if (!simplePaymentDraftData) {
      return;
    }

    const { beneficiary_details, beneficiary_account_details, simple_payment } =
      simplePaymentDraftData;

    const draftData = {
      ...beneficiary_details,
      ...beneficiary_account_details,
      ...simple_payment,
    };

    reset(draftData);

    if (simple_payment?.step) {
      setCurrentStep(simple_payment.step);
    }
  }, [simplePaymentDraftData, reset]);

  const nextStep = async () => {
    let fieldsToValidate: any[] = [];

    if (currentStep === 1) {
      fieldsToValidate = Object.keys(SPSenderBeneficiaryFormSchema.shape);
    } else if (currentStep === 2) {
      fieldsToValidate = Object.keys(SPDetailsFormSchema.shape);
    }

    const valid = await trigger(fieldsToValidate);
    console.log(getValues());

    if (valid) {
      setCurrentStep((prev) => prev + 1);

      window.scrollTo({ top: 0 });
    } else {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        setFocus(firstErrorField as keyof FullSimplePaymentSchemaType);
      }
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);

    window.scrollTo({ top: 0 });
  };

  const someDataPresent = Object.values(getValues()).some(
    (value) => value !== undefined || value !== null || value !== ""
  );

  const onClickSaveAsDraft = async () => {
    // console.log(getValues());
    setSaveAsDraftLoading(true);
    const values = getValues();

    const { beneficiary_id, beneficiary_account_id, ...rest } = values;

    const payload = simple_payment_id
      ? {
          simple_payment_id,
          save_as_draft: 1,
          step: currentStep,
          contract_id: "12345",
          beneficiary_id,
          beneficiary_account_id,
          ...rest,
        }
      : {
          save_as_draft: 1,
          step: currentStep,
          contract_id: "12345",
          beneficiary_id,
          beneficiary_account_id,
          ...rest,
        };

    const apiUrl = simple_payment_id
      ? update_simple_payment
      : add_simple_payment;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      console.log(result);

      if (result.status == "success") {
        toast.success("Successfully save as draft");

        let params = new URLSearchParams();
        params.set("simple_payment_id", result.simple_payment_id);
        setSearchParams(params);

        refetch();
      } else {
        toast.error("Someting whent wrong! please try again");
      }
    } catch (error) {
      console.log(error);
      toast.error("Someting whent wrong! please try again");
    } finally {
      setSaveAsDraftLoading(false);
    }
  };

  const onSubmitHandler: SubmitHandler<FullSimplePaymentSchemaType> = async (
    values
  ) => {
    // console.log(values);
    setValues(values);

    try {
      const res = await fetch(service_submit_mail, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type: "simple payment" }),
      });

      if (!res.ok) {
        throw new Error("Something went wrong please try again");
      }

      const data = await res.json();
      if (data.status === "success") {
        passwordAndOtpPopupRef.current?.showModal();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="flex">
      <nav className="w-[171px] p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
        {steps.map((el) => (
          <div key={el.id}>
            <div
              className={`w-full py-2 transition-colors duration-700 ${
                el.id === currentStep
                  ? "text-custom-blue-medium"
                  : "text-[#475467]"
              }`}
            >
              <p className="flex items-center gap-1">
                <span
                  className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                    el.id === currentStep
                      ? "text-custom-blue-medium border-custom-blue-medium"
                      : "border-[#D0D5DD]"
                  }`}
                >
                  0{el.id}
                </span>{" "}
                {el.name}
              </p>
            </div>
            {el.id !== steps.length && (
              <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
            )}
          </div>
        ))}
      </nav>

      <div className="relative w-full">
        {!checkAvailability && (
          <div className="absolute top-0 left-0 right-0 bottom-0 z-10 backdrop-blur-sm">
            <div className="h-full flex justify-center">
              <p className="text-red-300 font-medium pt-5">
                Please fill in the origin and destination details to proceed to
                the next step.
              </p>
            </div>
          </div>
        )}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitHandler)} className="px-8">
            {loading && (
              <div className="backdrop-blur-sm rounded-lg absolute top-0 left-0 bottom-0 right-0 z-30" />
            )}
            {currentStep === 1 && (
              <SPSenderBeneficiary
                {...{
                  selectedCompany,
                  setSelectedCompany,
                  openModal,
                }}
              />
            )}
            {currentStep === 2 && <SPDetails />}
            {currentStep === 3 && <SPSummary />}

            <div className="mt-4 py-6 flex justify-between border-t border-[#EAECF0]">
              {currentStep === 1 && (
                <button
                  type="button"
                  className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </button>
              )}

              {currentStep !== 1 && (
                <button
                  type="button"
                  // disabled={isSubmitting}
                  className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                  onClick={prevStep}
                >
                  Back
                </button>
              )}

              <div className="space-x-4">
                <button
                  type="button"
                  className="h-[44px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
                  onClick={onClickSaveAsDraft}
                  disabled={!someDataPresent}
                >
                  {saveAsDraftLoading ? (
                    <p className="space-x-2">
                      <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                      <span>Please wait...</span>
                    </p>
                  ) : (
                    "Save as Draft"
                  )}
                </button>

                {currentStep !== 3 && (
                  <button
                    type="button"
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                    onClick={nextStep}
                  >
                    Save and Next
                  </button>
                )}

                {currentStep === 3 && (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                  >
                    {isSubmitting ? "submitting.." : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>

        <dialog
          ref={addBeneficiarysDialogRef}
          className="rounded-md backdrop:bg-black/50"
        >
          <AddNewBeneficiary
            onClose={() => {
              addBeneficiarysDialogRef.current?.close();
            }}
            formType={formType}
            selectedCompany={selectedCompany}
          />
        </dialog>

        <dialog
          ref={passwordAndOtpPopupRef}
          className="backdrop:bg-black/50 rounded-md shadow-lg"
        >
          <PasswordAndOtpPopup values={values} />
        </dialog>
      </div>
    </div>
  );
}
