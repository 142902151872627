import { useAppContext } from "../context/AppContext";

import Chart1 from "../assets/img/chart1.png";
import Chart2 from "../assets/img/chart2.png";
import Chart3 from "../assets/img/chart3.png";

import USAFlag from "../assets/img/USA-flag.png";
import EUFlag from "../assets/img/eu-flag.png";
import AccountSummary from "../components/Dashboard/AccountSummary";
import Task from "../components/Dashboard/Task";
import History from "../components/Dashboard/History";
import Quotes from "../components/Dashboard/Quotes";
import PartnerCompanies from "../components/Dashboard/PartnerCompanies";
import Financing from "../components/Dashboard/Financing";
import Messages from "../components/Dashboard/Messages";
import Drafts from "../components/Dashboard/Drafts";
import AddIcon from "../components/Icons/AddIcon";

const Dashboard = () => {
  const { user, userLoading } = useAppContext();

  return (
    <>
      {userLoading ? (
        <section className="animate-pulse space-y-3 mt-2 mb-5">
          <div className="h-6 w-[400px] bg-slate-300 rounded"></div>

          <div className="h-4 w-[200px] bg-slate-300 rounded"></div>
        </section>
      ) : (
        <section className="mt-2 mb-5">
          <h1 className="font-medium text-2xl text-gray-800">
            Welcome {user?.first_name} 👋
          </h1>
          <p className="text-gray-400">Here is Your Latest Updates</p>
        </section>
      )}

      <section className="grid grid-cols-3 gap-6">
        {/* <div className="card">
          <h2 className="font-semibold text-lg mb-2">Total Amount</h2>

          <div className="flex justify-between items-center">
            <div>
              <p>
                <span className="font-bold text-3xl">1257.00</span>{" "}
                <span className="font-medium text-xs text-[#9C9FAD]">
                  / This Month
                </span>
              </p>
              <small className="text-[#9C9FAD]">1050.00.last month</small>
            </div>

            <div>
              <img src={Chart1} alt="chart" />
            </div>
          </div>
        </div> */}

        <div className="flip-card card">
          <div className="flip-card-inner relative w-full h-full">
            <div className="flip-card-front">
              <div className="flex items-center justify-between mb-2">
                <h2 className="font-semibold text-lg">USD - Account</h2>
                <img src={USAFlag} alt="usa flag" />
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <p>
                    <span className="font-bold text-3xl">557.00</span>{" "}
                    <span className="font-medium text-xs text-[#9C9FAD]">
                      / This Month
                    </span>
                  </p>
                  <small className="text-[#9C9FAD]">100.00.last month</small>
                </div>

                <div>
                  <img src={Chart2} alt="chart" />
                </div>
              </div>
            </div>

            <div className="flip-card-back">
              <h3 className="font-medium">Account Details:</h3>
              <div>
                <p className="text-[12px] flex justify-between">
                  <span>Account No.:</span>{" "}
                  <span className="text-[#6C6868]">01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>IBAN Account:</span>{" "}
                  <span className="text-[#6C6868]">PADG01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Routing No:</span>{" "}
                  <span className="text-[#6C6868]">12997</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Bank Address:</span>
                  <span className="text-[#6C6868]">
                    Lorem Ipsum is the dummy dummy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h2 className="font-semibold text-lg">EURO - Account</h2>
            <img src={EUFlag} alt="eu flag" />
          </div>

          <div className="flex justify-between items-center">
            <div>
              <p>
                <span className="font-bold text-3xl">700.00</span>{" "}
                <span className="font-medium text-xs text-[#9C9FAD]">
                  / This Month
                </span>
              </p>
              <small className="text-[#9C9FAD]">650.00.last month</small>
            </div>

            <div>
              <img src={Chart3} alt="chart" />
            </div>
          </div>
        </div>

        <div className="card flex flex-col justify-center items-center gap-2">
          <button className="flex items-center gap-2 font-semibold text-[#6F6D6D]">
            <AddIcon className="size-9" />
            <span>Add New Currency</span>
          </button>
          <p className="text-sm text-[#9C9FAD]">
            Add a new currency to your account
          </p>
        </div>

        <AccountSummary />
        <Task />

        <History />
        <Quotes />
        <Drafts />

        <PartnerCompanies />
      </section>

      <section className="grid grid-cols-2 gap-6 mt-6">
        <Financing />
        <Messages />
      </section>
    </>
  );
};

export default Dashboard;
