import { toast } from "react-toastify";

import InputWithLabel from "../components/InputWithLabel";

import {
  add_insured_details,
  get_insured_details,
  update_insured_details,
} from "../constants/apis";
import { PolicyHolderSchema, PolicyHolderType } from "../schemas/PolicyHolder";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { useFetch } from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

import Spinner from "../components/Spinner";

export const InsurancePolicyHolder = () => {
  const { jwtToken, user } = useAppContext();

  const navigate = useNavigate();

  const { data: insuredData, loading: insuredLoading } = useFetch<
    PolicyHolderType & { insured_details_id: number | string }
  >(get_insured_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  // console.log(insuredData);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<PolicyHolderType>({
    resolver: zodResolver(PolicyHolderSchema),

    values: insuredData!,
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      reset({
        company_name:user?.company_name,
        company_id: user?.company_reg_number,
        company_address: user?.company_reg_address,
        city: user?.city,
        country: user?.company_reg_country,
        email: user?.work_email,
        phone_number: user?.company_phone_number,
        contact_name: `${user?.first_name}${""} ${user?.last_name}`,
      });
    } else {
      reset({
        company_name: "",
        company_id: "",
        company_address: "",
        city: "",
        country: "",
        email: "",
        phone_number: "",
        contact_name: "",
      });
    }
  };

  console.log(errors);

  const onSubmitHandler: SubmitHandler<PolicyHolderType> = async (values) => {
    console.log("click");

    const { same_as_policy_holder, ...rest } = values;

    const apiUrl = insuredData?.insured_details_id
      ? update_insured_details
      : add_insured_details;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          insuredData?.insured_details_id
            ? {
                insured_details_id: insuredData.insured_details_id,
                same_as_policy_holder: same_as_policy_holder ? 1 : 0,
                ...rest,
              }
            : {
                same_as_policy_holder: same_as_policy_holder ? 1 : 0,
                ...rest,
              }
        ),
      });
      const result = await response.json();
      // console.log(result);

      if (result.status == "success") {
        toast.success(result?.message || result?.msg);
        navigate(
          `/insurance/details?insuredDetailsId=${insuredData?.insured_details_id}`
        );
      }
    } catch (error) {
      toast.error("Something went wrong please try again");
    }
  };

  // if (userLoading) {
  //   return (
  //     <div>
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <section className="relative py-4 pl-8">
      <h3 className="text-black text-2xl font-semibold mb-5">
      Policy Holder
      </h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <InputWithLabel
            disabled={true}
            label={"Company Name"}
            defaultValue={user?.company_name}
          />
          <InputWithLabel
            disabled={true}
            label={"Registration Number"}
            defaultValue={user?.company_reg_number}
          />
          <InputWithLabel
            disabled={true}
            label={"Address"}
            defaultValue={user?.company_reg_address}
          />
        </div>
        <div className="flex gap-4">
        <InputWithLabel
            disabled={true}
            label={"Contact Name"}
            defaultValue={`${user?.first_name} ${user?.last_name}`}
          />
          <InputWithLabel
            disabled={true}
            label={"Email"}
            defaultValue={user?.work_email}
          />
          <InputWithLabel
            disabled={true}
            label={"Phone Number"}
            defaultValue={user?.company_phone_number}
          />
         
        </div>
        {/* <div className="flex gap-4">
          <InputWithLabel
            disabled={true}
            label={"COMPANY ADDRESS"}
            defaultValue={user?.company_reg_address}
          />
          <InputWithLabel
            disabled={true}
            label={"CITY"}
            defaultValue={user?.city}
          />
          <InputWithLabel
            disabled={true}
            label={"COUNTRY"}
            defaultValue={user?.company_reg_country}
          />
        </div> */}
       
      </div>
      <hr className="border-b my-5 border-b-[#F3F3F5]" />

      <form
        className="mt-4 flex flex-col gap-2"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        {/* Policyholder fields */}

        {/* Insured fields */}
        <div className="flex flex-row justify-between items-center py-6">
        <h3 className="text-black text-2xl font-semibold mb-5">
        Insured</h3>

        <div className=" flex items-center gap-2">
            {/* <Checkbox
              label="Same as Policy Holder"
              isChecked={isChecked}
              onChange={handleCheckbox}
            /> */}

            <input
              type="checkbox"
              className="w-5 h-5 "
              id="same_as_policy_holder"
              {...register("same_as_policy_holder")}
              onChange={handleCheckboxChange}
            />
            <label className="font-semibold" htmlFor="same_as_policy_holder">Same As Policyholder</label>
          </div>
        </div>
        <div className="flex gap-4">
          <InputWithLabel
            label={"Company Name"}
           {...register("company_name")} 
            defaultValue={insuredData?.company_name}
          />
          <InputWithLabel
            label={"Registration Number"}
            {...register("company_id")}
            defaultValue={insuredData?.company_id}
          />
          <InputWithLabel
            label={"Address"}
            {...register("company_address")}
            defaultValue={insuredData?.company_address}
          />
        </div>
        <div className="flex gap-4">
          <InputWithLabel
            label={"Email"}
            {...register("email")}
            defaultValue={insuredData?.email}
          />
          <InputWithLabel
            label={"Phone Number"}
            {...register("phone_number")}
            defaultValue={insuredData?.phone_number}
          />
          <InputWithLabel
            label={"Contact Name"}
            {...register("contact_name")}
            defaultValue={insuredData?.contact_name}
          />
        </div>
        <hr className="border-b my-5 border-b-[#F3F3F5]" />

        {/* Submit Button */}
        <div className="space-x-4 py-4 flex justify-between items-center">
          <button
            type="button"
            className="h-[44px] px-2 text-[#0348B7] font-medium border border-[#0348B7] rounded-lg"
          >
            Cancel
          </button>

          <button
            type="submit"
            className="w-[146px] h-[44px] rounded-md bg-[#0348B7] text-white font-medium"
          >
            {isSubmitting ? "Submitting.." : "Save and Next"}
          </button>
        </div>
      </form>
    </section>
  );
};
