interface DataFields{
    data:any;
    userData:any;
}

export const SPDataShow = ({data,userData}:DataFields) => {
  return  <div className="text-left ml-10">
  <div className="space-y-2">
    <p className="text-custom-blue font-medium">Sender</p>
    <p>COMPANY NAME: {userData?.company_name}</p>
    <p>COMPANY EMAIL: {userData?.work_email}</p>
  </div>
  <div className="flex justify-between items-center space-y-10">
    <div className="space-y-2">
      <p className="text-custom-blue font-medium">
        Beneficiary
      </p>
      <p>
        COMPANY NAME:{" "}
        {data?.beneficiary_details?.company_name}
      </p>
      <p>
        ACCOUNT REFERENCE NAME:{" "}
        {
          data?.beneficiary_account_details
            ?.account_ref_name
        }
      </p>
      <p>
        ACCOUNT HOLDER NAME:{" "}
        {
          data?.beneficiary_account_details
            ?.account_holder_name
        }
      </p>
      <p>
        ACCOUNT NO./IBAN:{" "}
        {
          data?.beneficiary_account_details
            ?.account_no
        }
      </p>
      <p>
        CURRENCY:{" "}
        {
          data?.beneficiary_account_details
            ?.currency
        }
      </p>
      <p>
        BANK:{" "}
        {data?.beneficiary_account_details?.bank}
      </p>
      <p>
        BANK ADDRESS:{" "}
        {
          data?.beneficiary_account_details
            ?.bank_address
        }
      </p>
    </div>
    <div>
      <div className="space-y-2 mr-20">
        <p className="text-custom-blue font-medium">Details</p>
        <p>
          AMOUNT SENT:{" "}
          {data?.simple_payment?.amount_sent}
        </p>
        <p>
          AMOUNT RECEIVED:{" "}
          {data?.simple_payment?.amount_received}
        </p>
        <p>CURRENT MARKET RATE: 1 USD = 1 EURO</p>
        <p>FOREX RATE:</p>
        <p>FEES: {data?.simple_payment?.fees}</p>
        <p>
          ESTIMATED TIME:{" "}
          {data?.simple_payment?.estimated_time}
        </p>
        <p>
          REFERENCE:{" "}
          {data?.simple_payment?.reference}
        </p>
        <p>
          REASON: {data?.simple_payment?.reason}
        </p>
        <p>
          PAYMENT MODE:{" "}
          {data?.simple_payment?.payment_mode}
        </p>
      </div>
    </div>
  </div>
</div>;
};
