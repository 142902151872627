import styled from "styled-components";
import { blackColor, redHighlightColor } from "../constants/colors";
import { TextInput } from "./TextInput";
import { StyleProps } from "../constants/StylePropsType";
import helpIcon from "../assets/icons/helpIcon.png";
import Tooltip from "./Tooltip";

interface TextInputWithLabelProps extends StyleProps {
  label: string;
  id?: string;
  className?: string;
  errorMessage?: string;
  inputType?: string;
  pattern?: string;
  maxLength?: number;
  onInput?: any;
  hintIcon?: any;
  tooltipContent?: string;
  mandatory?: boolean;
  tooltipPosition?: any;
  name?: string;
  index?: any;
  maxValue?: any;
  disabled?: boolean;
}

const StyledLabel = styled.label<{
  fontSize?: string;
  fontStyle?: string;
  marginRight?: string;
  marginLeft?: string;
}>`
  display: block;
  margin-bottom: 5px;
  font-size: ${(props) => props.fontSize || "0.85rem"};
  font-style: ${(props) => props.fontStyle};
  color: ${blackColor};
  font-weight: 400;
  min-width: fit-content;
  max-width: 10px;
  height: 15px;
`;
const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({
  maxLength,
  name,
  maxValue,
  mandatory,
  index,
  hintIcon,
  tooltipContent,
  tooltipPosition,
  label,
  onInput,
  inputType,
  pattern,
  id,
  className,
  errorMessage,
  disabled,
  ...props
}) => {
  return (
    <div style={{ display: "block", marginTop: "10px" }} key={index}>
      <div style={{ marginLeft: "1%", display: "flex", alignItems: "center" }}>
        {mandatory ? (
          <>
            <StyledLabel
              htmlFor={id}
              marginLeft={props.marginInline}
              marginRight={props.marginInline}
              fontSize={props.fontSize}
              fontStyle={props.fontStyle}
            >
              {label}
            </StyledLabel>
            <p
              style={{
                color: redHighlightColor,
                fontSize: "30px",
                marginLeft: "10px",
              }}
            >
              *
            </p>
          </>
        ) : (
          <StyledLabel
            htmlFor={id}
            marginLeft={props.marginInline}
            marginRight={props.marginInline}
            fontSize={props.fontSize}
            fontStyle={props.fontStyle}
          >
            {label}
          </StyledLabel>
        )}
        {hintIcon && (
          <Tooltip content={tooltipContent} position={tooltipPosition}>
            <img
              style={{ width: "18px", height: "18px", marginTop: "-15px" }}
              src={hintIcon}
              alt="help-icon"
            />
          </Tooltip>
        )}
      </div>
      <TextInput
        maxValue={maxValue}
        name={name}
        className={className}
        maxLength={maxLength}
        onInput={onInput}
        pattern={pattern}
        inputType={inputType}
        errorMessage={errorMessage}
        id={id}
        marginInline={props.marginInline}
        disabled={disabled}
        {...props}
      />
      <p
        style={{
          marginLeft: props.marginInline,
          marginTop: "5px",
          color: "red",
        }}
      >
        {errorMessage}
      </p>
    </div>
  );
};

export default TextInputWithLabel;
