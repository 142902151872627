import { useForm, SubmitHandler } from "react-hook-form";
import InputWithLabel from "../InputWithLabel";

import { useAppContext } from "../../context/AppContext";

import SpinnerIcon from "../Icons/SpinnerIcon";
import { toast } from "react-toastify";
import { reset_password } from "../../constants/apis";

type ChangePasswordType = {
  old_password: string;
  new_password: string;
  repeat_password: string;
};

export default function ChangePasswordSection() {
  const { jwtToken } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<ChangePasswordType>();

  const onSubmitHandler: SubmitHandler<ChangePasswordType> = async (value) => {
    console.log(value);
    const { old_password, new_password } = value;

    try {
      const res = await fetch(reset_password, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          old_password,
          new_password,
        }),
      });

      const data = await res.json();

      console.log(data);

      if (data.status === "success") {
        toast.success(data?.message);
        reset();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="mb-4">
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <h2 className="font-medium text-xl mb-5">Change Password</h2>

        <div className="flex items-center gap-4">
          <InputWithLabel
            label="Current Password"
            type="password"
            id="old_password"
            placeholder="Enter Here"
            {...register("old_password", {
              required: "Current password is required",
            })}
            error={errors.old_password?.message}
          />
          <InputWithLabel
            label="New Password"
            type="password"
            id="new_password"
            placeholder="Enter Here"
            {...register("new_password", {
              required: "New password is required",
              minLength: {
                value: 4,
                message: "Password must be at least 4 characters",
              },
              maxLength: {
                value: 20,
                message: "Password must be no longer than 20 characters",
              },
            })}
            error={errors.new_password?.message}
          />

          <InputWithLabel
            label="Repeat Password"
            type="password"
            id="repeat_password"
            placeholder="Enter Here"
            {...register("repeat_password", {
              required: "Repeat password is required",
              maxLength: {
                value: 20,
                message: "Password must be no longer than 20 characters",
              },
              validate: (value) =>
                value === watch("new_password") || "Passwords do not match",
            })}
            error={errors.repeat_password?.message}
          />

          <button
            type="submit"
            className="h-[44px] px-4 mt-2 rounded-md bg-custom-blue-medium text-white font-medium"
          >
            {isSubmitting ? (
              <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </section>
  );
}
