import { useEffect } from "react";

import { useAppContext } from "../context/AppContext";

import InputWithLabel from "../components/InputWithLabel";
import { useFormContext } from "react-hook-form";

import Spinner from "../components/Spinner";
import Dropdown from "../components/DropDown";

import {
  Company as PartnerCompany,
  usePartnersDataContext,
} from "../context/PartnersDataContext";

import { FullSimplePaymentSchemaType } from "../components/SimplePayment/SimplePaymentForm";
import Tooltip from "../components/Tooltip";
import { beneficiary } from "../constants/tooltipContents";

export type SenderDetails = {
  sender_id: number;
  company_name: string;
  company_address: string;
  company_registration_number: string;
  contact_person_name: string;
  email: string;
  phone_number: string;
};

export type SenderAccountDetails = {
  sender_account_id: number;
  sender_id: number;
  account_ref_name: string;
  account_holder_name: string;
  bank: string;
  bank_address: string;
  account_no: string;
  swift_ifsc: string;
  currency: string;
};

export type BeneficiaryDetails = {
  beneficiary_id: number;
  company_name: string;
  company_address: string;
  company_registration_number: string;
  contact_person_name: string;
  email: string;
  phone_number: string;
};

export type BeneficiaryAccountDetails = {
  beneficiary_account_id: number;
  beneficiary_id: number;
  account_ref_name: string;
  account_holder_name: string;
  bank: string;
  bank_address: string;
  account_no: string;
  swift_ifsc: string;
  currency: string;
};

type AccountDetail = PartnerCompany["account_details"][number];

export default function SPSenderBeneficiary({
  selectedCompany,
  setSelectedCompany,
  openModal,
}: {
  selectedCompany: PartnerCompany | undefined;
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<PartnerCompany | undefined>
  >;
  openModal: (type: "update" | "add") => void;
}) {
  const { user } = useAppContext();
  const { data: partnersData, loading: partnersDataLoading } =
    usePartnersDataContext();
  const {
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useFormContext<FullSimplePaymentSchemaType>();

  // console.log(partnersData);

  useEffect(() => {
    const filteredData = partnersData
      ?.filter((el) => el.company_name === getValues("company_name"))
      .at(0);
    setSelectedCompany(filteredData);
  }, [partnersDataLoading]);

  const watchSelectedCompany = watch("company_name");
  const watchSelectedBank = watch("account_ref_name");

  useEffect(() => {
    if (!watchSelectedCompany) return;

    const company = partnersData?.find(
      (el) => el.company_name === watchSelectedCompany
    );

    if (!company) return;

    setSelectedCompany(company);
  }, [watchSelectedCompany, partnersData]);

  useEffect(() => {
    if (!watchSelectedBank) return;

    const selectedBank = selectedCompany?.account_details?.find(
      (el) => el.account_ref_name === watchSelectedBank
    );

    if (!selectedBank) {
      return;
    }

    // reset(selectedBank);
    Object.entries(selectedBank).forEach(([key, value]) => {
      setValue(key as keyof AccountDetail, value);
    });
  }, [watchSelectedBank, selectedCompany, setValue]);

  return (
    <section className="relative py-4">
      {partnersDataLoading && <Spinner />}

      <div className="w-[20vw]">
        <InputWithLabel
          label="Sender"
          style={{ fontWeight: "bold" }} // label style
          type="text"
          id="senderCompanyName"
          // {...register("senderCompanyName")}
          defaultValue={user?.company_name || ""}
          disabled
        />
      </div>

      <div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <Dropdown
              label="Beneficiary"
              placeholder="Select a company"
              style={{ fontWeight: "bold" }} // label style
              options={
                partnersData?.map((el) => ({
                  label: el.company_name,
                  value: el.company_name,
                })) || []
              }
              id="beneficiaryCompanyName"
              error={errors.company_name?.message}
              {...register("company_name")}
              // defaultValue={""}
              // selected
            />

            <Dropdown
              label="Account Reference Name"
              placeholder="Select a bank"
              options={
                selectedCompany?.account_details?.map((el) => ({
                  label: el.account_ref_name,
                  value: el.account_ref_name,
                })) || []
              }
              id="beneficiaryAccountRefName"
              error={errors.account_ref_name?.message}
              {...register("account_ref_name")}
              // defaultValue={""}
              // selected
            />

            <div className="pt-5">
              <button
                type="button"
                className="h-[43px] w-[100px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
                onClick={() => openModal("update")}
                disabled={!selectedCompany}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="mb-4">
            <div className="w-[170px] mt-4">
              <hr />
              <p className="w-6 text-gray-500 bg-white mb-2 ml-[70px] -mt-3">
                Or
              </p>
            </div>

            <button
              type="button"
              className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
              onClick={() => openModal("add")}
            >
              Add New Company
            </button>
          </div>

          <div className="flex gap-4">
            <InputWithLabel
              label="Account Holder Name"
              type="text"
              id="beneficiaryAccountHolderName"
              // error={errors.beneficiaryAccountHolderName?.message}
              {...register("account_holder_name")}
              disabled
            />

            <InputWithLabel
              label="Account No. / IBAN"
              type="text"
              id="beneficiaryAccountNoIban"
              placeholder="xxxx-xxxx-8796"
              // error={errors.beneficiaryAccountNoIban?.message}
              {...register("account_no")}
              disabled
            />

            <InputWithLabel
              label="Currency"
              type="text"
              id="beneficiaryCurrency"
              // error={errors.beneficiaryCurrency?.message}
              {...register("currency")}
              disabled
            />
          </div>

          <div className="flex gap-4">
            <InputWithLabel
              label="Bank Name"
              type="text"
              id="beneficiaryBank"
              // error={errors.beneficiaryBank?.message}
              {...register("bank")}
              disabled
            />

            <InputWithLabel
              label="SWIFT / IFSC"
              type="text"
              id="beneficiarySwiftIfscNo"
              // error={errors.beneficiarySwiftIfscNo?.message}
              {...register("swift_ifsc")}
              disabled
            />
          </div>

          <div>
            <label
              htmlFor="beneficiaryBankAddress"
              className="text-[#344054] text-sm font-medium"
            >
              Bank Address
            </label>
            <textarea
              id="beneficiaryBankAddress"
              {...register("bank_address")}
              disabled
              className="w-full"
            ></textarea>
          </div>
        </div>
      </div>
    </section>
  );
}
