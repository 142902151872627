import { fileToBase64, today } from "../../utils";
import { useAppContext } from "../../context/AppContext";
import { common_file_upload } from "../../constants/apis";
import { toast } from "react-toastify";

import DocumentIcon from "../Icons/DocumentIcon";
import DragAndDrop from "../DragAndDrop";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";
import Dropdown from "../DropDown";
import InputWithLabel from "../InputWithLabel";
import CustomDropdown from "./CustomDropdown";
import { businessDetails, businessRegion } from "../../constants/variable";
import { authorised_issuance_country } from "../../constants/tooltipContents";
import Tooltip from "../Tooltip";

const FouderDocumentSection = ({ nestIndex }: { nestIndex: number }) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext<FullRegistrationFormType>();

  const { jwtToken } = useAppContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `founders.${nestIndex}.company_authorisation_documents_details`,
  });

  // console.log(fields);

  const uplodaFile = async (file: File, index: number) => {
    try {
      const base64String = await fileToBase64(file);

      const res = await fetch(common_file_upload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Content_Type: "application/json",
        },
        body: JSON.stringify({
          base64File: base64String,
          folder_name: "user",
        }),
      });

      const data = await res.json();

      // console.log(data);

      if (data.status === "success") {
        // clearErrors(
        //   `founders.${nestIndex}.company_authorisation_documents_details.${index}.file`
        // );
        setValue(
          `founders.${nestIndex}.company_authorisation_documents_details.${index}.document_file_path`,
          data.url as string
        );

        clearErrors(
          `founders.${nestIndex}.company_authorisation_documents_details.${index}.document_file_path`
        );
        // toast.success(data.msg);
      } else {
        toast.error("File upload went wrong please try again");
      }
    } catch (error) {
      console.error("Error converting file to Base64:", error);
      // toast.error("File upload went wrong please try again");
    }
  };

  const handleFileChangeAndUpload = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      await uplodaFile(file, index);
    }
  };

  const handleDropAndUpload = async (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];

    if (file) {
      await uplodaFile(file, index);
    }
  };

  return (
    <div>
      {fields.map((item, idx) => (
        <div key={item.id} className="flex flex-col gap-2">
          <h3
            className={`text-[#344054] font-medium pt-1 ${
              idx !== 0 && "border-t"
            }`}
          >
            Document {idx + 1}
          </h3>

          <div>
            <label className="text-[#344054] text-sm font-medium">
              Company Authorization Document
            </label>

            {getValues()?.founders[nestIndex]
              ?.company_authorisation_documents_details[idx]
              ?.document_file_path &&
              getValues()?.founders[
                nestIndex
              ]?.company_authorisation_documents_details[
                idx
              ]?.document_file_path.startsWith("https:") && (
                <a
                  className="text-sm flex float-end items-center gap-1 text-custom-blue hover:underline"
                  target="_blank"
                  rel="noopener noreferrer" // Important for security
                  href={
                    getValues()?.founders[nestIndex]
                      ?.company_authorisation_documents_details[idx]
                      ?.document_file_path
                  }
                >
                  <DocumentIcon className="size-[14px]" /> View your uploaded
                  document
                </a>
              )}

            <DragAndDrop
              id={`file-input-${nestIndex}-${idx}`}
              onChange={(e) => {
                handleFileChangeAndUpload(e, idx);

                toast.promise(handleFileChangeAndUpload(e, idx), {
                  pending: "File is uploading",
                  success: "File has been uploaded 👌",
                  error: "Rejected, please try again 🤯",
                });
              }}
              onDrop={(e) => {
                handleDropAndUpload(e, idx);

                toast.promise(handleDropAndUpload(e, idx), {
                  pending: "File is uploading",
                  success: "File has been uploaded 👌",
                  error: "Rejected, please try again 🤯",
                });
              }}
            />

            {/* Display file validation/error messages */}
            <small className="text-red-500 h-[10px] block ml-1">
              {
                // errors.founders?.[nestIndex]
                //   ?.company_authorisation_documents_details?.[idx]?.file
                //   ?.message ||
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.document_file_path?.message
              }
            </small>
          </div>

          <div className="flex gap-6">
            {/* <Dropdown
              options={
                DocumentTypeFetch?.data?.map((el) => ({
                  label: el.description,
                  value: el.description,
                })) || []
              }
              label="Document Type"
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.document_type?.message
              }
              {...register(
                `founders.${nestIndex}.company_authorisation_documents_details.${idx}.document_type`
              )}
            /> */}

            <CustomDropdown
              label="Document Type"
              placeholder="Select document type"
              name={`founders.${nestIndex}.company_authorisation_documents_details.${idx}.document_type`}
              category={businessDetails.DOCUMENT_TYPE}
              region={businessRegion}
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.document_type?.message
              }
            />

            <InputWithLabel
              label="Document Number"
              
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.document_number?.message
              }
              {...register(
                `founders.${nestIndex}.company_authorisation_documents_details.${idx}.document_number`
              )}
            />
          </div>

          <div className="flex gap-6">
            {/* <Dropdown
              label="Issuance Country"
              options={
                CountryNameFetch?.data?.map((el) => ({
                  label: el.description,
                  value: el.description,
                })) || []
              }
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.issuance_country?.message
              }
              {...register(
                `founders.${nestIndex}.company_authorisation_documents_details.${idx}.issuance_country`
              )}
            /> */}

            <CustomDropdown
              label="Issuance Country"

              placeholder="Select issuance country"
              name={`founders.${nestIndex}.company_authorisation_documents_details.${idx}.issuance_country`}
              category={businessDetails.COUNTRY_NAME}
              region={businessRegion}
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]
                  ?.issuance_country?.message
              }
            />

            <InputWithLabel
              type="date"
              label="Expiry Date"
              error={
                errors.founders?.[nestIndex]
                  ?.company_authorisation_documents_details?.[idx]?.expiry_date
                  ?.message
              }
              {...register(
                `founders.${nestIndex}.company_authorisation_documents_details.${idx}.expiry_date`
              )}
              min={today}
            />
          </div>

          <div className="mb-5">
            {idx !== 0 && (
              <button
                type="button"
                className="text-red-500 hover:underline"
                onClick={() => remove(idx)}
              >
                remove
              </button>
            )}
          </div>
        </div>
      ))}

      <div>
        <button
          type="button"
          className="h-[44px] px-2 rounded-md bg-custom-blue-medium text-white font-medium"
          onClick={() => {
            append({
              document_type: "",
              document_number: "",
              issuance_country: "",
              expiry_date: "",
              document_file_path: "",
              // file: "",
            });
          }}
        >
          Add A New Document
        </button>
      </div>
    </div>
  );
};

export default FouderDocumentSection;
