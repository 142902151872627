import { z } from "zod";

export const SEDetailsSCPFormSchema = z.object({
  amount_sent: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z
      .number({ message: "Expect a number" })
      .min(1, "Enter a minimun amount")
      .max(10000)
  ),
  amount_received: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z
      .number({ message: "Expect a number" })
      .min(1, "Enter a minimun amount")
      .max(10000)
  ),
  fees: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z
      .number({ message: "Expect a number" })
      .min(1, "Enter a minimun amount")
      .max(10000)
  ),
  // estimatedTime: z.string().date(),
  reference: z.string().min(1, {
    message: "Reference is required",
  }),
  payment_mode: z.string().min(1, {
    message: "Payment mode is required",
  }),
  // reason: z.string().min(1, {
  //   message: "Reason is required",
  // }),
  amount_sent_currency: z.string().min(1, {
    message: "Currency is required",
  }),
  amount_received_currency: z.string().min(1, {
    message: "Currency is required",
  }),
  request_fx_hedging: z.boolean({ message: "Filed is required" }).optional(),
  fees_payed_by: z.string().min(1, {
    message: "Fees payed by is required",
  }),
});

export type SEDetailsSCPFormType = z.infer<typeof SEDetailsSCPFormSchema>;

export const SEDetailsFCPFormSchema = z.object({
  // condition_based_payment: z.boolean(),
  // current_market_rate: z.string().trim().min(1, {
  //   message: "Field is required",
  // }),
  transation_type: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  issue_date: z
    .string({ message: "Field is required" })
    .date("Enter a valid date"),
  expiry_date: z
    .string({ message: "Field is required" })
    .date("Enter a valid date"),
  expiry_place: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  latest_shipment: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  shipment_period: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  transation_amount: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  transaction_pi_currency: z
    .string({ message: "Field is required" })
    .trim()
    .min(1, {
      message: "Field is required",
    }),
  transaction_po_tolerance: z
    .string({ message: "Field is required" })
    .trim()
    .min(1, {
      message: "Field is required",
    }),
  exchange_rate: z.string({ message: "Field is required" }).optional(),
  // .min(1, {
  //   message: "Field is required",
  // }),
  transaction_amt_tolerance: z
    .string({ message: "Field is required" })
    .trim()
    .min(1, {
      message: "Field is required",
    }),
  drawing: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  drawing_term: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  partial_shipments: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  trans_shipment: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  transport_from: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  airport_og_loading: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  airport_of_discharge: z
    .string({ message: "Field is required" })
    .trim()
    .min(1, {
      message: "Field is required",
    }),
  transport_to: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  incoterms: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  description_of_delivery: z
    .string({ message: "Field is required" })
    .trim()
    .min(1, {
      message: "Field is required",
    }),
  industry: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  note: z.string({ message: "Field is required" }).trim().min(1, {
    message: "Field is required",
  }),
  payment_mode: z.string({ message: "Field is required" }).min(1, {
    message: "Payment mode is required",
  }),
  request_fx_hedging: z.boolean({ message: "Filed is required" }).optional(),
  fees_payed_by: z.string({ message: "Field is required" }).min(1, {
    message: "Fees payed by is required",
  }),
});

export type SEDetailsFCPFormType = z.infer<typeof SEDetailsFCPFormSchema>;
