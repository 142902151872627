import { Divider } from "../components/Divider";
import { VerticalDivider } from "../components/VerticalDivider";
import {
  blackColor,
  blueColor,
  offWhiteColor,
  strokeColor,
  whiteColor,
} from "../constants/colors";
import mapIcon from "../assets/icons/map.png";
import mapTrack from "../assets/icons/mapTrack.png";
import {
  TrackingSteps,
  VerticalDividerWithCircle,
} from "../components/TrackingSteps";
import Button from "../components/Button";
import leftArrow from "../assets/icons/arrow-left.png";
import { useEffect, useState } from "react";
import { AltertBox } from "../components/AltertBox";
import checkIcon from "../assets/icons/check.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemFromLocalStore } from "../utils";

export type RouteDetails = {
  originalPrice: number;
  originalCurrency: string;
  price: number;
  comment: null;
  distance: string;
  transitTime: string;
  interpolation: boolean;
  co2: number;
};

export type Quote = {
  shipment_id: string;
  from_city: string;
  to_city: string;
  name: string;
  logo: string;
  co2: number;
  cost: number;
  days: string;
  valid: string;
  route_info: {
    truckFrom: RouteDetails;
    truckTo: RouteDetails;
    railFrom: RouteDetails;
    railTo: RouteDetails;
    portFrom: {
      id: number;
      name: string;
      code: string;
      countryCode: string;
      lat: number;
      lng: number;
    };
    portTo: {
      id: number;
      name: string;
      code: string;
      countryCode: string;
      lat: number;
      lng: number;
    };
  };
};

const getNumberFromString = (str: string) => parseInt(str?.split(" ").at(0)!);

export const LSummary = () => {
  const [quote] = useState<Quote>(getItemFromLocalStore("quote-details"));

  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();

  function handleSubmit() {
    setPopup(!popup);
  }
  return (
    <div className="p-5">
      <div>
        <div>
          <img src={quote?.logo} alt={quote?.name} className="w-16" />
          <p className="text-custom-blue">{quote?.name}</p>
        </div>

        <div className="my-5 flex justify-between items-center">
          <div>
            <h3 className="font-medium">FROM: {quote?.from_city}</h3>
          </div>

          <div className="flex items-center gap-10">
            <h3 className="font-medium">TO: {quote?.to_city}</h3>
            <small className="bg-blue-100 py-1 px-2 rounded-2xl border border-custom-semi-light-blue">
              ETA: {quote?.days}
            </small>
            <small className="bg-blue-100 py-1 px-2 rounded-2xl border border-custom-semi-light-blue">
              PRICE: ${quote?.cost}
            </small>
          </div>
        </div>
      </div>

      <hr className="border-t-2 text-gray-500 mb-4" />

      <div className="flex pl-10">
        <div className="w-full">
          <h2 className="text-lg font-medium">RATE BREAKDOWN</h2>

          <div>
            <p>{quote?.from_city}</p>
            <VerticalDividerWithCircle />
          </div>

          <TrackingSteps
            location={`${quote?.route_info?.portFrom.name}, ${quote?.route_info?.portFrom.countryCode}`}
            truck={quote?.route_info.truckFrom}
            rail={quote?.route_info.railFrom}
            position="top"
          />

          <div style={{ position: "relative" }}>
            <div className="flex items-center absolute left-3 top-3">
              <div className="w-[100px] border border-dashed border-gray-400" />

              <div className="w-[250px] bg-white p-2 rounded-lg">
                <div className="flex justify-between text-sm">
                  <p className="text-custom-blue">Sea FCL</p>
                  <p>
                    {getNumberFromString(quote?.days) -
                      (getNumberFromString(
                        quote?.route_info.truckFrom.transitTime
                      ) +
                        getNumberFromString(
                          quote?.route_info.truckTo.transitTime
                        ))}{" "}
                    days
                  </p>
                </div>
                <div className="flex justify-between text-sm">
                  <p className="text-custom-blue">RF Road freight</p>
                  <p>
                    USD{" "}
                    {quote?.cost -
                      (quote?.route_info.truckFrom.price +
                        quote?.route_info.truckTo.price)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <VerticalDividerWithCircle />

          <TrackingSteps
            location={`${quote?.route_info?.portTo.name}, ${quote?.route_info?.portTo.countryCode}`}
            truck={quote?.route_info.truckTo}
            rail={quote?.route_info.railTo}
            position="bottom"
          />

          <div>
            <div className="h-5 w-5 border-2 border-gray-400 rounded-full" />

            <p>{quote?.to_city}</p>
          </div>
        </div>

        <VerticalDivider
          dividerHeight={"100vh"}
          dividerWidth={"2px"}
          dividerBackgroundColor={"#2586FF"}
        />

        <div className="w-full">
          <div>
            <img
              style={{
                width: "450px",
                height: "400px",
                marginInline: "10%",
                objectFit: "contain",
              }}
              src={mapIcon}
              alt="map"
            />
          </div>
          <div
            style={{
              width: "550px",
              height: "400px",
              top: 420,
              position: "absolute",
              zIndex: 2,
            }}
          >
            <img
              style={{
                width: "450px",
                height: "400px",
                marginLeft: "18%",
                marginInline: "10%",
                objectFit: "contain",
              }}
              src={mapTrack}
              alt="maptrack"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="my-10 flex justify-around">
          <button
            type="button"
            className="w-32 h-14 p-1 border border-custom-blue rounded flex justify-center items-center gap-2 text-custom-blue"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} alt="left arrow" />
            <span>Back</span>
          </button>

          <button
            className="w-32 h-14 p-1 bg-custom-blue text-white rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>

        {popup && (
          <AltertBox
            iconSource={checkIcon}
            alertText="Successfully Submitted"
            alertText1="You will recieve confirmation from the shipper in 2 business days and task to make payment after confirmation"
            alertText2=""
            buttonTitle="Go to next"
            buttonWidth="100%"
            buttonHeight="56px"
            handleClick={() => navigate("/quality-assurance")}
          />
        )}
      </div>
    </div>
  );
};
