import { z } from "zod";

export const InsuranceFormSchema = z.object({
  // insurance_id: z.string().optional(),
  category_of_goods: z
    .string()
    .min(1, { message: "Category of goods is required" }),
  description_of_goods: z
    .string()
    .min(1, { message: "Description of goods is required" }),
  volume_of_goods: z
    .string()
    .min(1, { message: "Volume of goods is required" }),
  // value_of_goods: z.string().min(1, { message: "Value of goods is required" }),
  invoice_amount: z
  .string()
  .min(1, { message: "Invoice amount is required" }),
  place_of_origin: z
    .string()
    .min(1, { message: "Volume of goods is required" }),
  place_of_destination: z
    .string()
    .min(1, { message: "Place of destination is required" }),
  type_of_insurance_cover: z
    .string()
    .min(1, { message: "Type of insurance cover is required" }),
  valid_from: z.string().min(1, { message: "Valid from date is required" }),
  valid_to: z.string().min(1, { message: "Valid to date is required" }),
});

export type InsuranceFormType = z.infer<typeof InsuranceFormSchema>;
