import React, { useState } from "react";
import { dropdownData } from "../constants/variable";
import {
  blackColor,
  blueColor,
  greenHighlightColor,
  offWhiteColor,
  strokeColor,
  whiteColor,
} from "../constants/colors";
import Button from "./Button";
import { FormValuesType } from "../pages/LCargoDetails";

const shippingTypeOptions = ["SEA", "LAND", "AIR", "AUTO"];

const transportationByOptions = [
  { label: "Full container load FCL", value: "FCL" },
  { label: "Less container load FCL", value: "LCL" },
  { label: "BULK", value: "BULK" },
];

const containerSizeOptions = [
  { label: "20` Standard", value: "ST20" },
  { label: "40` Standard", value: "ST40" },
];

const Dropdowns = ({
  setFormValues,
  onToggle,
}: {
  onToggle: () => void;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [shippingType, setShippingType] = useState("SEA");
  const [transportationBy, setTransportationBy] = useState("FCL");
  const [containerSize, setContainerSize] = useState("ST20");
  const [truckType, setTruckType] = useState<string>("");
  const [mediumType, setMediumType] = useState("");
  const [isMedium, setIsMedium] = useState(false);

  const [activeTab, setActiveTab] = useState("tab1");

  const handleShippingTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setShippingType(e.target.value);
    setTransportationBy("");
    setTruckType("");
  };

  const handleTransportationByChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTransportationBy(e.target.value);
    setTruckType("");
  };

  const getTransportationOptions = (): string[] => {
    if (shippingType === "land") {
      return Object.keys(dropdownData.land.transportationBy);
    }
    return [];
  };

  const getTypeOptions = (): string[] => {
    if (shippingType === "land" && transportationBy) {
      return Object.keys(dropdownData.land.transportationBy[transportationBy]);
    }
    return [];
  };

  const getTruckOptions = (): string[] => {
    if (shippingType === "land" && transportationBy && truckType) {
      return dropdownData.land.transportationBy[transportationBy][truckType];
    }
    return [];
  };
  const getTransportMedium = (medium: string) => {
    if (mediumType === medium) {
      const result = setIsMedium(true);
      return result;
    } else {
      return false;
    }
  };

  console.log(activeTab);

  return (
    <div className="w-[100%] bg-white border-2 px-3 py-5 mt-1 rounded-lg shadow-custom flex flex-col gap-4 z-10">
      {/* Shipping Type Dropdown */}
      <div>
        <label className="font-semibold uppercase">Shipping Type</label>
        <div className="flex p-1 shadow-custom rounded-lg">
          {shippingTypeOptions.map((el, i) => (
            <div key={el} className="w-full text-center">
              <button
                type="button"
                className="p-1 cursor-pointer font-semibold"
                onClick={() => {
                  setActiveTab(`tab${i + 1}`);
                  setShippingType(el);
                }}
              >
                {el}
              </button>
              <div
                className={`h-1 rounded-2xl transition-colors duration-200 ease-in-out ${
                  activeTab === `tab${i + 1}`
                    ? "border-b-4 border-custom-blue"
                    : ""
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {/* Transportation By Dropdown */}
      {/* {shippingType === 'land' && ( */}
      <div className="flex flex-col">
        <label className="font-semibold uppercase">Transportation By</label>
        <select
          className="h-12 p-1 border rounded-lg"
          value={transportationBy}
          onChange={handleTransportationByChange}
        >
          {/* <option value="">Select Transportation</option> */}
          {transportationByOptions.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {/* )} */}

      {/* Type Dropdown */}
      {/* {transportationBy && ( */}
      <div className="flex flex-col">
        <label className="font-semibold uppercase">Type</label>
        <select
          className="h-12 p-1 border rounded-lg"
          value={containerSize}
          onChange={(e) => setContainerSize(e.target.value)}
        >
          {/* <option value="">Select Type</option> */}
          {containerSizeOptions.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {/* )} */}

      {/* Truck Type Dropdown */}
      {truckType ||
        (shippingType === "land" && (
          <div>
            <label>Truck Type</label>
            <select
              style={{
                border: `1px solid ${blackColor}`,
                borderWidth: "1px",
                height: "51px",
                padding: "10px",
                color: blackColor,
                borderColor: strokeColor,
                borderRadius: "4px",
              }}
              value={transportationBy}
              onChange={handleTransportationByChange}
            >
              <option value="">Select Truck Type</option>
              {getTruckOptions().map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        ))}
      <Button
        type="button"
        buttonTitle="Select"
        width="100%"
        height="50px"
        style={{ marginTop: "20px" }}
        titleStyle={{ textAlign: "center", borderRadius: "4px" }}
        borderRadius="5px"
        onClick={() => {
          setFormValues((prev: FormValuesType) => ({
            ...prev,
            shipppingType: {
              container_size: containerSize,
              freight_type: shippingType,
              transportation_by: transportationBy,
            },
          }));
          onToggle();
        }}
      />
    </div>
  );
};

export default Dropdowns;
