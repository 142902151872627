import React from 'react'
import { blackColor, blueColor, offWhiteColor, whiteColor } from '../constants/colors'
import Button from '../components/Button'

const DashboardReviewSubmit = () => {
  return (
<div style={{backgroundColor:offWhiteColor}}>

   <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>Payment Service</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor}}>
        <p>STATUS</p>
        <p>Money transferred to ESCROW account</p>
     </div>
     <Button width='200px' buttonTitle='DETAILS' titleStyle={{textAlign:'center'}} borderRadius='4px'/>
     </div>

     <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>Invoice Factoring</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor}}>
        <p>STATUS</p>
        <p>$20000 deposited to customer account</p>
     </div>
     <Button width='200px' buttonTitle='DETAILS' titleStyle={{textAlign:'center'}} borderRadius='4px'/>
     </div>

     <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>Logistics</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor}}>
        <p>STATUS</p>
        <p>Awating goods to from factory</p>
     </div>
     <Button width='200px' buttonTitle='DETAILS' titleStyle={{textAlign:'center'}} borderRadius='4px'/>
     </div>

     <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>Quality Assurance-Vendor Audit</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor}}>
        <p>STATUS</p>
        <p>Agent in factory location performing vendor audit</p>
     </div>
     <Button width='200px' buttonTitle='DETAILS' titleStyle={{textAlign:'center'}} borderRadius='4px'/>
     </div>

     <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>Insurance</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor}}>
        <p>STATUS</p>
        <p>Approved</p>
     </div>
     <Button width='200px' buttonTitle='DETAILS' titleStyle={{textAlign:'center'}} borderRadius='4px'/>
     </div>

     <p style={{color:blackColor,marginLeft:'5%', fontSize:'18px'}}>SOROBAN Smart Contract Details</p>
   <div style={{width:'90%', height:'96px',marginBlock:'1%',marginInline:'auto',paddingInline:'1%',backgroundColor:whiteColor,display:'flex',alignItems:'center',paddingBlock:'15px',justifyContent:'center'}}>
     <p style={{color:blueColor,width:'50%'}}>Taram</p>
     <div style={{color:blackColor, display:'flex'}}>
        <p style={{width:'50%'}}>STATUS:
        Awaiting sequential execution of service per smart contract</p>
     </div>
     <div style={{backgroundColor:'green', textAlign:'center', alignItems:'center', width:'282px',height:'61px'}}>
     <p style={{width:'282px', lineHeight:'61px', textAlign:'center'}}>DEPLOYED ON CHAIN</p>
     </div> 
     </div>



</div>
  )
}

export default DashboardReviewSubmit
