export default function TaskIcon({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={className}
    >
      <path
        d="M7.99809 16H11.9981M7.99809 11H15.9981M7.50009 3.5C5.94409 3.547 5.01709 3.72 4.37509 4.362C3.49609 5.242 3.49609 6.657 3.49609 9.488V15.994C3.49609 18.826 3.49609 20.241 4.37509 21.121C5.25309 22 6.66809 22 9.49609 22H14.4961C17.3251 22 18.7391 22 19.6171 21.12C20.4971 20.241 20.4971 18.826 20.4971 15.994V9.488C20.4971 6.658 20.4971 5.242 19.6171 4.362C18.9761 3.72 18.0481 3.547 16.4921 3.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49609 3.75C7.49609 2.784 8.28009 2 9.24609 2H14.7461C15.2102 2 15.6553 2.18437 15.9835 2.51256C16.3117 2.84075 16.4961 3.28587 16.4961 3.75C16.4961 4.21413 16.3117 4.65925 15.9835 4.98744C15.6553 5.31563 15.2102 5.5 14.7461 5.5H9.24609C8.78197 5.5 8.33685 5.31563 8.00866 4.98744C7.68047 4.65925 7.49609 4.21413 7.49609 3.75Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
