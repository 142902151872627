import { z } from "zod";

export const SEConditionsFormSchema = z.object({
  conditions: z.array(
    z.object({
      percent_of_money: z.preprocess(
        (arg) => (typeof arg === "string" ? Number(arg) : arg),
        z
          .number({ message: "Expect a number" })
          .min(1, "Enter amount")
          .max(100, "Can't be more than 100%")
          .int({ message: "Enter a integer value" })
      ),
      document_approval: z.array(
        z.object({
          approval_of_document: z
            .string()
            .min(1, "Approval Document is required"),
          doument_file: z
            .string()
            .min(1, "Document is required")
            .includes("https://", { message: "Please select a document" }),

          file: z
            .instanceof(FileList, { message: "Please select a document" })
            .optional(),
        })
      ),
      condition_approval: z.array(
        z.object({
          condition: z.string().min(1, "Condition is required"),
          approval_of_document: z
            .string()
            .min(1, "Condition approval is required"),
        })
      ),
    })
  ),
});

export type SEConditionsFormType = z.infer<typeof SEConditionsFormSchema>;
