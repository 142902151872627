import { useFormContext } from "react-hook-form";

import InputWithLabel from "../components/InputWithLabel";
import Dropdown from "../components/DropDown";

import { TWO_PERCENT } from "../constants/variable";
import Tooltip from "../components/Tooltip";
import { FullSimplePaymentSchemaType } from "../components/SimplePayment/SimplePaymentForm";

export const currencyOptions = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "EURO",
    value: "EURO",
  },
];

export const paymentModeOptions = [
  {
    label: "Local Payment (Instant)",
    value: "Local Payment (Instant)",
  },
  {
    label: "SWIFT Payment (Slower)",
    value: "SWIFT Payment (Slower)",
  },
];
export const paidFromOptions = [
  {
    label: "External Account",
    value: "External Account",
  },
  {
    label: "Account Balance",
    value: "Account Balance",
  },
  {
    label: "Both Combined",
    value: "Both Combined",
  },
];

const reasonOptions = [
  { label: "Reason 1", value: "Reason 1" },
  { label: "Reason 2", value: "Reason 2" },
  { label: "Reason 3", value: "Reason 3" },
  { label: "Reason 4", value: "Reason 4" },
];

const tooltipContent =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum ipsam officiis labore, quasi dolores maxime veritatis, quaerat, minus beatae aspernatur illo aliquid animi pariatur temporibus. Reiciendis repudiandae suscipit corrupti repellendus.";

export const SPDetails = () => {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<FullSimplePaymentSchemaType>();

  return (
    <section className="relative space-y-2">
      {/* {loading && (
        <div className="backdrop-blur-sm rounded-lg absolute top-0 left-0 bottom-0 right-0 z-30" />
      )} */}

      <div className="flex justify-between items-center gap-10">
        <InputWithLabel
          label={"Amount Sent"}
          // element={
          //   <Dropdown
          //     label=""
          //     options={currencyOptions}
          //     className="currency-dropdown bg-custom-off-white"
          //     style={{
          //       width: "50px",
          //     }}
          //     {...register("amountSentCurrency")}
          //   />
          // }
          id={"amountSent"}
          type="number"
          {...register("amount_sent")}
          error={errors.amount_sent?.message}
          onChange={(e) => {
            let fees = Number(
              (TWO_PERCENT * parseInt(e.currentTarget.value)).toFixed(2)
            );
            let amountRecieved = Number(
              (parseInt(e.currentTarget.value) - fees).toFixed(2)
            );
            setValue("fees", fees);
            setValue("amount_received", amountRecieved);
            clearErrors("amount_sent");
          }}
        />
        <InputWithLabel
          label={"Amount Recieved"}
          id={"amountRecieved"}
          type="number"
          // element={
          //   <Dropdown
          //     label=""
          //     options={currencyOptions}
          //     className="currency-dropdown"
          //     style={{
          //       width: "50px",
          //     }}
          //     {...register("amountRecievedCurrency")}
          //   />
          // }
          {...register("amount_received")}
          // error={errors.amountRecieved?.message}
          disabled
        />

        <InputWithLabel
          label="Conversion Rate"
          id="spamountRecieved2"
          placeholder="1 USD = 0.98 EURO"
          disabled
        />
      </div>
      <div className="flex justify-between items-center gap-10">
        <InputWithLabel
          label={"FEES"}
          type="number"
          id={"fees"}
          {...register("fees")}
          // error={errors.fees?.message}
          disabled
        />
        <InputWithLabel
          label={"Estimated Time"}
          type="date"
          id={"estimatedTime"}
          placeholder="By Monday, 12:04 PM"
          {...register("estimated_time")}
          error={errors.estimated_time?.message}
        />
        <InputWithLabel
          label={"Reference"}
          type="text"
          id={"reference"}
          placeholder="Reference description"
          {...register("reference")}
          error={errors.reference?.message}
        />
      </div>

      <div className="flex justify-between items-center gap-10">
        <Dropdown
          label="Reason"
          element={<Tooltip content={tooltipContent} />}
          options={reasonOptions}
          id={"reason"}
          placeholder="Select a reason"
          {...register("reason")}
          error={errors.reason?.message}
        />
        <Dropdown
          label="Paid From"
          element={<Tooltip content={tooltipContent} />}
          options={paidFromOptions}
          id={"paidFrom"}
          placeholder="Select a paid from"
          {...register("paid_from")}
          error={errors.paid_from?.message}
        />

        <Dropdown
          label="Choose Payment Mode"
          element={<Tooltip content={tooltipContent} />}
          options={paymentModeOptions}
          id={"paymentMode"}
          placeholder="Select a payment mode"
          {...register("payment_mode")}
          error={errors.payment_mode?.message}
        />
      </div>
    </section>
  );
};
