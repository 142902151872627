import { useAppContext } from "../context/AppContext";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";

import {
  get_all_quality_assurance_details,
  get_beneficary_details,
  get_quality_assurance,
} from "../constants/apis";

import Spinner from "../components/Spinner";
import checkIcon from "../assets/icons/check.png";

import { Key, useEffect, useState } from "react";
import { QualityAssuranceType } from "../schemas/QuallityAssuranceType";
import { AltertBox } from "../components/AltertBox";
import { useFormContext } from "react-hook-form";
import { SupplierType } from "../schemas/SupplierType";

export const QualityAssuranceSummary = () => {
  const { getValues } = useFormContext<QualityAssuranceType>();
  
    const values = getValues();
    console.log('values',values);
  // if (checked == false) {
    return (
      <section className="px-4">
        <div className="card !bg-custom-off-white">
          <h3 className="font-semibold mb-5">Supplier Information</h3>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">Supplier Company Name</p>
            <p className="font-medium text-md">{values?.company_name}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">
              Company Regsitration Number
            </p>
            <p className="font-medium text-md">{values?.reg_no}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">Address of Supplier</p>
            <p className="font-medium text-md">{values?.company_address}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">
              Company Contact Person Name
            </p>
            <p className="font-medium text-md">
              {values?.contact_person_name}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">Company Email</p>
            <p className="font-medium text-md">{values?.email}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#777B8B]">Company Phone number</p>
            <p className="font-medium text-md">{values?.phone_number}</p>
          </div>
        </div>
        {/* Supplier Audit Data is true... */}

        {values?.supplier_audit === true && (
          <div className="card !bg-custom-off-white my-5">
            <h3 className="font-semibold mb-5">Supplier Audit Information</h3>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#777B8B]">Audit Specifications</p>
              <p className="font-medium text-md">
                {values?.audit_specification}
              </p>
            </div>
          </div>
        )}

        {values?.quality_assurance === true && (
          <div className="card !bg-custom-off-white">
            <h3 className="font-semibold mb-5">
              Quality Assurance Information
            </h3>

            <div className="">
              <p className="font-medium text-md">
                {values?.quality_specifications_file.map(
                  (data: { url: string | undefined; }, index: number) => (
                    <div key={index} className="flex justify-between my-4">
                      <p className="text-sm text-[#777B8B]">
                        {`Quality Specifications File ${index + 1}`}
                      </p>
                      <a
                        href={data?.url}
                        className="font-medium text-md hover:underline hover:text-custom-blue"
                      >
                        View File
                      </a>
                    </div>
                  )
                )}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#777B8B]">Consignment Value</p>
              <p className="font-medium text-md">
                {values?.qa_consignment_value}
              </p>
            </div>
          </div>
        )}

        {values?.loading_inspection === true && (
          <div className="card !bg-custom-off-white my-5">
            <h3 className="font-semibold mb-5">
              Loading Inspection Information
            </h3>

            <div className="">
              <div className="flex justify-between my-4">
                <p className="text-sm text-[#777B8B]">Number of Containers</p>
                <p className="font-medium text-md">
                  {values?.audit_specification}
                </p>
              </div>
              <div className="flex justify-between my-4">
                <p className="text-sm text-[#777B8B]">Consignment Value</p>
                <p className="font-medium text-md">
                  {values?.loading_consignment_value}
                </p>
              </div>
              <div className="flex justify-between my-4">
                <p className="text-sm text-[#777B8B]">Goods Specifications</p>
                <p className="font-medium text-md">
                  {values?.goods_specifications}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* {isProcessed && (
          <AltertBox
            iconSource={checkIcon}
            alertText="Successfully Submitted"
            alertText1="Quotes will be processed within 2 business days and result will appear in dashboard."
            alertText2="Thank you for your patience."
            buttonTitle="Go to Dashboard"
            handleClick={() => navigate("/dashboard")}
          />
        )} */}
      </section>
    );
  }
// };
