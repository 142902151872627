import Financing from "../components/DashboardInsights/Financing";
import { ArrowIcon } from "../components/Icons/ArrowIcon";

export default function DashboardInsights() {
  return (
    <section className="space-y-6">
      {/* Accounting */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>Accounting</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">Accounting</div>
      </details>

      {/* Financing */}
      <Financing />

      {/* Quotes */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>Quotes</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">Quotes</div>
      </details>

      {/* Tasks */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>Tasks</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">Tasks</div>
      </details>

      {/* History */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>History</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">History</div>
      </details>

      {/* Companies */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>Companies</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">Companies</div>
      </details>

      {/* Messages */}
      <details className="bg-white rounded-xl group border border-[#f3f3f5]">
        <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
          <span>Messages</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">Messages</div>
      </details>
    </section>
  );
}
