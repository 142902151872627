export default function MessageIcon({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={className}
    >
      <path
        d="M21.749 7.50697V14.114C21.7493 14.6346 21.647 15.1503 21.4479 15.6314C21.2488 16.1125 20.9568 16.5497 20.5887 16.9179C20.2205 17.2861 19.7834 17.5782 19.3024 17.7774C18.8213 17.9766 18.3057 18.0791 17.785 18.079H15.142L12.962 20.259C12.9026 20.3219 12.8309 20.3721 12.7514 20.4064C12.6719 20.4407 12.5862 20.4584 12.4995 20.4584C12.4129 20.4584 12.3272 20.4407 12.2477 20.4064C12.1682 20.3721 12.0965 20.3219 12.037 20.259L9.85705 18.079H7.21205C6.6914 18.079 6.17586 17.9764 5.69486 17.7771C5.21386 17.5779 4.77683 17.2858 4.40872 16.9176C4.04062 16.5494 3.74865 16.1123 3.5495 15.6312C3.35035 15.1502 3.24792 14.6346 3.24805 14.114V7.50697C3.24805 6.45565 3.66568 5.44739 4.40908 4.704C5.15247 3.9606 6.16073 3.54297 7.21205 3.54297H17.784C18.8354 3.54297 19.8436 3.9606 20.587 4.704C21.3304 5.44739 21.749 6.45565 21.749 7.50697Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
