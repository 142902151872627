import { useState } from "react";

import {
  simple_user_registeration,
  user_login,
  verify_user,
} from "../../constants/apis";
import { OtpPopup } from "../../components/OtpPopup";
// import { AltertBox } from "../../components/AltertBox";

// import errorCheckIcon from "../../assets/icons/ErrorCheck.png";
import { useAppContext } from "../../context/AppContext";

import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SimpleRegistrationLoginSchema,
  SimpleRegistrationLoginType,
  SimpleRegistrationSignupSchema,
  SimpleRegistrationSignupType,
} from "../../schemas/SimpleRegistrationFormSchema";
import InputWithLabel from "../../components/InputWithLabel";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../DropDown";
import { PHONE_NO_COUNTRY_CODE } from "../../constants/variable";

export const LoginForm = () => {
  const [isLoginFail, setIsLoginFail] = useState(false);
  const [step, setStep] = useState<"login" | "twoFactor">("login");

  const { setJwtToken, toggleModal } = useAppContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<SimpleRegistrationLoginType>({
    resolver: zodResolver(SimpleRegistrationLoginSchema),
  });

  const email = getValues("email");

  const handleChange = () => {
    setIsLoginFail(false);
  };

  const onHandleSubmit: SubmitHandler<SimpleRegistrationLoginType> = async (
    values
  ) => {
    try {
      const response = await fetch(user_login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();

      // console.log(data);
      if (data.status === "success") {
        if (data.two_fa) {
          setStep("twoFactor");
        } else {
          setJwtToken(data.jwt);
          toast.success("Login Successfully");
          toggleModal();
          navigate("/dashboard");
        }
      } else {
        setIsLoginFail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-2 flex justify-center items-center">
      {step === "login" ? (
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className="w-[384px] space-y-2"
        >
          <h2 className="text-[#1A1D21] text-3xl text-center font-bold mb-3">
            Log In
          </h2>

          <InputWithLabel
            label="Email"
            id="email"
            {...register("email", { onChange: handleChange })}
            error={errors.email?.message}
          />
          <InputWithLabel
            label="Password"
            id="password"
            type="password"
            {...register("password", { onChange: handleChange })}
            error={errors.password?.message}
          />

          <div className="h-[28px]">
            {isLoginFail && (
              <small className="bg-red-50 text-red-400 border border-red-400 p-[2px] rounded-md flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>

                <span>Wrong Credentials</span>
              </small>
            )}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full p-4 bg-custom-blue text-white rounded font-bold"
              disabled={isSubmitting}
            >
              {isSubmitting ? "submitting..." : "Log In"}
            </button>
          </div>

          <div className="text-center">
            <Link
              to={"/forgot-password"}
              className="text-sm text-custom-blue-new hover:underline"
            >
              Forgot password?
            </Link>
          </div>
        </form>
      ) : (
        <TwoFALogin email={email} />
      )}
    </div>
  );
};

export const SignupForm = () => {
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  // const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<SimpleRegistrationSignupType>({
    resolver: zodResolver(SimpleRegistrationSignupSchema),
  });

  const onHandleSubmit: SubmitHandler<SimpleRegistrationSignupType> = async (
    values
  ) => {
    // console.log(values);

    try {
      const response = await fetch(simple_user_registeration, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: values.firstName,
          // middle_name: values.middleName,
          last_name: values.lastName,
          company_name: values.companyName,
          // job_title: values.jobTitle,
          work_email: values.workEmail,
          company_reg_country_code: values.countryCode,
          company_phone_number: values.businessPhone,
          company_reg_number: values.companyRegNumber,
          // company_reg_country: values.countryRegion,
          // company_reg_address: values.companyRegAddress,
          // city: values.city,
          // contact_name: values.contactName,
          pwd: values.password,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      // console.log(data);
      if (data.status === "success") {
        setIsAccountCreated(true);
        toast.success(data.message);
      } else {
        setIsAccountCreated(false);
        toast.success(data.message || "Something went wrong please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(errors);

  return (
    <>
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        className="w-[600px] py-4 flex flex-col gap-2"
        // id="signup"
      >
        <h2 className="text-[#1A1D21] text-3xl text-center font-bold mb-3">
          Sign Up
        </h2>

        {/* <div className="flex justify-between gap-4"> */}
        <div className="w-full flex gap-4">
          <InputWithLabel
            label={"First Name"}
            placeholder="Enter here"
            id={"firstName"}
            {...register("firstName")}
            error={errors.firstName?.message}
          />
          <InputWithLabel
            label={"Last Name"}
            placeholder="Enter here"
            id={"lastName"}
            {...register("lastName")}
            error={errors.lastName?.message}
          />
        </div>

        <InputWithLabel
          label={"Company Name"}
          placeholder="Enter here"
          id={"companyName"}
          {...register("companyName")}
          error={errors.companyName?.message}
        />
        {/* </div> */}

        <div className="flex gap-4">
          <InputWithLabel
            label={"Company Registration Number"}
            placeholder="Enter here"
            id={"companyRegNumber"}
            {...register("companyRegNumber")}
            error={errors.companyRegNumber?.message}
          />
          {/* <InputWithLabel
            label={"Company Registered Address"}
            placeholder="Enter here"
            id={"companyRegAddress"}
            {...register("companyRegAddress")}
            error={errors.companyRegAddress?.message}
          /> */}

          <InputWithLabel
            label={"Work Email"}
            placeholder="Enter here"
            id={"workEmail"}
            {...register("workEmail")}
            error={errors.workEmail?.message}
          />
        </div>

        {/* <div className="flex gap-4">
          <InputWithLabel
            label={"Job Title"}
            placeholder="Enter here (i.e. CEO, CFO, etc.)"
            id={"jobTitle"}
            {...register("jobTitle")}
            error={errors.jobTitle?.message}
          />

         
        </div> */}
        <div className="flex gap-4">
          {/* <div className="flex gap-4">
            <InputWithLabel
              label={"City"}
              placeholder="Enter here"
              id={"phoneNumber"}
              {...register("city")}
              error={errors.city?.message}
            />
            <InputWithLabel
              label={"Country/Region"}
              placeholder="Enter here"
              id={"countryRegion"}
              {...register("countryRegion")}
              error={errors.countryRegion?.message}
            />
          </div> */}
          {/* <div className="flex gap-4"> */}
          {/* <InputWithLabel
            label={"Country Code"}
            placeholder="Enter here"
            id={"countryCode"}
            {...register("countryCode")}
            error={errors.countryCode?.message}
          /> */}

          <Dropdown
            label="Country Code"
            placeholder="Select a country code"
            options={PHONE_NO_COUNTRY_CODE.map((el) => ({
              label: el.label,
              value: el.countryCode,
            }))}
            {...register("countryCode")}
            error={errors.countryCode?.message}
          />

          <InputWithLabel
            label={"Business Phone Number"}
            placeholder="Enter here"
            id={"businessPhone"}
            {...register("businessPhone")}
            error={errors.businessPhone?.message}
          />
          {/* </div> */}

          {/* <InputWithLabel
            label={"Contact Name"}
            // style={{ color: "white" }}
            id={"companyRegAddress"}
            {...register("contactName")}
            error={errors.contactName?.message}
            // className="secondary-input"
          /> */}
        </div>

        <div className="flex gap-4">
          <InputWithLabel
            label={"Password"}
            type="password"
            id={"password"}
            placeholder="Enter password"
            {...register("password")}
            error={errors.password?.message}
          />
          <InputWithLabel
            label={"Retype Password"}
            type="password"
            id={"retypePassword"}
            placeholder="Enter retype password"
            {...register("retypePassword")}
            error={errors.retypePassword?.message}
          />
          {/* Other fields */}
        </div>

        <div className="mt-5 flex justify-center">
          <button
            type="submit"
            className="w-[200px] p-4 rounded font-bold text-white bg-custom-blue"
            disabled={isSubmitting}
          >
            {isSubmitting ? "submitting..." : "Sign Up"}
          </button>
        </div>
      </form>
      {isAccountCreated && <OtpPopup emailId={getValues("workEmail")} />}
    </>
  );
};

function TwoFALogin({ email }: { email: string }) {
  const [isLoginFail, setIsLoginFail] = useState(false);

  const { setJwtToken, toggleModal } = useAppContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<{ twoFAOtp: string }>();

  const handleChange = () => {
    setIsLoginFail(false);
  };

  const handleOnSubmit: SubmitHandler<{ twoFAOtp: string }> = async (
    values
  ) => {
    try {
      const res = await fetch(verify_user, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          verify_code: values.twoFAOtp,
        }),
      });

      const data = await res.json();
      // console.log(data);

      if (data.status === "success") {
        setIsLoginFail(false);
        reset();

        setJwtToken(data.jwt);
        toast.success("Login Successfully");
        toggleModal();
        navigate("/dashboard");
      } else {
        setIsLoginFail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className="w-[400px] py-4 space-y-4"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <div className="text-center">
        <h2 className="text-[#1A1D21] text-2xl  font-bold mb-1">
          Two-Factor Authentication
        </h2>
        <p className="text-gray-600 text-sm">
          Please check your email for the One-Time Password (OTP) to complete
          your two-factor authentication.
        </p>
      </div>

      <InputWithLabel
        label="Enter Verification Code"
        id="verificationCode"
        type="text"
        {...register("twoFAOtp", {
          required: "Enter a OTP",
          minLength: { value: 6, message: "OTP must be at least 6 characters" },
          maxLength: { value: 6, message: "OTP must be at least 6 characters" },
          setValueAs: (value) => value.trim(),
          onChange: handleChange,
        })}
        error={errors.twoFAOtp?.message}
      />

      <div>
        <div className="h-[24px] mb-2">
          {isLoginFail && (
            <small className="bg-red-50 text-red-400 border border-red-400 p-[2px] rounded-md flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                />
              </svg>

              <span>Wrong OTP, please try again</span>
            </small>
          )}
        </div>

        <button
          type="submit"
          className="w-full p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          {isSubmitting ? "Verifying.." : "Verify Code"}
        </button>
      </div>
    </form>
  );
}
