import { Outlet, NavLink, Link, useNavigate } from "react-router-dom";

import taramLogo from "../assets/icons/taramTextLogo.png";

import CheckAvailability from "../components/CheckAvailability";

import SimplePaymentIcon from "../components/Icons/SimplePaymentIcon";
import SmartEscrowIcon from "../components/Icons/SmartEscrowIcon";
import DashboardIcon from "../components/Icons/DashboardIcon";
import ChartBreakoutCircle from "../components/Icons/ChartBreakoutCircle";
// import SettingsIcon from "../components/Icons/SettingsIcon";
import HelpCircleIcon from "../components/Icons/HelpCircleIcon";
// import UserAvatar from "../assets/img/Avatar.png";
import IFIcon from "../components/Icons/IFIcon";

import { useAppContext } from "../context/AppContext";
import LogisticsIcon from "../components/Icons/LogisticsIcon";
import QualityAssuranceIcon from "../components/Icons/QualityAssuranceIcon";
import InsuranceIcon from "../components/Icons/InsuranceIcon";
import LogOutIcon from "../components/Icons/LogOutIcon";
import { NavBar } from "../components/NavBar";
import UserDropdown from "../components/UserDropdown";
import { paymentContent } from "../constants/tooltipContents";
import Tooltip from "../components/Tooltip";

const routes = [
  {
    id: 1,
    route: "/dashboard",
    name: "Dashboard",
    content: { paymentContent },
    icon: <DashboardIcon className="size-6" />,
  },
  {
    id: 2,
    route: "/simple-payment",
    name: "Payment",
    icon: <SimplePaymentIcon className="size-7" />,
  },
  {
    id: 3,
    route: "/invoice-factoring",
    name: "Invoice Factoring",
    icon: <IFIcon className="size-7" />,
  },
  // {
  //   id: 4,
  //   route: "/smart-escrow",
  //   name: "Smart Escrow",
  //   icon: <SmartEscrowIcon className="size-7" />,
  //   disabled: true,
  // },

  {
    id: 5,
    route: "/logistics",
    name: "Logistics",
    icon: <LogisticsIcon className="size-7" />,
  },
  {
    id: 6,
    route: "/quality-assurance",
    name: "Quality Assurance",
    icon: <QualityAssuranceIcon className="size-7" />,
  },

  // {
  //   id: 7,
  //   route: "/insurance",
  //   name: "Insurance",
  //   icon: <InsuranceIcon className="size-7" />,
  // },
  {
    id: 4,
    route: "/smart-escrow",
    name: "Smart Escrow",
    icon: <SmartEscrowIcon className="size-7" />,
    disabled: true,
  },
  // {
  //   id: 8,
  //   route: "/transaction-summary",
  //   name: "Summary",
  //   icon: <ChartBreakoutCircle className="size-7" />,
  // },
];

const VTabBarlayout = () => {
  const { removeJwtToken } = useAppContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeJwtToken();
    navigate("/");
  };

  return (
    <main className="flex">
      <aside className="w-[264px] h-full fixed top-0 bg-custom-dark-blue-new px-4 py-8 flex flex-col justify-between">
        <div>
          <div className="mb-10 pl-4">
            <Link to={"/"}>
              <img src={taramLogo} alt="logo" />
            </Link>
          </div>

          {routes.map((el) => (
            <div key={el.id} className="relative group">
              <NavLink
                // key={el.id}
                className={({ isActive }) =>
                  `pl-4 flex items-center gap-3 py-[10px] ${
                    el.disabled && "cursor-not-allowed"
                  } ${el.id === 1 && "mb-8"} ${
                    isActive
                      ? "bg-custom-blue-new text-white rounded-lg"
                      : "text-[#C6C8D0]"
                  }`
                }
                to={el.route}
                end={false}
                onClick={(e) => {
                  if (el.disabled) e.preventDefault();
                }}
              >
                {el.icon}
                <span>{el.name}</span>
              </NavLink>

              {el.disabled ? (
                <span
                  onMouseEnter={(e) => e.stopPropagation()}
                  className="absolute z-10 bottom-0 right-0 transform translate-x-2 px-2 py-1 text-sm text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                  Coming soon
                </span>
              ) : null}
            </div>
          ))}
        </div>

        <div className="text-[#C6C8D0] space-y-4">
          {/* <Link to="#" className="pl-4 flex items-center gap-3 hover:underline">
            <SettingsIcon className="size-6" /> <span>Settings</span>
          </Link> */}
          <Link
            to="help-and-support"
            className="pl-4 flex items-center gap-3 hover:underline"
          >
            <HelpCircleIcon className="size-6" /> <span>Help & Support</span>
          </Link>

          <button
            className="w-full pl-4 rounded-lg hover:underline flex items-center gap-3"
            onClick={handleLogout}
          >
            <LogOutIcon className="size-6" /> <span>Log Out</span>
          </button>
        </div>
      </aside>

      <div className="flex-1 ml-[264px] min-h-screen pb-10 bg-[#EAECF0]">
        {/* <CheckAvailability /> */}
        <nav className="max-w-[1128px] mx-auto flex justify-end py-4 items-center">
          <UserDropdown />
        </nav>

        <div className="max-w-[1128px] mx-auto">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default VTabBarlayout;
