import { useFieldArray, useFormContext } from "react-hook-form";

import InputWithLabel from "../InputWithLabel";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";

import Switch1 from "../Switch1";
import { today } from "../../utils";

import CrossIcon from "../Icons/CrossIcon";
// import UploadIcon from "./Icons/UploadIcon";

import Dropdown from "../DropDown";
import FouderDocumentSection from "./FouderDocumentSection";
import CustomDropdown from "./CustomDropdown";
import { businessDetails, businessRegion } from "../../constants/variable";

const initialFounderState = {
  founder_type: 0,
  first_name: "",
  middle_name: "",
  last_name: "",
  nationality: "",
  date_of_birth: "",
  professional_position: "",
  residential_address: "",
  email: "",
  phone_number: "",
  addressLine1: "",
  city: "",
  state: "",
  country: "",
  postcode: "",

  company_authorisation_documents_details: [
    {
      document_type: "",
      document_number: "",
      issuance_country: "",
      expiry_date: "",
      // file: "",
      document_file_path: "",
    },
  ],
};

export default function FounderDetailsFields() {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext<FullRegistrationFormType>();

  const {
    fields: founderFields,
    append: founderAppend,
    remove: founderRemove,
  } = useFieldArray({
    control,
    name: "founders",
  });

  return (
    <div>
      {/* Toggle and IconButton Section */}

      {/* Text Input Fields Section */}
      {founderFields.map((field, index) => (
        <div key={field.id}>
          {index > 0 && <hr className="my-8" />}
          <div className="flex items-center gap-4 mb-8">
            <div className="bg-[#F8F8F8] px-6 py-3 rounded-lg">
              <Switch1
                labelOne="Authorized Person"
                labelTwo="Shareholder"
                setValueWithHookForm={setValue}
                getValues={getValues}
                index={index}
              />
            </div>
            {index === 0 && (
              <div>
                <button
                  type="button"
                  onClick={() => founderAppend(initialFounderState)}
                  className="w-[67px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                >
                  Add
                </button>
              </div>
            )}

            {index !== 0 && (
              <button
                type="button"
                onClick={() => founderRemove(index)}
                className="text-red-500"
              >
                <CrossIcon className="size-10" />
              </button>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-6">
              <InputWithLabel
                label="First Name"
                id="founderFirstName"
                {...register(`founders.${index}.first_name`)}
                error={errors.founders?.[index]?.first_name?.message}
              />
              <InputWithLabel
                label="Middle Name"
                type="text"
                id="founderMiddleName"
                {...register(`founders.${index}.middle_name`)}
                error={errors.founders?.[index]?.middle_name?.message}
              />
              <InputWithLabel
                label="Last Name"
                id="founderLastName"
                {...register(`founders.${index}.last_name`)}
                error={errors.founders?.[index]?.last_name?.message}
              />
            </div>

            <div className="flex items-center gap-6">
              <InputWithLabel
                label="Nationality"
                id="nationality"
                {...register(`founders.${index}.nationality`)}
                error={errors.founders?.[index]?.nationality?.message}
              />
              <InputWithLabel
                label="Date Of Birth"
                type="date"
                id="dateOfBirth"
                {...register(`founders.${index}.date_of_birth`)}
                error={errors.founders?.[index]?.date_of_birth?.message}
                max={today}
              />
              {/* <Dropdown
                options={
                  PostionFetch?.data?.map((el) => ({
                    label: el.description,
                    value: el.description,
                  })) || []
                }
                label="Professional Position"
                placeholder="Please select position"
                id="professionalPosition"
                error={errors.founders?.[index]?.professional_position?.message}
                {...register(`founders.${index}.professional_position`)}
              /> */}

              <CustomDropdown
                label="Professional Position"
                placeholder="Please select position"
                name={`founders.${index}.professional_position`}
                category={businessDetails.POSITION}
                region={businessRegion}
                error={errors.founders?.[index]?.professional_position?.message}
              />
            </div>

            <div className="flex items-center gap-6">
              {/* <InputWithLabel
                label="Residential Address"
                id="residentialAddress"
                error={errors.founders?.[index]?.residential_address?.message}
                {...register(`founders.${index}.residential_address`)}
              /> */}

              <InputWithLabel
                label="Email Id"
                id="emailId"
                error={errors.founders?.[index]?.email?.message}
                {...register(`founders.${index}.email`)}
              />

              <InputWithLabel
                label="Phone No."
                id="companyPhoneNumber"
                error={errors.founders?.[index]?.phone_number?.message}
                {...register(`founders.${index}.phone_number`)}
              />
            </div>

            <FounderAddressFields index={index} />

            {/* Document Fields Section */}
            <FouderDocumentSection nestIndex={index} />
          </div>
        </div>
      ))}
    </div>
  );
}

function FounderAddressFields({ index }: { index: number }) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FullRegistrationFormType>();

  return (
    <div className="space-y-2">
      <h3 className="text-[#344054] font-medium pt-4 border-t">
        Residential Address
      </h3>

      {/* <div className="flex items-center gap-6"> */}
      <InputWithLabel
        label={"Address Line 1"}
        placeholder="Enter here"
        {...register(`founders.${index}.addressLine1`)}
        error={errors.founders?.[index]?.addressLine1?.message}
      />

      <div className="flex items-center gap-6">
        <InputWithLabel
          label={"City"}
          placeholder="Enter here"
          {...register(`founders.${index}.city`)}
          error={errors.founders?.[index]?.city?.message}
        />
        <InputWithLabel
          label={"State"}
          placeholder="Enter here"
          {...register(`founders.${index}.state`)}
          error={errors.founders?.[index]?.state?.message}
        />
      </div>

      <div className="flex items-center gap-6">
        <CustomDropdown
          label="Country"
          placeholder="Select reg. country"
          name={`founders.${index}.country`}
          category={businessDetails.COUNTRY_NAME}
          region={businessRegion}
          error={errors.founders?.[index]?.country?.message}
        />

        <InputWithLabel
          label={"Post Code"}
          placeholder="Enter here"
          {...register(`founders.${index}.postcode`)}
          error={errors.founders?.[index]?.postcode?.message}
        />
      </div>
    </div>
  );
}
