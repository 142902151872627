import React, { useState, useRef, useEffect } from "react";
import {
  blackColor,
  blueColor,
  offWhiteColor,
  whiteColor,
} from "../constants/colors";

interface ComboBoxProps {
  items: string[];
  width?: number;
  placeholder?: string;
  height?: number | string;
  dropdownWidth?: number | string;
  onItemSelect: (selectedItem: string) => void;
  selectedItem?: string;
  disabled?: boolean; // New prop to control the selected item
  className?: string;
}

const ComboBox: React.FC<ComboBoxProps> = ({
  items,
  width,
  placeholder,
  height,
  dropdownWidth,
  onItemSelect,
  disabled,
  selectedItem, // Use selectedItem from props
  className,
}) => {
  const [query, setQuery] = useState<string>(""); // Initialize with selectedItem
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedItem) return;

    setQuery(selectedItem); // Update the input field when the selectedItem changes
  }, [selectedItem]);

  // Handle click outside of the component to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setIsOpen(true);
  };

  const handleItemClick = (item: string) => {
    setQuery(item);
    setIsOpen(false);
    onItemSelect(item); // Notify parent of the selected item
  };

  return (
    <div ref={ref} style={{ position: "relative", width: dropdownWidth }}>
      <input
        type="text"
        disabled={disabled}
        value={query} // Controlled by query state
        placeholder={placeholder}
        className={`h-[39px] text-center border border-[#B9B9B9] ${className}`}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && filteredItems.length > 0 && (
        <ul
          className="combobox-ul"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            margin: 0,
            paddingInline: "10px",
            listStyleType: "none",
            backgroundColor: whiteColor,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            maxHeight: "150px",
            overflowY: "auto",
            scrollbarWidth: "none",
            scrollBehavior: "smooth",
            zIndex: 1000,
          }}
        >
          {filteredItems.map((item, index) => (
            <li
              key={index}
              onClick={() => handleItemClick(item)}
              style={{
                padding: "8px",
                cursor: "pointer",
                color: blueColor,
                borderTop: "1px solid #ddd",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#f0f0f0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#fff")
              }
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ComboBox;
