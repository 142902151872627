import { useAppContext } from "../../context/AppContext";
import InputWithLabel from "../InputWithLabel";

export type ApprovedFinance = {
  approved_finance_id: number;
  created_at: string;
  finance_partner_id: number;
  invoice_buyer_id: number;
  limit_request: string;
  payment_terms: string;
  term_conditions: number;
  uid: number;
};

export default function FundYourTransaction({
  openRequestNewCreditLimitModal,
}: {
  openRequestNewCreditLimitModal: () => void;
}) {
  const { user } = useAppContext();

  return (
    <section>
      <h3 className="text-black text-2xl font-semibold mb-5">
        Fund Your Transaction
      </h3>

      <div className="flex gap-4">
        <InputWithLabel
          disabled={true}
          label={"Company Name"}
          defaultValue={user?.company_name}
        />
        <InputWithLabel
          disabled={true}
          label={"Registration Number"}
          defaultValue={user?.company_reg_number}
        />
        <InputWithLabel
          disabled={true}
          label={"Address"}
          defaultValue={`${user?.registered_addressLine1}, ${
            user?.registered_addressLine2
              ? user?.registered_addressLine2 + ","
              : ""
          } ${user?.registered_city}, ${user?.registered_state}, ${
            user?.registered_country
          }`}
        />
      </div>

      {/* <CreditLimitDropDown
        setSelectedFinancePartnerId={setSelectedFinancePartnerId}
        buyerData={buyerData}
      /> */}

      <div className="py-5 border-b-2">
        <button
          type="button"
          className="h-[44px] px-2 text-[#0348B7] font-semibold border border-[#0348B7] rounded-lg"
          onClick={openRequestNewCreditLimitModal}
        >
          Request New Credit Limit
        </button>
      </div>
    </section>
  );
}
