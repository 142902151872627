import { useRef, useState } from "react";
import SuccessIcon from "../Icons/SuccessIcon";
import InputWithLabel from "../InputWithLabel";
import SpinnerIcon from "../Icons/SpinnerIcon";
import {
  add_simple_payment,
  update_simple_payment,
} from "../../constants/apis";
import { Link, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { FullSimplePaymentSchemaType } from "./SimplePaymentForm";
import { useSimplePaymentDataContext } from "../../context/SimplePaymentDataContext";

export default function PasswordAndOtpPopup({
  values,
}: {
  values: FullSimplePaymentSchemaType | null;
}) {
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Refs to focus the next input after each digit
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const { jwtToken } = useAppContext();
  const { refetch } = useSimplePaymentDataContext();

  const [searchParams] = useSearchParams();
  const simple_payment_id = searchParams.get("simple_payment_id");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    // Only allow one digit per input
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError("");

      // Move focus to the next input if the current one is filled
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }

      // Focus back to the previous input if the current input is emptied
      if (value === "" && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsloading(true);
    setError("");

    const formData = new FormData(e.currentTarget);
    const password = formData.get("password");
    const verify_code = otp.join("");

    if (!values) return;

    const {
      beneficiary_id,
      beneficiary_account_id,
      amount_sent,
      amount_sent_currency,
      amount_received,
      amount_received_currency,
      fees,
      estimated_time,
      reference,
      reason,
      paid_from,
      payment_mode,
    } = values;

    const payload = simple_payment_id
      ? {
          simple_payment_id,
          beneficiary_id,
          beneficiary_account_id,
          amount_sent,
          amount_sent_currency,
          amount_received,
          amount_received_currency,
          fees,
          estimated_time,
          reference,
          reason,
          paid_from,
          payment_mode,
        }
      : {
          beneficiary_id,
          beneficiary_account_id,
          amount_sent,
          amount_sent_currency,
          amount_received,
          amount_received_currency,
          fees,
          estimated_time,
          reference,
          reason,
          paid_from,
          payment_mode,
        };

    const apiUrl = simple_payment_id
      ? update_simple_payment
      : add_simple_payment;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          save_as_draft: 0,
          contract_id: "12345",
          password,
          verify_code,
          ...payload,
        }),
      });
      const result = await response.json();

      console.log(result);

      if (result.status == "success") {
        setSuccess(true);
        refetch();
      } else {
        setError(result?.message || result?.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className="bg-white p-8 w-[400px] space-y-6">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-800">Verification</h2>
        <p></p>
      </div>

      <div className="space-y-4 text-center">
        {/* Success Message */}
        {success && (
          <div>
            <SuccessIcon className="size-16 inline-flex justify-center" />
            <p className="text-green-400 mt-2 mb-4">
              Thank you! Your payment has been successfully completed. We
              appreciate your business!
            </p>

            <Link
              to={"/dashboard"}
              className="w-full py-2 px-4 bg-custom-blue-medium text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Go to Dashboard
            </Link>
          </div>
        )}

        {/* Forgot Password Form */}
        {!success && (
          <form className="space-y-4" onSubmit={handleOnSubmit}>
            <p className="text-xs text-gray-500">
              Please check your email for the verification code. Enter it below
              to complete this service.
            </p>

            <InputWithLabel
              label="Enter Your Password"
              type="password"
              id="password"
              name="password"
              required
              placeholder="Enter here"
            />

            <label className="text-[#344054] text-sm font-medium">
              <p className="text-start">Enter OTP</p>
              <div className="flex justify-center gap-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    maxLength={1}
                    ref={(el) => (inputRefs.current[index] = el!)} // Store refs for each input
                    className={`input-new text-center ${
                      error ? "border-2 border-red-300" : ""
                    }`}
                    placeholder="-"
                    required
                  />
                ))}
              </div>
            </label>

            <div>
              <small className="h-4 text-red-500">{error ? error : null}</small>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-2 px-4 bg-custom-blue-medium text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {isLoading ? (
                <p className="space-x-2">
                  <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                  <span>Please wait...</span>
                </p>
              ) : (
                "Verify"
              )}
            </button>
          </form>
        )}
      </div>

      {/* Footer */}
      <div className="text-center text-sm text-gray-500">
        <p>
          If you’re having trouble, please{" "}
          <a href="#" className="text-custom-blue-new hover:text-blue-700">
            contact support
          </a>
          .
        </p>
      </div>
    </div>
  );
}
