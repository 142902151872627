import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { reset_password } from "../constants/apis"; // Make sure to define your API endpoint
import SpinnerIcon from "../components/Icons/SpinnerIcon";
import SuccessIcon from "../components/Icons/SuccessIcon";
import InputWithLabel from "../components/InputWithLabel";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

type ResetPasswordFormType = {
  newPassword: string;
  retypePassword: string;
};

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  // Get reset token from URL (Assuming it's sent in the URL)
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<ResetPasswordFormType>();

  const submitHandler: SubmitHandler<ResetPasswordFormType> = async (
    values
  ) => {
    // console.log(values);

    try {
      const response = await fetch(reset_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          new_password: values.newPassword,
        }),
      });

      const data = await response.json();

      //   console.log(data);

      if (data.status === "success") {
        setIsSuccess(true);
      } else if (data.status === "fail") {
        toast.error("Wrong credentials!", {
          position: "top-center",
        });
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", {
        position: "top-center",
      });
      setIsSuccess(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#EAECF0]">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full space-y-6">
        {/* Header */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-800">Reset Password</h2>
        </div>

        <div className="space-y-4">
          {/* Success Message */}
          {isSuccess && (
            <div className="text-center">
              <SuccessIcon className="size-16 inline-flex justify-center" />
              <p className="text-green-400 mt-2 mb-4">
                Your password has been reset successfully.
              </p>
              {/* Submit Button */}
              <button
                className="w-full py-2 px-4 bg-custom-blue-medium text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => navigate("/")}
              >
                Go to Login
              </button>
            </div>
          )}

          {/* Reset Password Form */}
          {!isSuccess && (
            <form onSubmit={handleSubmit(submitHandler)} className="space-y-4">
              <InputWithLabel
                label="New Password"
                type="password"
                id="newPassword"
                placeholder="Enter your new password"
                {...register("newPassword", {
                  required: "New password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
                error={errors.newPassword?.message}
              />

              <InputWithLabel
                label="Retype New Password"
                type="password"
                id="retypePassword"
                placeholder="Retype your new password"
                {...register("retypePassword", {
                  required: "Please retype your new password",
                  validate: (value) =>
                    value === getValues("newPassword") ||
                    "Passwords do not match",
                })}
                error={errors.retypePassword?.message}
              />

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full py-2 px-4 bg-custom-blue-medium text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {isSubmitting ? (
                  <p className="space-x-2">
                    <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                    <span>resetting your password..</span>
                  </p>
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>
          )}
        </div>

        {/* Footer */}
        <div className="text-center text-sm text-gray-500">
          <p>
            If you’re having trouble, please{" "}
            <a href="#" className="text-blue-600 hover:text-blue-700">
              contact support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
