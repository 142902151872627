import { forwardRef } from "react";
import CrossIcon from "./Icons/CrossIcon";

type Props = {
  children: React.ReactNode;
  toggleDialog: () => void;
  className: string;
};

const Modal = forwardRef<HTMLDialogElement, Props>(
  ({ children, toggleDialog, className }, ref) => {
    return (
      <dialog
        ref={ref}
        // onClick={(e) => {
        //   if (e.currentTarget === e.target) {
        //     toggleDialog();
        //   }
        // }}
        className={className}
      >
        <div className="absolute right-8">
          <button onClick={toggleDialog}>
            <CrossIcon className="size-8 text-[#1A1D21]" />
          </button>
        </div>
        <div>{children}</div>
      </dialog>
    );
  }
);

export default Modal;
