import { useFormContext } from "react-hook-form";
import { businessDetails, businessRegion } from "../../constants/variable";
import InputWithLabel from "../InputWithLabel";
import CustomDropdown from "./CustomDropdown";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";
import { today } from "../../utils";

export default function ApplicantDetailsFields() {
  const {
    register,
    formState: { errors },
  } = useFormContext<FullRegistrationFormType>();

  return (
    <div className="space-y-2">
      <div className="flex gap-4">
        <InputWithLabel
          label={"First Name"}
          placeholder="Enter here"
          {...register("first_name")}
          error={errors.first_name?.message}
          disabled
        />
        <InputWithLabel
          label={"Last Name"}
          placeholder="Enter here"
          {...register("last_name")}
          error={errors.last_name?.message}
          disabled
        />
      </div>

      <div className="flex gap-4">
        <CustomDropdown
          label="Nationality"
          placeholder="Please select nationality"
          name={`user_nationality`}
          category={businessDetails.COUNTRY_NAME}
          region={businessRegion}
          error={errors.user_nationality?.message}
        />
        <InputWithLabel
          label="Date Of Birth"
          type="date"
          {...register("user_dateOfBirth")}
          error={errors.user_dateOfBirth?.message}
          max={today}
        />
      </div>

      <div className="flex gap-4">
        <CustomDropdown
          label="Professional Position"
          placeholder="Please select position"
          name={`job_title`}
          category={businessDetails.POSITION}
          region={businessRegion}
          error={errors.job_title?.message}
        />

        <InputWithLabel
          label={"Work Email"}
          placeholder="Enter here"
          {...register("work_email")}
          error={errors.work_email?.message}
          disabled
        />
      </div>

      <div className="flex gap-4">
        <InputWithLabel
          label={"Country Code"}
          placeholder="Enter here"
          {...register("countryCode")}
          error={errors.countryCode?.message}
        />

        <InputWithLabel
          label={"Business Phone"}
          placeholder="Enter here"
          {...register("company_phone_number")}
          error={errors.company_phone_number?.message}
        />
      </div>

      <div className="space-y-2">
        <h3 className="text-[#344054] font-medium pt-2">Applicant Address</h3>

        <InputWithLabel
          label={"Address Line 1"}
          placeholder="Enter here"
          {...register("user_addressLine1")}
          error={errors.user_addressLine1?.message}
        />

        <div className="flex items-center gap-6">
          <InputWithLabel
            label={"City"}
            placeholder="Enter here"
            {...register("user_city")}
            error={errors.user_city?.message}
          />
          <InputWithLabel
            label={"State"}
            placeholder="Enter here"
            {...register("user_state")}
            error={errors.user_state?.message}
          />
        </div>

        <div className="flex items-center gap-6">
          <CustomDropdown
            label="Country"
            placeholder="Select reg. country"
            name="user_country"
            category={businessDetails.COUNTRY_NAME}
            region={businessRegion}
            error={errors.user_country?.message}
          />

          <InputWithLabel
            label={"Post Code"}
            placeholder="Enter here"
            {...register("user_postcode")}
            error={errors.user_postcode?.message}
          />
        </div>
      </div>

      <ApplicantDocumentSection />
    </div>
  );
}

const ApplicantDocumentSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FullRegistrationFormType>();

  return (
    <div>
      <h3 className="text-[#344054] font-medium py-2">Applicant Document</h3>

      <div className="flex gap-6">
        <CustomDropdown
          label="Document Type"
          placeholder="Select document type"
          name={"user_documentType"}
          category={businessDetails.DOCUMENT_TYPE}
          region={businessRegion}
          error={errors.user_documentType?.message}
        />

        <InputWithLabel
          label="Document Number"
          error={errors.user_documentNumber?.message}
          {...register("user_documentNumber")}
        />

        <CustomDropdown
          label="Issuance Country"
          placeholder="Select issuance country"
          name={`user_documentIssuanceCountry`}
          category={businessDetails.COUNTRY_NAME}
          region={businessRegion}
          error={errors.user_documentIssuanceCountry?.message}
        />
      </div>
    </div>
  );
};
