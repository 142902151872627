import { z } from "zod";

export const PolicyHolderSchema = z.object({
  // same_as_policy_holder: z.number().optional(),
  // company_name: z
  //   .string()
  //   .min(3, { message: "Plese enter a valid Company Name" })
  //   .optional(),
  // company_reg_number: z
  //   .string()
  //   .min(3, { message: "Please enter a valid Company Registration Number" })
  //   .optional(),
  // company_phone_number: z
  //   .string()
  //   .min(3, { message: "Please enter a valid PhoneNumber" })
  //   .optional(),
  // work_email: z
  //   .string()
  //   .min(3, { message: "Please enter a valid email." })
  //   .optional(),
  // company_reg_country: z
  //   .string()
  //   .min(1, { message: "Please enter valid country" })
  //   .optional(),
  // // company_city:z.string().min(1,{message:"Please enter valid city"}).optional(),
  // company_reg_address: z
  //   .string()
  //   .min(3, { message: "Please enter a valid Address" })
  //   .optional(),
  // contactPersonName: z
  //   .string()
  //   .min(3, { message: "Please enter a valid Address" })
  //   .optional(),
  // policyholderCity: z
  //   .string()
  //   .min(3, { message: "Please enter a valid City" })
  //   .optional(),
  // policyholderCountry: z
  //   .string()
  //   .min(3, { message: "Please enter a valid Country" })
  //   .optional(),
  // insuredCompanyName: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insured Company Name" })
  //   .optional(),
  // insuredCompanyId: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insured Company iD" })
  //   .optional(),
  // insuredCompanyAddress: z
  //   .string()
  //   .min(3, { message: "Please enter a valid company Address" })
  //   .optional(),
  // insuredCity: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insured City" })
  //   .optional(),
  // insuredCountry: z
  //   .string()
  //   .min(1, { message: "Please enter a valid insured Country" })
  //   .optional(),
  // insuredEmail: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insured Email" })
  //   .optional(),
  // insuredPhoneNumber: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insured Phone Number" })
  //   .optional(),
  // insuredContactName: z
  //   .string()
  //   .min(3, { message: "Please enter a valid insuser Contact number" })
  //   .optional(),

  same_as_policy_holder: z.boolean().optional(),
  company_name: z
    .string()
    .trim()
    .min(1, { message: "Company Name is required" }),
  company_id: z.string().trim().min(1, { message: "Company ID is required" }),
  company_address: z
    .string()
    .trim()
    .min(1, { message: "Company address is required" }),
  // city: z.string().trim().min(1, { message: "City is required" }),
  // country: z.string().trim().min(1, { message: "Country is required" }),
  email: z.string().email("Enter a valid email"),
  phone_number: z
    .string()
    .trim()
    .min(4, { message: "Company phone number is required" }),
  contact_name: z
    .string()
    .trim()
    .min(1, { message: "Contact person name is required" }),
});

export type PolicyHolderType = z.infer<typeof PolicyHolderSchema>;
