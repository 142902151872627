import { useFieldArray, useFormContext } from "react-hook-form";
import { fileToBase64 } from "../../utils";
import { common_file_upload } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";
import { toast } from "react-toastify";

import DocumentIcon from "../Icons/DocumentIcon";
import DragAndDrop from "../DragAndDrop";
import Dropdown from "../DropDown";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";
import CustomDropdown from "./CustomDropdown";
import { businessDetails, businessRegion } from "../../constants/variable";

export default function BusinessDocumentsFields() {
  const { jwtToken } = useAppContext();
  // const documentTypeFetch = useConstantsFetch("documentType", "US");
  const {
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext<FullRegistrationFormType>();

  const {
    fields: businessDocumentsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "business_documents_details",
  });

  const uploadFile = async (file: File, index: number) => {
    try {
      const base64String = await fileToBase64(file);

      const res = await fetch(common_file_upload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Content_Type: "application/json",
        },
        body: JSON.stringify({
          base64File: base64String,
          folder_name: "user",
        }),
      });

      const data = await res.json();

      if (data.status === "success") {
        // clearErrors(`business_documents_details.${index}.file`);

        setValue(
          `business_documents_details.${index}.documents_file_path`,
          data.url as string
        );

        clearErrors(`business_documents_details.${index}.documents_file_path`);
        // toast.success(data.msg);
      } else {
        // toast.error("File upload went wrong please try again");
      }
    } catch (error) {
      console.error("Error converting file to Base64:", error);
      // toast.error("File upload went wrong please try again");
    }
  };

  const handleFileChangeAndUpload = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      await uploadFile(file, index);
    }
  };

  const handleDragAndUpload = async (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();

    const file = e.dataTransfer.files?.[0];
    console.log(file);

    if (file) {
      await uploadFile(file, index);
    }
  };
  console;
  return (
    <>
      <div>
        {businessDocumentsFields?.map((field, index) => (
          <div key={field.id}>
            <h3
              className={`text-[#344054] font-medium pt-1 ${
                index !== 0 && "border-t"
              }`}
            >
              Document {index + 1}
            </h3>
            <div className="flex flex-col gap-2 mb-2">
              <div>
                <label className="text-[#344054] text-sm font-medium">
                  Company Authorization Document
                </label>

                {getValues().business_documents_details[index]
                  ?.documents_file_path &&
                getValues().business_documents_details[
                  index
                ]?.documents_file_path.startsWith("https:") ? (
                  <a
                    className="text-sm flex float-end items-center gap-1 text-custom-blue hover:underline"
                    target="_blank"
                    rel="noopener noreferrer" // Important for security
                    href={
                      getValues().business_documents_details[index]
                        ?.documents_file_path
                    }
                  >
                    <DocumentIcon className="size-[14px]" /> View your uploaded
                    document
                  </a>
                ) : null}

                <DragAndDrop
                  id={`file-input-${index}`}
                  onChange={(e) => {
                    handleFileChangeAndUpload(e, index);

                    toast.promise(handleFileChangeAndUpload(e, index), {
                      pending: "File is uploading",
                      success: "File has been uploaded 👌",
                      error: "Rejected, please try again 🤯",
                    });
                  }}
                  onDrop={(e) => {
                    handleDragAndUpload(e, index);

                    toast.promise(handleDragAndUpload(e, index), {
                      pending: "File is uploading",
                      success: "File has been uploaded 👌",
                      error: "Rejected, please try again 🤯",
                    });
                  }}
                />

                {/* Display file validation/error messages */}
                <small className="text-red-500 h-[10px] block ml-1">
                  {
                    // errors.business_documents_details?.[index]?.file?.message ||
                    errors.business_documents_details?.[index]
                      ?.documents_file_path?.message
                  }
                </small>
              </div>

              {/* <Dropdown
                options={[]}
                id="businessDocumentNames"
                placeholder="Please Select Document Type"
                label={`Document Type`}
                error={
                  errors.business_documents_details?.[index]?.documents_type
                    ?.message
                }
                {...register(
                  `business_documents_details.${index}.documents_type`
                )}
              /> */}

              <CustomDropdown
                label="Document Type"
                placeholder="Select document type"
                name={`business_documents_details.${index}.documents_type`}
                category={businessDetails.DOCUMENT_TYPE}
                region={businessRegion}
                error={
                  errors.business_documents_details?.[index]?.documents_type
                    ?.message
                }
              />

              {index !== 0 && (
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="w-10 text-start text-red-500 hover:underline"
                >
                  {/* <CrossIcon className="size-8 text-red-500" /> */}
                  remove
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="h-[44px] px-2 rounded-md bg-custom-blue-medium text-white font-medium"
          onClick={() =>
            append({ documents_type: "", documents_file_path: "" })
          }
        >
          Add A New Document
        </button>
      </div>
    </>
  );
}
