import { useState } from "react";
import { Link } from "react-router-dom";
import { forgot_password } from "../constants/apis";
import SpinnerIcon from "../components/Icons/SpinnerIcon";
import SuccessIcon from "../components/Icons/SuccessIcon";
import InputWithLabel from "../components/InputWithLabel";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.currentTarget);
    const email = formData.get("email");

    try {
      const response = await fetch(forgot_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await response.json();

      //   console.log(data);

      if (data.status) {
        setIsSuccess(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", {
        position: "top-center",
      });
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#EAECF0]">
      <div className="bg-white p-8 rounded-lg shadow-lg w-[400px] space-y-6">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-800">Forgot Password</h2>
        </div>

        <div className="space-y-4 text-center">
          {/* Success Message */}
          {isSuccess && (
            <div>
              <SuccessIcon className="size-16 inline-flex justify-center" />
              <p className="text-green-400 mt-2 mb-4">
                A password reset link has been sent to your email.
              </p>
            </div>
          )}

          {/* Forgot Password Form */}
          {!isSuccess && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <InputWithLabel
                label="Email Address"
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your email"
              />

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-2 px-4 bg-custom-blue-medium text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {isLoading ? (
                  <p className="space-x-2">
                    <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
                    <span>Please wait...</span>
                  </p>
                ) : (
                  "Send Reset Link"
                )}
              </button>
            </form>
          )}
        </div>

        {/* Footer */}
        <div className="text-center text-sm text-gray-500">
          <Link to={"/"} className="text-custom-blue-new hover:underline">
            Go to home
          </Link>

          <p>
            If you’re having trouble, please{" "}
            <a href="#" className="text-custom-blue-new hover:text-blue-700">
              contact support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
