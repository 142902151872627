import { SubmitHandler, useForm } from "react-hook-form";
import { useAppContext } from "../../context/AppContext";
import InputWithLabel from "../InputWithLabel";

import SpinnerIcon from "../Icons/SpinnerIcon";
import { two_factor_mail, verify_two_factor_otp } from "../../constants/apis";
import { toast } from "react-toastify";

type TwoFAType = {
  two_fa: number;
};

export default function TwoFASection() {
  const { user, jwtToken } = useAppContext();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TwoFAType>({
    values: { two_fa: user?.two_fa || 0 },
  });

  const isEnable = watch("two_fa");

  const onSubmitHandler: SubmitHandler<TwoFAType> = async (values) => {
    console.log(values);

    try {
      const res = await fetch(two_factor_mail, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
      });

      const data = await res.json();

      //   console.log(data);

      if (data.status === "success") {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="flex gap-4 mt-4">
        <form className="w-full" onSubmit={handleSubmit(onSubmitHandler)}>
          <label className="text-[#344054] text-sm font-medium flex items-center gap-2 mb-3">
            <input type="checkbox" className="size-5" {...register("two_fa")} />
            Enable 2FA (*Either using phone no. or email)
          </label>

          <InputWithLabel
            label="Phone Number (Not available yet*)"
            type="text"
            placeholder="Enter Here"
            disabled
          />

          <InputWithLabel
            label="Email"
            type="email"
            placeholder="Enter Here"
            defaultValue={user?.work_email}
            disabled
          />

          <div className="flex items-center gap-2 mt-4">
            <button
              type="button"
              className="h-[44px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg disabled:cursor-not-allowed"
              disabled={!isEnable}
            >
              Resend Code
            </button>
            <button
              type="submit"
              className="h-[44px] px-4 rounded-md bg-custom-blue-medium text-white font-medium disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
              ) : (
                "Send Code"
              )}
            </button>
          </div>
        </form>

        <div className="w-full mt-8">
          <div className="flex items-center gap-2">
            <InputWithLabel
              label="Code"
              type="text"
              placeholder="Enter The OTP"
              disabled
            />

            <button
              type="submit"
              className="h-[44px] px-4 mt-2 rounded-md bg-custom-blue-medium text-white font-medium"
              disabled
            >
              Submit
            </button>
          </div>

          <Email2FAOTPVerify two_fa={isEnable} />
        </div>
      </div>
    </section>
  );
}

function Email2FAOTPVerify({ two_fa }: { two_fa: number }) {
  const { jwtToken, refetchUser } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<{ twoFAOtp: string }>();

  const handleOnSubmit: SubmitHandler<{ twoFAOtp: string }> = async (
    values
  ) => {
    try {
      const res = await fetch(verify_two_factor_otp, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          two_fa: two_fa ? 1 : 0,
          verify_code: values.twoFAOtp,
        }),
      });

      const data = await res.json();
      //   console.log(data);

      if (data.status === "success") {
        toast.success(data?.message);
        reset();
        refetchUser();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className="flex items-center gap-2"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <InputWithLabel
        label="Code"
        type="text"
        placeholder="Enter The OTP"
        {...register("twoFAOtp", {
          required: "Enter a OTP",
          minLength: { value: 6, message: "OTP must be at least 6 characters" },
          maxLength: { value: 6, message: "OTP must be at least 6 characters" },
          setValueAs: (value) => value.trim(),
        })}
        error={errors.twoFAOtp?.message}
      />

      <button
        type="submit"
        className="h-[44px] px-4 mt-2 rounded-md bg-custom-blue-medium text-white font-medium"
      >
        {isSubmitting ? (
          <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
        ) : (
          "Submit"
        )}
      </button>
    </form>
  );
}
