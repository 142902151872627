import Button from "../components/Button";

import InputWithLabel from "../components/InputWithLabel";
import {
  blackColor,
  blueColor,
  lightBlueColor,
  offWhiteColor,
  whiteColor,
} from "../constants/colors";
import leftArrow from "../assets/icons/arrow-left.png";
import IconButton from "../components/IconButton";
import addIcon from "../assets/icons/AddIcon.png";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Divider } from "../components/Divider";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SEConditionsFormSchema,
  SEConditionsFormType,
} from "../schemas/SEConditionsFormSchema";
import {
  Control,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  common_file_upload,
  get_smart_escrow_details,
  update_smart_escrow_details,
} from "../constants/apis";
import { useAppContext } from "../context/AppContext";
import { fileToBase64 } from "../utils";
import { toast } from "react-toastify";
import { useFetch } from "../hooks/useFetch";
import { SEDetailsSCPFormType } from "../schemas/SEDetailsType";

export const SEConditions = () => {
  const navigate = useNavigate();
  const { jwtToken } = useAppContext();

  const [searchParams] = useSearchParams();

  const smart_escrow_id = searchParams.get("smart_escrow_id");

  // console.log(beneficiaryAccountId, beneficiaryId, smart_escrow_id);

  // const [partialPayment2documentFields, setPartialPayment2documentFields] =
  //   useState([{ index: 1 }]);
  // const [
  //   partialPayment2DocumentNextIndex,
  //   setpartialPayment2DocumentNextIndex,
  // ] = useState(2); //keep track of the next partialPayment1documentFields...
  // const [partialPayment2ConditionFields, setPartialPayment2ConditionFields] =
  //   useState([{ index: 1 }]);
  // const [
  //   partialPayment2ConditionNextIndex,
  //   setpartialPayment2ConditionNextIndex,
  // ] = useState(2); //keep track of the next partialPayment1documentFields...

  const { data: allSmartEscrowData, loading } = useFetch<{
    smart_escrow: SEDetailsSCPFormType &
      SEConditionsFormType & { smart_escrow_id: number };
  }>(get_smart_escrow_details, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      smart_escrow_id,
    }),
  });

  console.log(allSmartEscrowData);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    clearErrors,
    setValue,
    getValues,
  } = useForm<SEConditionsFormType>({
    resolver: zodResolver(SEConditionsFormSchema),

    defaultValues: {
      conditions: [
        {
          document_approval: [{}],
          condition_approval: [{}],
        },
      ],
    },
    values: { conditions: allSmartEscrowData?.smart_escrow?.conditions || [] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "conditions",
  });

  const onHandleSubmit: SubmitHandler<SEConditionsFormType> = async (
    values
  ) => {
    // console.log(values);

    values.conditions.forEach((el) => {
      el.document_approval.forEach((doc) => {
        delete doc.file;
      });
    });

    console.log(values);

    try {
      const res = await fetch(
        update_smart_escrow_details,

        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            smart_escrow_id,
            ...allSmartEscrowData?.smart_escrow,
            ...values,
          }),
        }
      );

      const data = await res.json();

      console.log(data);

      if (data.status === "success") {
        toast.success(data?.msg || data?.message);
        navigate(`/smart-escrow/summary?smart_escrow_id=${smart_escrow_id}`);
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <section style={{ position: "relative" }}>
      {(allSmartEscrowData === null || loading) && (
        <div
          style={{
            backgroundColor: "rgba(,.5)",
            backdropFilter: `blur(4px)`,
            borderRadius: "10px",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 3,
          }}
        />
      )}
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        className="p-12 flex flex-col gap-8"
      >
        {fields?.map((field, index) => (
          <div key={field.id} className="space-y-4">
            <DocumentApprovalFields
              {...{
                register,
                control,
                errors,
                clearErrors,
                getValues,
                setValue,
                index,
              }}
            />

            <ConditionApprovalFields
              {...{
                register,
                control,
                errors,
                clearErrors,
                getValues,
                setValue,
                index,
              }}
            />

            {index !== 0 && (
              <button
                type="button"
                onClick={() => remove(index)}
                className="text-red-600 text-start"
              >
                remove
              </button>
            )}

            {index < fields.length - 1 && (
              <Divider
                dividerWidth="100%"
                dividerHeight="1px"
                dividerBackgroundColor={lightBlueColor}
              />
            )}
          </div>
        ))}

        <div
          className="flex justify-start items-center gap-2 cursor-pointer"
          onClick={() =>
            append({
              percent_of_money: 0,
              condition_approval: [{ approval_of_document: "", condition: "" }],
              document_approval: [
                { approval_of_document: "", doument_file: "" },
              ],
            })
          }
        >
          <p className="text-custom-blue underline">
            ADD A NEW PARTIAL PAYMENT
          </p>

          <IconButton height="25px" width="30px" iconSource={addIcon} />
        </div>

        <div className="my-14 inline-flex justify-around">
          <button
            type="button"
            className="w-32 h-14 p-1 border border-custom-blue rounded flex justify-center items-center gap-2 text-custom-blue"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} alt="left arrow" />
            <span>Back</span>
          </button>

          <button
            type="submit"
            className="w-32 h-14 p-1 bg-custom-blue text-white rounded"
            disabled={isSubmitting}
          >
            {isSubmitting ? "submitting.." : "Next"}
          </button>
        </div>
      </form>
    </section>
  );
};

const DocumentApprovalFields = ({
  register,
  errors,
  control,
  setValue,
  clearErrors,
  getValues,
  index,
}: {
  register: UseFormRegister<SEConditionsFormType>;
  errors: FieldErrors<SEConditionsFormType>;
  control: Control<SEConditionsFormType>;
  setValue: UseFormSetValue<SEConditionsFormType>;
  clearErrors: UseFormClearErrors<SEConditionsFormType>;
  getValues: UseFormGetValues<SEConditionsFormType>;
  index: number;
}) => {
  const { jwtToken } = useAppContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `conditions.${index}.document_approval`,
  });

  const handleFileChangeAndUpload = async (
    e: React.FormEvent<HTMLInputElement>,
    idx: number
  ) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      try {
        const base64String = await fileToBase64(file);

        const res = await fetch(common_file_upload, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            Content_Type: "application/json",
          },
          body: JSON.stringify({
            base64File: base64String,
            folder_name: "smart_escrow",
          }),
        });

        const data = await res.json();

        console.log(data);

        if (data.status === "success") {
          clearErrors(`conditions.${index}.document_approval.${idx}.file`);

          setValue(
            `conditions.${index}.document_approval.${idx}.doument_file`,
            data.url as string
          );

          clearErrors(
            `conditions.${index}.document_approval.${idx}.doument_file`
          );
        }
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };

  return (
    <div className="flex justify-between items-center gap-10">
      <div className="flex items-center">
        <InputWithLabel
          label="PARTIAL PAYMENT 1"
          type="text"
          id="percent_of_money"
          error={errors.conditions?.[index]?.percent_of_money?.message}
          {...register(`conditions.${index}.percent_of_money`)}
          style={{
            width: "56px",
            height: "56px",
          }}
        />

        <p className="w-full">% Of money released or value of money</p>
      </div>
      <div className="flex flex-col gap-4">
        {fields.map((field, nextIdx) => (
          <div key={field.id} className="flex items-center gap-4">
            <div>
              <InputWithLabel
                type="file"
                accept="image/png, image/jpeg, image/jpg, application/pdf, "
                label={`DOCUMENT - ${index + 1}`}
                id="doument_file"
                error={
                  errors.conditions?.[index]?.document_approval?.[nextIdx]?.file
                    ?.message ||
                  errors.conditions?.[index]?.document_approval?.[nextIdx]
                    ?.doument_file?.message
                }
                {...register(
                  `conditions.${index}.document_approval.${nextIdx}.file`
                )}
                onChange={(e) => {
                  handleFileChangeAndUpload(e, nextIdx);

                  toast.promise(handleFileChangeAndUpload(e, nextIdx), {
                    pending: "File is uploading",
                    success: "File has been uploaded 👌",
                    error: "Rejected, please try again 🤯",
                  });
                }}
              />
              {getValues().conditions?.[index]?.document_approval[nextIdx]
                ?.doument_file &&
                getValues().conditions?.[index]?.document_approval[
                  nextIdx
                ]?.doument_file.startsWith("https:") && (
                  <a
                    className="flex float-right text-custom-blue -mt-[5px] h-[10px]"
                    target="_blank"
                    rel="noopener noreferrer" // Important for security
                    href={
                      getValues().conditions?.[index]?.document_approval[
                        nextIdx
                      ]?.doument_file
                    }
                  >
                    View File
                  </a>
                )}
            </div>
            <InputWithLabel
              label={`APPROVER OF DOCUMENT - ${nextIdx + 1}`}
              id="approval_of_document"
              error={
                errors.conditions?.[index]?.document_approval?.[nextIdx]
                  ?.approval_of_document?.message
              }
              {...register(
                `conditions.${index}.document_approval.${nextIdx}.approval_of_document`
              )}
            />

            {nextIdx !== 0 && (
              <button
                type="button"
                onClick={() => remove(nextIdx)}
                className="text-red-600"
              >
                remove
              </button>
            )}

            {nextIdx === 0 && (
              <div>
                <button
                  type="button"
                  className="w-8 h-8 mt-4"
                  onClick={() =>
                    append({ approval_of_document: "", doument_file: "" })
                  }
                >
                  <img src={addIcon} alt="add icon" />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ConditionApprovalFields = ({
  register,
  errors,
  control,
  index,
}: {
  register: UseFormRegister<SEConditionsFormType>;
  errors: FieldErrors<SEConditionsFormType>;
  control: Control<SEConditionsFormType>;
  setValue: UseFormSetValue<SEConditionsFormType>;
  clearErrors: UseFormClearErrors<SEConditionsFormType>;
  getValues: UseFormGetValues<SEConditionsFormType>;
  index: number;
}) => {
  const {
    fields: conditionApprovalFields,
    append: conditionApprovalAppend,
    remove: conditionApprovalRemove,
  } = useFieldArray({
    control,
    name: `conditions.${index}.condition_approval`,
  });

  return (
    <div className="flex flex-col gap-2">
      {conditionApprovalFields.map((field, nestIdx) => (
        <div key={field.id} className="flex items-center gap-4">
          <InputWithLabel
            label={`CONDITION - ${nestIdx + 1}`}
            error={
              errors.conditions?.[index]?.condition_approval?.[nestIdx]
                ?.condition?.message
            }
            {...register(
              `conditions.${index}.condition_approval.${nestIdx}.condition`
            )}
          />
          <InputWithLabel
            label={`APPROVER OF CONDITION - ${nestIdx + 1}`}
            error={
              errors.conditions?.[index]?.condition_approval?.[nestIdx]
                ?.approval_of_document?.message
            }
            {...register(
              `conditions.${index}.condition_approval.${nestIdx}.approval_of_document`
            )}
          />

          {nestIdx !== 0 && (
            <button
              type="button"
              onClick={() => conditionApprovalRemove(nestIdx)}
              className="text-red-600"
            >
              remove
            </button>
          )}

          {nestIdx === 0 && (
            <div>
              <button
                type="button"
                className="w-8 h-8 mt-4"
                onClick={() =>
                  conditionApprovalAppend({
                    condition: "",
                    approval_of_document: "",
                  })
                }
              >
                <img src={addIcon} alt="add icon" />
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
