import { Link, NavLink, Outlet } from "react-router-dom";

import Switch from "../components/Switch";
import { useState } from "react";
import { useAppContext } from "../context/AppContext";

import { strokeColor } from "../constants/colors";
import { Divider } from "../components/Divider";

export const SmartEscrowLayout = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { setValue } = useAppContext();
  const { setCheck } = useAppContext();
  const [paymentCondition, setpaymentCondition] = useState(
    "Simple Condtional Payment"
  );
  const { checkAvailability } = useAppContext();

  function handleToggle(conditionOfPayment: string) {
    setIsChecked(!isChecked);
    setCheck(!isChecked); // Sending Value to App Context.tsx
    setValue(paymentCondition);
    sendingDataToAppContext();
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await fetch(get_country_details, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${jwtToken}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const result = await response.json();
  //     console.log(result);
  //   };
  //   fetchData();
  // }, []);

  // Updating Data and sending to the AppContext.tsx
  function sendingDataToAppContext() {
    !isChecked
      ? (setpaymentCondition("Full Condtional Payment"),
        setValue("Full Condtional Payment"))
      : (setpaymentCondition("Simple Condtional Payment"),
        setValue("Simple Condtional Payment"));
  }

  const routes = [
    {
      id: 1,
      route: "/smart-escrow/sender-beneficiary",
      name: "Sender & Beneficiary",
    },
    { id: 2, route: "/smart-escrow/details", name: "Details" },
    { id: 3, route: "/smart-escrow/conditions", name: "Conditions" },
    { id: 4, route: "/smart-escrow/summary", name: "Summary" },
  ];

  return (
    <div>
      <Divider
        dividerHeight={"1px"}
        dividerWidth={"100%"}
        dividerBackgroundColor={strokeColor}
      />
      <div className="flex justify-end my-10">
        <Switch
          checked={isChecked}
          paymentOne="Simple Condition-Based Payment"
          paymentTwo="Full Condition-Based Payment"
          onToggle={() => handleToggle(paymentCondition)}
          checkPayment={false}
        />
      </div>

      <div className="bg-custom-off-white mt-4">
        <nav className="flex gap-[1px]">
          {routes?.map((el) => (
            <NavLink
              key={el.id}
              className={({ isActive }) =>
                `w-full py-2 text-xl font-bold text-center transition-colors duration-700 ${
                  isActive
                    ? "text-custom-blue border-b border-white"
                    : "bg-custom-blue text-white"
                }`
              }
              onClick={(e) => e.preventDefault()}
              to={el.route}
            >
              {el.name}
            </NavLink>
          ))}
        </nav>
        <div className="relative">
          {!checkAvailability && (
            <div className="absolute top-0 left-0 right-0 bottom-0 z-10 backdrop-blur-sm">
              <div className="h-full flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <p className="text-custom-blue font-semibold">
                    Please fill in the origin and destination details to proceed
                    to the next step.
                  </p>

                  <Link
                    to="/simple-payment"
                    className="underline text-custom-dark-blue"
                  >
                    Go to Simple Payment to start
                  </Link>
                </div>
              </div>
            </div>
          )}

          <Outlet />
        </div>
      </div>
    </div>
  );
};
