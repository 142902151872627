import { z } from "zod";

export const SESenderBeneficiaryFormSchema = z.object({
    senderCompanyName:z.string().min(3,{message:"Company name must be at least 3 characters"}),
    beneficiaryCompanyName:z.string().min(3,{message:"Beneficairy company must be at least 3 characters"}),
    beneficiaryAccountRefName:z.string().min(3,{message:"Please enter a valid Account Reference Name"}),
    beneficiaryAccountHolderName:z.string().min(3,{message:"Please enter a valid Beneficiary Account Holder Name"}),
    beneficiaryAccountNoIban:z.string().min(3,{message:"Please enter a valid Account No/IBan"}),
    beneficiarySwiftIfscNo:z.string().min(3,{message:'Please enter a valid beneficiary Swift IFSC No.'}),
    beneficiaryCurrency:z.string().min(3,{message:'Please enter a valid currency number'}),
    beneficiaryBankAddress:z.string().min(3,{message:"Please enter a valid bank Address"}),
    beneficiaryBank:z.string().min(3,{message:'Please enter a valid beneficiary Bank'})
})

export type SESenderBeneficiaryFormType = z.infer<typeof SESenderBeneficiaryFormSchema>