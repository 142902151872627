import { matchPath, Outlet, useLocation } from "react-router-dom";

import { useAppContext } from "../context/AppContext";
import InputWithLabel from "../components/InputWithLabel";
import { useState } from "react";
import LogisticsIcon from "../components/Icons/LogisticsIcon";

export const LogisticsLayout = () => {
  const { checkAvailability } = useAppContext();
  const [isChecked, setIsChecked] = useState("simpleBooking");
  const location = useLocation();
  const routes = [
    {
      id: 1,
      route: "/logistics/cargo-details",
      name: "Cargo Details",
    },
    { id: 2, route: "/logistics/quotes", name: "Quotes" },
    { id: 3, route: "/logistics/summary", name: "Summary" },
  ];

  const isActiveRoute = (route: string) => {
    return matchPath(location.pathname, route) !== null;
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.value);
  };

  return (
    <div>
      <div className="bg-white rounded-md flex flex-col gap-5 items-center mb-5 border border-[#f3f3f5]">
        <div className="w-full px-10 py-6 flex flex-row justify-between items-center">
          <h3 className="text-2xl font-semibold">Shipping Details</h3>
          <div className="flex flex-row space-x-3">
            <input
              type="radio"
              className="w-4"
              checked={isChecked == "simpleBooking"}
              value={"simpleBooking"}
              onChange={handleRadioChange}
              name="simple_booking"
              id="simple_booking"
            />
            <label htmlFor="simple_booking">Simple Booking</label>
            <input
              type="radio"
              className="w-4"
              checked={isChecked == "customBooking"}
              value={"customBooking"}
              onChange={handleRadioChange}
              name="custom_booking"
              id="custom_booking"
            />
            <label htmlFor="custom_booking">Custom Booking</label>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md p-8 border border-[#f3f3f5]">
        <div className="flex flex-row items-center ">
          <LogisticsIcon className="size-8" />
          <h3 className="text-2xl font-bold ml-4">Cargo Details</h3>
        </div>
        <hr className="border-b my-5 border-b-[#F3F3F5]" />

        <div className=" flex">
          <nav className="p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
            {routes.map((el) => (
              <div key={el.route}>
                <div
                  className={`w-[180px] py-2 transition-colors duration-700 ${
                    isActiveRoute(el.route)
                      ? "text-[#0348B7]"
                      : "text-[#475467]"
                  }`}
                >
                  <p className="flex items-center gap-1">
                    <span
                      className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                        isActiveRoute(el.route)
                          ? "text-[#0348B7] border-[#0348B7]"
                          : "border-[#D0D5DD]"
                      }`}
                    >
                      0{el.id}
                    </span>{" "}
                    {el.name}
                  </p>
                </div>
                {el.id !== routes.length && (
                  <div className="w-[1px] h-10 bg-[#D0D5DD] ml-4" />
                )}
              </div>
            ))}
          </nav>

          <div className="relative w-full">
            {/* {!checkAvailability && (
              <div className="absolute top-0 left-0 right-0 bottom-0 z-10 backdrop-blur-sm">
                <div className="h-full flex justify-center items-center">
                  <div>
                    <p className="text-custom-blue font-semibold">
                      Please fill in the origin and destination details to
                      proceed to the next step.
                    </p>
                  </div>
                </div>
              </div>
            )} */}

            <Outlet context={{ isChecked }} />
          </div>
        </div>
      </div>
    </div>
  );
};
