import IconButton from "../components/IconButton";
import InputWithLabel from "../components/InputWithLabel";
import addIcon from "../assets/icons/AddIcon.png";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  add_quality_assurance,
  common_file_upload,
  get_quality_assurance,
  update_quality_assurance,
} from "../constants/apis";
import { useAppContext } from "../context/AppContext";
import {
  SubmitHandler,
  useForm,
  useFieldArray,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import {
  getQualityAssuranceFormSchema,
  // QualityAssuranceFormSchema,
  QualityAssuranceType,
} from "../schemas/QuallityAssuranceType";
import { toast } from "react-toastify";
import { useFetch } from "../hooks/useFetch";
import { fileToBase64 } from "../utils";
// import Spinner from "../components/Spinner";

import CrossIcon from "../components/Icons/CrossIcon";
import Dropdown from "../components/DropDown";
import {
  usePartnersDataContext,
} from "../context/PartnersDataContext";

const QualityAssurance = ({
  selectedCompany,
  setSelectedCompany,
  isSupplierAuditChecked,
  isQualityAssuranceChecked,
  isLoadingInspectionChecked,
  setIsSupplierAuditChecked,
  openModal,
}: {
  setIsSupplierAuditChecked:React.Dispatch<
  React.SetStateAction<boolean | undefined>>;
  selectedCompany: QualityAssuranceType | undefined;

  setSelectedCompany: React.Dispatch<
    React.SetStateAction<QualityAssuranceType | undefined>
  >;
  openModal: (type: "update" | "add") => void;
  isSupplierAuditChecked:boolean;
  isQualityAssuranceChecked:boolean;
  isLoadingInspectionChecked:boolean;
  
}) => {
  // const isLoadingInspectionChecked =false;
  // const schema = getQualityAssuranceFormSchema({
  //   isLoadingInspection,
  //   isQualityInspection,
  //   isSupplierAuditInspection,
  // }); 
  const [isChecked , setIsChecked] = useState<boolean>(false);
  console.log(isLoadingInspectionChecked,isQualityAssuranceChecked,isSupplierAuditChecked)
  const {
    register,
    formState: { errors },
    setValue,
    unregister,
    control,
    watch,
    clearErrors,
    getValues,
  } = useFormContext<QualityAssuranceType>();
  const { jwtToken } = useAppContext();
  const { data: partnersData, loading: partnersDataLoading } =
    usePartnersDataContext();


  // const { watch, control, setValue,getValues, clearErrors, formState: {errors, isSubmitting }, unregister } = useForm<QualityAssuranceType>({
  //   defaultValues: {
  //     quality_specifications_file: [{}],
  //   },
  //   values: qualityAssuranceData?.quality_assurance_data,
  // });

    useEffect(() => {
      if (isSupplierAuditChecked) {
        unregister("audit_specification");
        clearErrors("audit_specification");
  
      } else {
        register("audit_specification", {
          required:
            "Audit specification is required when supplier audit is checked",
        });
      }
  
      if (isQualityAssuranceChecked) {
        unregister("qa_consignment_value");
        unregister("quality_specifications_file");
  
      } else {
        register("qa_consignment_value", {
          required:
            "QA consignment value is required when quality assurance is checked",
        });
        register("quality_specifications_file", {
          required:
            "Quality specifications are required when quality assurance is checked",
        });
      }
      console.log("loading check karo", isLoadingInspectionChecked);
      if (isLoadingInspectionChecked) {
        unregister("no_of_containers");
        unregister("loading_consignment_value");
        unregister("goods_specifications");
      } else {
        register("no_of_containers");
        register("loading_consignment_value");
        register("goods_specifications");
      }
    }, [
      isSupplierAuditChecked,
      isQualityAssuranceChecked,
      isLoadingInspectionChecked,
      register,
      unregister,
      clearErrors,
    ]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quality_specifications_file",
  });

  const watchSelectedCompany = watch("company_name");

  useEffect(() => {
    const filteredData = partnersData
      ?.filter((el) => el.company_name === getValues("company_name"))
      .at(0);
    setSelectedCompany(filteredData);
  }, [partnersDataLoading]);

  useEffect(() => {
    if (!watchSelectedCompany) return;
    const company =
      partnersData?.find((el) => el.company_name === watchSelectedCompany) ||
      undefined;
    if (!company) return;
    setSelectedCompany(company);
  }, [watchSelectedCompany, partnersData]);



  const handleChangeUploadFile = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      try {
        const base64String = await fileToBase64(file);
        const res = await fetch(common_file_upload, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            base64File: base64String,
            folder_name: "quality_assurance",
          }),
        });
        const data = await res.json();

        if (data.status === "success") {
          clearErrors(`quality_specifications_file.${index}.file`);
          setValue(
            `quality_specifications_file.${index}.url`,
            data.url as string
          );
          clearErrors(`quality_specifications_file.${index}.url`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };


  
  function handleSupplierChange(checked: boolean): void {
    setIsChecked(checked);
    setIsSupplierAuditChecked(checked);
  }

  // console.log('qualityAssuranceData',qualityAssuranceData?.quality_assurance_data)

  return (
    <section className="bg-white rounded-md">
      <div className="flex flex-row justify-between items-center">
        <h1 className=" text-2xl font-bold">Quality Assurance</h1>
        <div className="flex justify-end my-4">
          {/* <button
            type="button"
            className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
            onClick={() => {
              addBeneficiarysDialogRef.current?.showModal();
            }}
          >
            Add New Supplier
          </button> */}
          {/* <dialog
            ref={addBeneficiarysDialogRef}
            className="rounded-xl backdrop:bg-black/50"
          >
            <AddQualitySupplier
              onClose={() => addBeneficiarysDialogRef.current?.close()}
              selectedCompany={selectedCompany}
              formType={"add"}
            />
          </dialog> */}
          <button
            type="button"
            className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
            onClick={() => openModal("add")}
          >
            Add New Company
          </button>
        </div>
      </div>

      <div>
        <div>
           {selectedCompany === null && ( 
           <div
              style={{
                backgroundColor: "rgba(,.5)",
                backdropFilter: `blur(4px)`,
                borderRadius: "10px",
                position: "absolute",
                top: 60,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 3,
              }}
            >
              <p className="text-center pt-20 text-red-500">
                Please Add or Edit New Company
              </p>
            </div> 
           )} 

          <div className="flex gap-6">
            <Dropdown
              label="Company Name"
              placeholder="Select a Supplier"
              options={
                partnersData?.map((el) => ({
                  label: el.company_name,
                  value: el.company_name,
                })) || []
              }
              id="beneficiaryCompanyName"
              error={errors?.company_name?.message}
              {...register("company_name")}
            />
            <div className="pt-5">
              <button
                type="button"
                className="h-[43px] w-[100px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
                onClick={() => openModal("update")}
                disabled={!selectedCompany}
              >
                Edit
              </button>
            </div>
            <InputWithLabel
              label={"Company Regsitration Number"}
              {...register("company_registration_number")}
              error={errors?.company_registration_number?.message}
              defaultValue={selectedCompany?.company_registration_number}
              disabled
            />
          </div>
          <div className="flex gap-6">
            <InputWithLabel
              label={"Address Of Supplier"}
              {...register("company_address")}
              error={errors?.company_address?.message}
              defaultValue={selectedCompany?.company_address}
              disabled
            />
            <InputWithLabel
              label={"Address Of Inspection"}
              {...register("company_address")}
              error={errors?.company_registration_number?.message}
              defaultValue={selectedCompany?.company_registration_number}
              disabled
            />
          </div>
          <div className="flex gap-6 pb-4">
            <InputWithLabel
              label={"Company Contact Person Name"}
              {...register("contact_person_name")}
              error={errors?.contact_person_name?.message}
              defaultValue={selectedCompany?.contact_person_name}
              disabled
            />
            <InputWithLabel
              label={"Company Email"}
              {...register("email")}
              error={errors?.email?.message}
              defaultValue={selectedCompany?.email}
              disabled
            />
            <InputWithLabel
              label={"Company Phone Number"}
              {...register("phone_number")}
              error={errors?.phone_number?.message}
              defaultValue={selectedCompany?.phone_number}
              disabled
            />
          </div>
          <hr className="border-t-[#D0D5DD] pb-5" />

          <div className="flex gap-3">
            <div className="w-full space-y-2">
              <div className="inline-flex items-center gap-2 mb-5">
                <input
                  type="checkbox"
                  size={32}
                  id="supplier_audit"
                  {...register("supplier_audit", {
                    value: getValues().supplier_audit ? true : false,
                  })}
                  checked={isChecked}
                  onChange={(e)=>handleSupplierChange(e.target.checked)}
                  className="w-6 h-6"
                />
                <label
                  htmlFor="supplier_audit"
                  className="font-semibold text-xl ml-5"
                >
                  Supplier Audit
                </label>
              </div>

              <InputWithLabel
                disabled={!isSupplierAuditChecked}
                label={"Audit Specifications"}
                {...register("audit_specification",{
                  required: {
                    value:isSupplierAuditChecked,
                    message: "Field is required",
                  },
                })}
                placeholder="Add required Specifications"
                error={errors?.audit_specification?.message}
              />
            </div>

            <div className="w-full space-y-2 border-l-[#D0D5DD] border-l-2 border-r-2 border-r-[#D0D5DD] px-3">
              <div className="inline-flex items-center gap-2 mb-5">
                <input
                  type="checkbox"
                  id="quality_assurance"
                  {...register("quality_assurance", {
                    value: getValues().quality_assurance ? true : false,
                  })}
                  className="w-6 h-6"
                />
                <label
                  htmlFor="quality_assurance"
                  className="font-semibold text-xl ml-5"
                >
                  Quality Assurance
                </label>
              </div>

              <div className="space-y-2 ">
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <div className="flex items-center gap-1 w-full">
                      <InputWithLabel
                        disabled={!isQualityAssuranceChecked}
                        type="file"
                        accept=".jpg,.pdf,.docx,.png,.jpeg"
                        {...register(
                          `quality_specifications_file.${index}.file`,
                          {
                            required: getValues().quality_specifications_file?.[
                              index
                            ]?.url
                              ? false
                              : {
                                  value: isQualityAssuranceChecked,
                                  message: "Document is required",
                                },
                          }
                        )}
                        onChange={(e) => {
                          handleChangeUploadFile(e, index);

                          toast.promise(handleChangeUploadFile(e, index), {
                            pending: "File is uploading",
                            success: "File has been uploaded 👌",
                            error: "Rejected, please try again 🤯",
                          });
                        }}
                        label={`Quality Specifications - ${index + 1}`}
                        error={
                          errors?.quality_specifications_file?.[index]?.message
                        }
                      />

                      {index === 0 && (
                        <IconButton
                          height="30px"
                          width="35px"
                          buttonWidth="50px"
                          buttonHeight="50px"
                          onClick={() => append({ url: "", file: "" })}
                          iconSource={addIcon}
                          disabled={!isQualityAssuranceChecked}
                        />
                      )}

                      {index !== 0 && (
                        <button onClick={() => remove(index)}>
                          <CrossIcon className="size-8 text-red-500" />
                        </button>
                      )}
                    </div>
                    {getValues().quality_specifications_file?.[index]?.url &&
                      getValues().quality_specifications_file?.[
                        index
                      ]?.url.startsWith("https:") && (
                        <a
                          className="flex float-right -mt-2 text-custom-blue hover:underline"
                          target="_blank"
                          rel="noopener noreferrer" // Important for security
                          href={
                            getValues().quality_specifications_file?.[index]
                              ?.url
                          }
                        >
                          View File
                        </a>
                      )}
                  </div>
                ))}

                <InputWithLabel
                  {...register("qa_consignment_value", {
                    required: {
                      value: isQualityAssuranceChecked === true,
                      message: "Field is required",
                    },
                  })}
                  label={"Consignment Value"}
                  disabled={!isQualityAssuranceChecked}
                  error={errors.qa_consignment_value?.message}
                />
              </div>
            </div>

            <div className="w-full space-y-2">
              <div className="inline-flex items-center gap-2 mb-5">
                <input
                  type="checkbox"
                  id="loading_inspection"
                  {...register("loading_inspection", {
                    value: getValues().loading_inspection ? true : false,
                  })}
                  className="w-6 h-6"
                />
                <label
                  htmlFor="loading_inspection"
                  className="font-semibold text-xl ml-5"
                >
                  Loading Inspection
                </label>
              </div>

              <div className="space-y-2">
                <InputWithLabel
                  disabled={!isLoadingInspectionChecked}
                  {...register("no_of_containers",{
                    required: {
                      value:getValues("loading_inspection"),
                      message: "Field is required",
                    },
                  })}
                  error={errors?.no_of_containers?.message}
                  label={"No. Of Containers"}
                />
                <InputWithLabel
                  disabled={!isLoadingInspectionChecked}
                  {...register("loading_consignment_value",{
                    required: {
                      value:isLoadingInspectionChecked,
                      message: "Field is required",
                    },
                  })}
                  error={errors?.loading_consignment_value?.message}
                  label={"Consignment Value"}
                />
                <InputWithLabel
                  disabled={!isLoadingInspectionChecked}
                  {...register("goods_specifications",{
                    required: {
                      value:isLoadingInspectionChecked,
                      message: "Field is required",
                    },
                  })}
                  error={errors?.goods_specifications?.message}
                  label={"Goods Specifications"}
                />
              </div>
            </div>
          </div>

          {/* <div className=" py-7 flex justify-between">
            <button
              type="button"
              className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </button>

            <div className="space-x-4">
              <button
                type="button"
                className="h-[44px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
              >
                Save as Draft
              </button>

              <button
                type="submit"
                className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
              >
                {isSubmitting ? "Submitting" : "Save and Next"}
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default QualityAssurance;
