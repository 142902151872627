// import { SPSenderBeneficiaryFormType } from "../schemas/SPSenderBeneficiaryType";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  SESenderBeneficiaryFormSchema,
  SESenderBeneficiaryFormType,
} from "../schemas/SESenderBeneficiaryType";
import { zodResolver } from "@hookform/resolvers/zod";
import InputWithLabel from "../components/InputWithLabel";
import IconButton from "../components/IconButton";
import addIcon from "../assets/icons/AddIcon.png";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../context/AppContext";
import AddNewBeneficiary from "../components/AddNewBeneficiary";
import { useNavigate } from "react-router-dom";
import {
  get_beneficary_accnt_details,
  get_beneficary_details,
} from "../constants/apis";
import { useFetch } from "../hooks/useFetch";
import Spinner from "../components/Spinner";

import Dropdown from "../components/DropDown";

type BeneficiaryDetails = {
  beneficiary_id: number;
  company_name: string;
  company_address: string;
  company_registration_number: string;
  contact_person_name: string;
  email: string;
  phone_number: string;
};

type BeneficiaryAccountDetails = {
  beneficiary_account_id: number;
  beneficiary_id: number;
  account_ref_name: string;
  account_holder_name: string;
  bank: string;
  bank_address: string;
  account_no: string;
  swift_ifsc: string;
  currency: string;
};

export const SESenderBeneficiary = () => {
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<number>();
  const [beneficiaryId, setBeneficiaryId] = useState<number | null>(null);

  const { jwtToken, checked, user } = useAppContext();
  // const { stateValue } = useOutletContext<{ stateValue: boolean }>();

  // const [isBeneficiary, setIsBeneficiary] = useState<boolean>(false);
  const addBeneficiarysDialogRef = useRef<HTMLDialogElement>(null);

  // custom fetch hook
  const {
    data: beneficiaryData,
    loading: beneficiaryLoading,
    error: beneficiaryError,
    refetch: beneficiaryDataRefetch,
  } = useFetch<BeneficiaryDetails>(get_beneficary_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const baUrl = beneficiaryId ? get_beneficary_accnt_details : null;

  const {
    data: beneficiaryAccountData,
    loading: beneficiaryAccountLoading,
    error: beneficiaryAccountError,
    refetch: beneficiaryAccountDataRefetch,
  } = useFetch<BeneficiaryAccountDetails[]>(
    baUrl,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        beneficiary_id: beneficiaryData?.beneficiary_id,
      }),
    },
    beneficiaryId!
  );

  useEffect(() => {
    if (beneficiaryData) {
      setBeneficiaryId(beneficiaryData.beneficiary_id);
    }
  }),
    [beneficiaryData];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SESenderBeneficiaryFormType>({
    resolver: zodResolver(SESenderBeneficiaryFormSchema),
    values: {
      senderCompanyName: user?.company_name || "",
      beneficiaryCompanyName: beneficiaryData?.company_name || "",
      beneficiaryAccountRefName:
        beneficiaryAccountData?.at(0)?.account_ref_name || "",
      beneficiaryAccountHolderName:
        beneficiaryAccountData?.at(0)?.account_holder_name || "",
      beneficiaryAccountNoIban: beneficiaryAccountData?.at(0)?.account_no || "",
      beneficiaryCurrency: beneficiaryAccountData?.at(0)?.currency || "",
      beneficiaryBank: beneficiaryAccountData?.at(0)?.bank || "",
      beneficiaryBankAddress: beneficiaryAccountData?.at(0)?.bank_address || "",
      beneficiarySwiftIfscNo: beneficiaryAccountData?.at(0)?.swift_ifsc || "",
    },
  });
  const navigate = useNavigate();
  const onSubmitHandler: SubmitHandler<SESenderBeneficiaryFormType> = (
    values
  ) => {
    // navigate("/smart-escrow/details");

    navigate(
      `/smart-escrow/details?beneficiaryId=${
        beneficiaryData?.beneficiary_id
      }&beneficiaryAccountId=${
        selectedBeneficiaryId === undefined
          ? beneficiaryAccountData?.at(0)?.beneficiary_account_id
          : selectedBeneficiaryId
      }`
    );
    console.log(values);
  };

  const handleOnChange = (e: React.FormEvent<HTMLSelectElement>) => {
    // console.log(e.currentTarget.value);
    const selectedData = beneficiaryAccountData?.filter(
      (el) => el.account_ref_name === e.currentTarget.value
    );

    setSelectedBeneficiaryId(selectedData?.at(0)?.beneficiary_account_id);

    reset({
      senderCompanyName: user?.company_name,
      beneficiaryCompanyName: beneficiaryData?.company_name,
      beneficiaryAccountRefName: selectedData?.at(0)?.account_ref_name,
      beneficiaryAccountHolderName: selectedData?.at(0)?.account_holder_name,
      beneficiaryAccountNoIban: selectedData?.at(0)?.account_no,
      beneficiaryCurrency: selectedData?.at(0)?.currency,
      beneficiaryBank: selectedData?.at(0)?.bank,
      beneficiaryBankAddress: selectedData?.at(0)?.bank_address,
      beneficiarySwiftIfscNo: selectedData?.at(0)?.swift_ifsc,
    });
  };

  return (
    <section className="relative py-4 px-20">
      {(beneficiaryAccountLoading || beneficiaryLoading) && <Spinner />}

      <h3 className="text-custom-blue text-base font-bold mb-2">Sender</h3>

      <div className="w-[31vw]">
        <InputWithLabel
          label="COMPANY NAME"
          type="text"
          id="senderCompanyName"
          // error={errors.senderCompanyName?.message}
          {...register("senderCompanyName")}
          disabled
        />
      </div>

      <div className="my-4 flex justify-between">
        <h3 className="text-custom-blue text-base font-bold">Beneficiary</h3>

        <IconButton
          text="Add or Edit New Company"
          onClick={() => {
            addBeneficiarysDialogRef.current?.showModal();
          }}
          height="20px"
          width="25px"
          iconSource={addIcon}
          buttonWidth="350px"
        />
      </div>

      <dialog
        ref={addBeneficiarysDialogRef}
        className="rounded-xl backdrop:bg-black/50"
      >
        <AddNewBeneficiary
          onClose={() => {
            addBeneficiarysDialogRef.current?.close();
          }}
          beneficiaryData={beneficiaryData}
          beneficiaryAccountData={beneficiaryAccountData}
          beneficiaryDataRefetch={beneficiaryDataRefetch}
          beneficiaryAccountDataRefetch={beneficiaryAccountDataRefetch}
        />
      </dialog>

      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        style={{ position: "relative" }}
      >
        {beneficiaryData === null && beneficiaryAccountData === null && (
          <div
            style={{
              backgroundColor: "rgba(,.5)",
              backdropFilter: `blur(4px)`,
              borderRadius: "10px",
              position: "absolute",
              top: 0,

              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 3,
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "red",
              }}
            >
              Please Add or Edit New Company
            </p>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <InputWithLabel
              label="COMPANY NAME"
              type="text"
              id="beneficiaryCompanyName"
              error={errors.beneficiaryCompanyName?.message}
              {...register("beneficiaryCompanyName")}
              disabled
            />

            <Dropdown
              label="ACCOUNT REFERENCE NAME"
              options={
                beneficiaryAccountData?.map((el) => ({
                  label: el.account_ref_name,
                  value: el.account_ref_name,
                })) || []
              }
              id="beneficiaryAccountRefName"
              error={errors.beneficiaryAccountRefName?.message}
              {...register("beneficiaryAccountRefName")}
              onChange={handleOnChange}
            />
          </div>

          <div className="flex gap-4">
            <InputWithLabel
              label="ACCOUNT HOLDER NAME"
              type="text"
              id="beneficiaryAccountHolderName"
              error={errors.beneficiaryAccountHolderName?.message}
              {...register("beneficiaryAccountHolderName")}
              disabled
            />

            <InputWithLabel
              label="ACCOUNT NO. / IBAN"
              type="text"
              id="beneficiaryAccountNoIban"
              placeholder="xxxx-xxxx-8796"
              error={errors.beneficiaryAccountNoIban?.message}
              {...register("beneficiaryAccountNoIban")}
              disabled
            />

            <InputWithLabel
              label="CURRENCY"
              type="text"
              id="beneficiaryCurrency"
              error={errors.beneficiaryCurrency?.message}
              {...register("beneficiaryCurrency")}
              disabled
            />
          </div>

          <div className="flex gap-4">
            <InputWithLabel
              label="BANK"
              type="text"
              id="beneficiaryBank"
              error={errors.beneficiaryBank?.message}
              {...register("beneficiaryBank")}
              disabled
            />

            <InputWithLabel
              label="BANK ADDRESS"
              type="text"
              id="beneficiaryBankAddress"
              error={errors.beneficiaryBankAddress?.message}
              {...register("beneficiaryBankAddress")}
              disabled
            />

            <InputWithLabel
              label="SWIFT / IFSC"
              type="text"
              id="beneficiarySwiftIfscNo"
              error={errors.beneficiarySwiftIfscNo?.message}
              {...register("beneficiarySwiftIfscNo")}
              disabled
            />
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className=" w-[150px] py-4 px-1 my-8 rounded-md bg-custom-blue text-white"
          >
            Next
          </button>
        </div>
      </form>
    </section>
  );
};
