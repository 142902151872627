import React from "react";
// import "../constants/styling.css";
import { blueColor, offWhiteColor, whiteColor } from "../constants/colors";
import { useLocation } from "react-router-dom";

interface StyleProps {
  type: "submit" | "reset" | "button" | undefined;
  width?: string;
  height?: string;
  placeholder?: string;
  borderRadius?: string;
  value?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  isSelected?: boolean;
  iconStyle?: React.CSSProperties;
  iconAltTag?: string;
  titleStyle?: React.CSSProperties | undefined;
  buttonTitle?: string;
  iconSource?: string;
  backgroundColor?: string;
  paddingBlock?: string;
  paddingInline?: string;
  route?: any;
  disabled?: boolean;
}
const Button: React.FC<StyleProps> = ({
  type,
  width,
  route,
  height,
  backgroundColor,
  paddingBlock,
  paddingInline,
  onClick,
  borderRadius,
  children,
  style,
  buttonTitle,
  titleStyle,
  disabled,
}) => {
  const location = useLocation();
  const isSelected = location.pathname.endsWith(route);
  // console.log("buttonRoute:",isSelected)
  return (
    <button
      type={type}
      onClick={onClick}
      className="button"
      style={{
        maxWidth: width,
        height: height,
        paddingBlock: paddingBlock,
        paddingInline: paddingInline,
        backgroundColor: isSelected
          ? offWhiteColor
          : blueColor || backgroundColor,
        color: isSelected ? blueColor : whiteColor,
        borderRadius: borderRadius,
        ...style,
      }}
      disabled={disabled}
    >
      {/* <div style={{ display: "flex" }}> */}
      {/* <img src={iconSource} style={iconStyle} alt={iconAltTag} /> */}
      {/* <p style={titleStyle}> */}
      {buttonTitle}
      {/* </p>
      </div> */}
      {children}
    </button>
  );
};
export default Button;
