export default function DashboardIconNew({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={className}
    >
      <path
        d="M6.4 1.60742H2.6C2.03995 1.60742 1.75992 1.60742 1.54601 1.71642C1.35785 1.81229 1.20487 1.96527 1.10899 2.15343C1 2.36734 1 2.64737 1 3.20742V7.00742C1 7.56747 1 7.8475 1.10899 8.06141C1.20487 8.24957 1.35785 8.40255 1.54601 8.49843C1.75992 8.60742 2.03995 8.60742 2.6 8.60742H6.4C6.96005 8.60742 7.24008 8.60742 7.45399 8.49843C7.64215 8.40255 7.79513 8.24957 7.89101 8.06141C8 7.8475 8 7.56747 8 7.00742V3.20742C8 2.64737 8 2.36734 7.89101 2.15343C7.79513 1.96527 7.64215 1.81229 7.45399 1.71642C7.24008 1.60742 6.96005 1.60742 6.4 1.60742Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4 1.60742H13.6C13.0399 1.60742 12.7599 1.60742 12.546 1.71642C12.3578 1.81229 12.2049 1.96527 12.109 2.15343C12 2.36734 12 2.64737 12 3.20742V7.00742C12 7.56747 12 7.8475 12.109 8.06141C12.2049 8.24957 12.3578 8.40255 12.546 8.49843C12.7599 8.60742 13.0399 8.60742 13.6 8.60742H17.4C17.9601 8.60742 18.2401 8.60742 18.454 8.49843C18.6422 8.40255 18.7951 8.24957 18.891 8.06141C19 7.8475 19 7.56747 19 7.00742V3.20742C19 2.64737 19 2.36734 18.891 2.15343C18.7951 1.96527 18.6422 1.81229 18.454 1.71642C18.2401 1.60742 17.9601 1.60742 17.4 1.60742Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4 12.6074H13.6C13.0399 12.6074 12.7599 12.6074 12.546 12.7164C12.3578 12.8123 12.2049 12.9653 12.109 13.1534C12 13.3673 12 13.6474 12 14.2074V18.0074C12 18.5675 12 18.8475 12.109 19.0614C12.2049 19.2496 12.3578 19.4026 12.546 19.4984C12.7599 19.6074 13.0399 19.6074 13.6 19.6074H17.4C17.9601 19.6074 18.2401 19.6074 18.454 19.4984C18.6422 19.4026 18.7951 19.2496 18.891 19.0614C19 18.8475 19 18.5675 19 18.0074V14.2074C19 13.6474 19 13.3673 18.891 13.1534C18.7951 12.9653 18.6422 12.8123 18.454 12.7164C18.2401 12.6074 17.9601 12.6074 17.4 12.6074Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.4 12.6074H2.6C2.03995 12.6074 1.75992 12.6074 1.54601 12.7164C1.35785 12.8123 1.20487 12.9653 1.10899 13.1534C1 13.3673 1 13.6474 1 14.2074V18.0074C1 18.5675 1 18.8475 1.10899 19.0614C1.20487 19.2496 1.35785 19.4026 1.54601 19.4984C1.75992 19.6074 2.03995 19.6074 2.6 19.6074H6.4C6.96005 19.6074 7.24008 19.6074 7.45399 19.4984C7.64215 19.4026 7.79513 19.2496 7.89101 19.0614C8 18.8475 8 18.5675 8 18.0074V14.2074C8 13.6474 8 13.3673 7.89101 13.1534C7.79513 12.9653 7.64215 12.8123 7.45399 12.7164C7.24008 12.6074 6.96005 12.6074 6.4 12.6074Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
