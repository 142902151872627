import {
  BeneficiaryAccountDetails,
  BeneficiaryDetails,
  SenderAccountDetails,
  SenderDetails,
} from "./SPSenderBeneficiary";

import { FullSimplePaymentSchemaType } from "../components/SimplePayment/SimplePaymentForm";
import { useFormContext } from "react-hook-form";

export type SPSummaryDataType = {
  beneficiary_account_details: BeneficiaryAccountDetails;
  beneficiary_details: BeneficiaryDetails;
  sender_account_details: SenderAccountDetails;

  sender_details: SenderDetails;
  simple_payment: {
    contract_id: number;
    sender_id: number;
    sender_account_id: number;
    beneficiary_id: number;
    beneficiary_account_id: number;
    amount_sent: number;
    amount_sent_currency: string;
    amount_received: number;
    amount_received_currency: string;
    fees: number;
    estimated_time: string;
    reference: string;
    reason: string;
    payment_mode: string;
    paid_from: string;
  };
};

export const SPSummary = () => {
  const { getValues } = useFormContext<FullSimplePaymentSchemaType>();

  const values = getValues();

  return (
    <section className="px-10">
      <div className="my-5">
        <h3 className="font-semibold mb-5">Beneficiary Information</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Company Name</p>
            <p className="text-gray-700 py-1">{values.company_name}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">
              Account Reference Name
            </p>
            <p className="text-gray-700">{values?.account_ref_name}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">
              Account Holder Name
            </p>
            <p className="text-gray-700">{values?.account_holder_name}</p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <p className="text-sm font-medium text-custom-blue">
              Account Number/IBAN
            </p>
            <p className="text-gray-700 py-1">{values?.account_no}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Currency</p>
            <p className="text-gray-700">{values?.currency}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Bank</p>
            <p className="text-gray-700">{values?.bank}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Bank Address</p>
            <p className="text-gray-700">{values?.bank_address}</p>
          </div>
        </div>
      </div>

      <div>
        <h3 className="font-semibold mb-5">Payment Information</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Amount Sent</p>
            <p className="text-gray-700 py-1">{values?.amount_sent}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">
              Amount Received
            </p>
            <p className="text-gray-700">{values?.amount_received}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Fees</p>
            <p className="text-gray-700">{values?.fees}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">
              Estimated Time
            </p>
            <p className="text-gray-700">{values?.estimated_time}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Reference</p>
            <p className="text-gray-700">{values?.reference}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Reason</p>
            <p className="text-gray-700">{values?.reason}</p>
          </div>
          <div className="">
            <p className="text-sm font-medium text-custom-blue">Payment Mode</p>
            <p className="text-gray-700">{values?.payment_mode}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
