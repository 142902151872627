export default function TaramLogo({ className }: { className: string }) {
  return (
    <svg
      viewBox="0 0 113 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <rect
        y="0.183594"
        width="36.4946"
        height="33.6323"
        fill="url(#pattern0_3113_3797)"
      />
      <path
        d="M54.7908 10.3431V12.1706H51.1555V24H48.9154V12.1706H45.2605V10.3431H54.7908ZM65.0964 21.2097H59.3782L58.3957 24H56.0573L60.9502 10.3235H63.544L68.4369 24H66.0789L65.0964 21.2097ZM64.4676 19.3822L62.2471 13.0352L60.007 19.3822H64.4676ZM77.4616 24L74.3175 18.5373H72.608V24H70.3679V10.3431H75.0839C76.1319 10.3431 77.0161 10.5266 77.7367 10.8934C78.4703 11.2602 79.0139 11.7514 79.3676 12.3671C79.7344 12.9828 79.9178 13.6706 79.9178 14.4304C79.9178 15.3212 79.6558 16.1334 79.1318 16.867C78.6209 17.5875 77.8284 18.0788 76.7541 18.3408L80.134 24H77.4616ZM72.608 16.7491H75.0839C75.9223 16.7491 76.5511 16.5395 76.9703 16.1203C77.4026 15.7011 77.6188 15.1378 77.6188 14.4304C77.6188 13.723 77.4092 13.1728 76.99 12.7798C76.5707 12.3737 75.9354 12.1706 75.0839 12.1706H72.608V16.7491ZM90.8204 21.2097H85.1022L84.1197 24H81.7814L86.6742 10.3235H89.2681L94.1609 24H91.8029L90.8204 21.2097ZM90.1916 19.3822L87.9712 13.0352L85.731 19.3822H90.1916ZM110.476 10.3431V24H108.236V14.6465L104.07 24H102.517L98.332 14.6465V24H96.0919V10.3431H98.5089L103.303 21.0525L108.078 10.3431H110.476Z"
        fill="#155DE9"
      />
      <defs>
        <pattern
          id="pattern0_3113_3797"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use
            xlinkHref="#image0_3113_3797"
            transform="matrix(0.0078125 0 0 0.00847739 0 -0.0425532)"
          />
        </pattern>
        <image
          id="image0_3113_3797"
          width={128}
          height={128}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAElBMVEVMaXHtJCTtIiXsIiXtIiXtIyZieZdXAAAABXRSTlMAEdbCgy63GJ8AAAAJcEhZcwAAA+gAAAPoAbV7UmsAAAIZSURBVHic7drZjuMwDERRLuL///IgnfYSW5ZsshQB06znIOcC8YMhhSiXy+VyuVwul/uPxlIm+2a3Cso43+4UqAz0rV+gJiN96xWoDQng1e8UqA0J4J3fLHj5AwL4w28U/Pj4AD74lwVvHx7AJ/+i4NdHB3DFrxYsPjiAq36lYPWxAXzhKx8+t/nQAHb4yAD2+MAAdvm4APb5sAB2+qgA9vqgAHb7mAD2+5AADviIAI74gAAO+fEAjvnhAA760QCO+sEADvuxAI77oQAG+K2Awl/wWwEiPN5vBpjxcL8TYGW03wuw4xei/W6AVQtwfj/AKo8i0L8RYKcHAenfCrAyzr8XYPvvxvo3A2x7EMD+3QBbfobw+0fxBliB+EzFG2DKCJ8OBQ8CTCD+oeBJwNUe+p8FgAB96n8UxAP0ub8vCAeox98VRAPU528FwQD1+mtBLED9/lIQCtCI/1sQCdCY/y4IBGjU/ynwB2jcfxW4AxThExVvgGL85r2hfMNvTSb7JJN9ksk+yWSfMO9f8AD9mk8y2SeZ7JNM9kkm+ySTfZLJPslkn2SyTzLZp33A6bCujPfp4oyoeQOF9GkLqB1WVu8AoT6tAfXj2sotLNanJeDiuPh8Dw72Sdr+6Z8IaJ8ah9WVArxPreP6U8EAn6TrbwUjfJK+vxQM8Ulu+O+CMT7JHf9VMMin3r3hWjDIz+VyuVwul8v9yf0DCS1JqY6a8aEAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}
