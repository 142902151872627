import { useActionData } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

interface DataFields {
  data: any;
  senderData: any;
}

export const SEShowData = ({ data, senderData }: DataFields) => {
  const { checked } = useAppContext();
  return (
    <div className="m-10 leading-8">
      <div className="flex">
        <div className="w-full">
          <h3 className="text-custom-blue font-medium">Sender</h3>
          <p>COMPANY NAME: {senderData?.company_name}</p>
          <p>SENDER EMAIL: {senderData?.work_email}</p>
        </div>
        <div className="w-full">
          <h3 className="text-custom-blue font-medium">Beneficiary</h3>
          <p>COMPANY NAME: {data.beneficiary_details.company_name}</p>
          <p>
            ACCOUNT REFERENCE NAME:{" "}
            {data.beneficiary_account_details.account_ref_name}
          </p>
          <p>
            ACCOUNT HOLDER NAME:{" "}
            {data.beneficiary_account_details.account_holder_name}
          </p>
          <p>ACCOUNT NO./IBAN: {data.beneficiary_account_details.account_no}</p>
          <p>CURRENCY: : {data.beneficiary_account_details.currency}</p>
          <p>BANK: {data.beneficiary_account_details.bank}</p>
          <p>BANK ADDRESS: {data.beneficiary_account_details.bank_address}</p>
          <p>SWIFT/IFSC: {data.beneficiary_account_details.swift_ifsc}</p>
        </div>
      </div>

      <div className="flex mt-5">
        {!checked ? (
          <div className="w-full">
            <h3 className="text-custom-blue font-medium">Details</h3>
            <p>AMOUNT SENT: {data.smart_escrow.amount_sent}</p>
            <p>AAMOUNT RECEIVED: {data.smart_escrow.amount_received}</p>
            <p>CURRENT MARKET RATE: 1USD = 0.98 EURO</p>
            <p>FEES: {data.smart_escrow.fees}</p>
            <p>REFERENCE: {data.smart_escrow.reference}</p>
            <p>
              FX HEDGING:{" "}
              {data.smart_escrow.request_fx_hedging ? "checked" : "NA"}
            </p>
            <p>ESCROW FEES PAID BY: {data.smart_escrow.fees_payed_by}</p>
          </div>
        ) : (
          <div className="w-full">
            <h3 className="text-custom-blue font-medium">Details</h3>
            <p>TRANSACTION TYPE: {data.smart_escrow.transation_type}</p>
            <p>ISSUE DATE: {data.smart_escrow.issue_date}</p>
            <p>EXPIRY DATE: {data.smart_escrow.expiry_date}</p>
            <p>LATEST SHIPMENT: {data.smart_escrow.latest_shipment}</p>
            <p>SHIPMENT PERIOD: {data.smart_escrow.shipment_period}</p>
            <p>TRANSACTION AMOUNT: {data.smart_escrow.transation_amount}</p>
            <p>
              TRANSACTION PI CURRENCY:{" "}
              {data.smart_escrow.transaction_pi_currency}
            </p>
            <p>
              TRANSACTION PO TOLERANCE:{" "}
              {data.smart_escrow.transaction_po_tolerance}
            </p>
            <p>EXCHANGE RATE: : {data.smart_escrow.exchange_rate}</p>
            <p>
              TRANSACTION AMT TOLERANCE:{" "}
              {data.smart_escrow.transaction_amt_tolerance}
            </p>
            <p>DRAWING: {data.smart_escrow.drawing}</p>
            <p>DRAWING TERM: {data.smart_escrow.drawing_term}</p>
            <p>PARTIAL SHIPMENTS: {data.smart_escrow.partial_shipments}</p>
            <p>TRANS SHIPMENT: {data.smart_escrow.trans_shipment}</p>
            <p>AIRPORT OF LOADING: {data.smart_escrow.airport_og_loading}</p>
            <p>
              AIRPORT OF DISCHARGE: : {data.smart_escrow.airport_of_discharge}
            </p>
            <p>TRANSPORT TO: {data.smart_escrow.transport_to}</p>
            <p>INCOTERMS</p>
            <p>
              DESCRIPTION OF DELIVERY:{" "}
              {data.smart_escrow.description_of_delivery}
            </p>
            <p>INDUSTRY: {data.smart_escrow.industry}</p>
            <p>NOTE: {data.smart_escrow.note}</p>
            <p>ESCROW FEES PAID BY: {data.smart_escrow.fees_payed_by}</p>
          </div>
        )}

        <div className="w-full">
          <h3 className="text-custom-blue font-medium">Conditions</h3>
          {data?.smart_escrow?.conditions?.map((el, i) => (
            <div key={i}>
              <p>VALUE OF PARTIAL PAYMENT {i + 1}</p>
              {el.document_approval.map((doc, idx) => (
                <div key={idx}>
                  <p>
                    DOCUMENTS:{" "}
                    {doc.doument_file && (
                      <a
                        href={doc.doument_file}
                        target="_blank"
                        className="text-custom-blue underline"
                      >
                        View file
                      </a>
                    )}
                  </p>
                  <p>APPROVER OF DOCUMENT: {doc.approval_of_document}</p>
                </div>
              ))}

              {el.condition_approval.map((doc, idx2) => (
                <p key={idx2}>CONDITION: {doc.condition}</p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
