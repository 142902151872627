import SpinnerIcon from "./Icons/SpinnerIcon";

const Spinner = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/40 z-50 flex flex-col justify-center items-center gap-3">
      <SpinnerIcon className="size-16 text-gray-200 animate-spin fill-blue-600" />
      <p className="text-white">Loading, please wait...</p>
    </div>
  );
};

export default Spinner;
