import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLInputElement> {
  icon: string | undefined;
}

const IconTextInput = ({
  width,
  height,
  icon,
  placeholder,
  className,
  ...rest
}: Props) => {
  return (
    <div className="relative w-full">
      {icon && (
        <span className="absolute left-2 top-[12px]">
          <img src={icon} alt="icon" className="w-5 h-5" />
        </span>
      )}
      <input
        className={`${icon ? "pl-10" : ""} ${className}`}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};
export default IconTextInput;
